// @ts-nocheck
import React from 'react';
import { useGetOne, Loading } from 'react-admin';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

export default () => {
  const [value, setValue] = React.useState('1');
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const {
    data: dataSuspicious,
    isLoading: loadingSuspicious,
    error: errorSuspicious,
  } = useGetOne('dashboard', { id: 70 });

  const {
    data: dataExecutionTime,
    isLoading: loadingExecutionTime,
    error: errorExecutionTime,
  } = useGetOne('dashboard', { id: 72 });

  const {
    data: dataTransactions,
    isLoading: loadingTransactions,
    error: errorTransactions,
  } = useGetOne('dashboard', { id: 71 });

  const {
    data: dataPartner,
    isLoading: loadingPartner,
    error: errorPartner,
  } = useGetOne('dashboard', { id: 35 });

  const {
    data: dataPartnerWithCallback,
    isLoading: loadingPartnerWithCallback,
    error: errorPartnerWithCallback,
  } = useGetOne('dashboard', { id: 107 });

  if (
    loadingSuspicious ||
    loadingPartner ||
    loadingExecutionTime ||
    loadingTransactions ||
    loadingPartnerWithCallback
  ) {
    return <Loading />;
  }
  if (
    errorSuspicious ||
    errorPartner ||
    errorExecutionTime ||
    errorTransactions ||
    errorPartnerWithCallback
  ) {
    console.log({
      error:
        errorSuspicious ||
        errorPartner ||
        errorExecutionTime ||
        errorTransactions ||
        errorPartnerWithCallback,
    });
  }

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Suspicious" value="1" />
          <Tab label="Execution Time" value="2" />
          <Tab label="Transaction" value="3" />
          <Tab label="Partner" value="4" />
          <Tab label="Partner With Callback" value="5" />
        </TabList>
      </Box>
      <TabPanel value="1" style={{ height: '75%', padding: '0px' }}>
        {dataSuspicious && dataSuspicious.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataSuspicious.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      <TabPanel value="2" style={{ height: '75%', padding: '0px' }}>
        {dataExecutionTime && dataExecutionTime.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataExecutionTime.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      <TabPanel value="3" style={{ height: '75%', padding: '0px' }}>
        {dataTransactions && dataTransactions.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataTransactions.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      <TabPanel value="4" style={{ height: '75%', padding: '0px' }}>
        {dataPartner && dataPartner.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataPartner.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      <TabPanel value="5" style={{ height: '75%', padding: '0px' }}>
        {dataPartnerWithCallback && dataPartnerWithCallback.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataPartnerWithCallback.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
    </TabContext>
  );
};
