import { useEffect, useContext, useState } from "react";
import { useListContext } from "react-admin";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { FilterContextProv } from "./ListPage";

const useStyles = makeStyles(() => ({
  date: {
    marginBottom: "5px",
  },
}));

export const FilterDateCheck = (props: any) => {
  // @ts-ignore
  const { filterContext } = useContext(FilterContextProv);
  const styleClass = useStyles();
  const { setFilters, filterValues } = useListContext();

  var date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [dateValue, setDateValue] = useState({
    dateEnd:
      typeof filterValues?.dateLte === "string"
        ? new Date(filterValues.dateLte)
        : lastDay,
    dateBegin:
      typeof filterValues?.dateGte === "string"
        ? new Date(filterValues.dateGte)
        : firstDay,
  });

  const [checkboxCredit, setCheckboxCredit] = useState(true);
  const [checkboxDebit, setCheckboxDebit] = useState(true);

  useEffect(() => {
    setFilters(
      {
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
        filterCredit: checkboxCredit,
        filterDebit: checkboxDebit,
        search_by_label: filterContext.search_by_label,
        search_by_reference_id: filterContext.search_by_reference_id,
        account_id: filterContext.account_id,
      },
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, filterContext, checkboxCredit, checkboxDebit]);

  console.log({ dateValue, props });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <DateRangePicker
            value={[dateValue.dateBegin, dateValue.dateEnd]}
            //if the value clean, the date will set to null
            onClean={() => {
              setDateValue({
                dateBegin: null as any,
                dateEnd: null as any,
              });
            }}
            onChange={(value: any) => {
              setDateValue({
                dateBegin: value ? new Date(value[0]) : (null as any),
                dateEnd: value ? new Date(value[1]) : (null as any),
              });
            }}
            className={styleClass.date}
            size="lg"
          />
        </Grid>
        <Grid item>
          <FormGroup
            row
            className={styleClass.date}
            onChange={(value: any) => {
              if (value.target.name === "filterCredit") {
                setCheckboxCredit(value.target.checked);
              }
              if (value.target.name === "filterDebit") {
                setCheckboxDebit(value.target.checked);
              }
            }}
          >
            <FormControlLabel
              control={<Checkbox name="filterCredit" defaultChecked />}
              label="Credit"
            />
            <FormControlLabel
              control={<Checkbox name="filterDebit" defaultChecked />}
              label="Debit"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};
