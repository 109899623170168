import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { PersonAdd as AddUserIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import { TextInput, SelectInput, PasswordInput, usePermissions, required } from 'react-admin';

const useStyles = makeStyles({
  input: {
    marginBottom: '0px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface DialogParams {
  open: boolean;
  otpCode: string;
  setOtpCode: React.Dispatch<React.SetStateAction<string>>;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  setCreatedData: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpen: () => void;
  handleClose: () => void;
  handleAdd: () => void;
}

export default function AddUserDialog({
  open,
  otpCode,
  setUserId,
  setOtpCode,
  setCreatedData,
  handleClickOpen,
  handleClose,
  handleAdd,
}: DialogParams) {
  const classes = useStyles();
  const { permissions } = usePermissions();

  let currentChoice: Array<any> = [
    { id: 'Partner', name: 'Partner' },
    { id: 'Partner View', name: 'Partner View' },
    { id: 'Partner Top-Up', name: 'Partner Top Up' },
  ];

  const validateConfirmPassword = (value: any, values: any) => {
    if (value === undefined) {
      return 'Required';
    } else if (value !== values.password) {
      return "Password didn't match";
    }
    return undefined;
  };

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        startIcon={<AddUserIcon />}
        onClick={handleClickOpen}
        disabled={permissions.includes('Staff') ? true : false}
        data-test-id="new-user-btn"
      >
        New User
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-email"
                  className={classes.input}
                  label="Email"
                  source="email"
                  validate={[required()]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      email: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-name"
                  className={classes.input}
                  label="Name"
                  source="name"
                  validate={[required()]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <PasswordInput
                  fullWidth
                  data-test-id="input-password"
                  className={classes.input}
                  label="Password"
                  source="password"
                  validate={[required()]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput
                  fullWidth
                  data-test-id="input-confirm-pass"
                  className={classes.input}
                  label="Confirm Password"
                  source="confirm_password"
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      confirmPassword: e.target.value,
                    }))
                  }
                  validate={validateConfirmPassword}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-address"
                  className={classes.input}
                  label="Address"
                  source="address"
                  validate={[required()]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      address: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-phone"
                  className={classes.input}
                  label="Phone"
                  source="phone"
                  validate={[required()]}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      phone: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SelectInput
                  source="roles"
                  data-test-id="select-roles"
                  className={classes.input}
                  choices={currentChoice}
                  fullWidth
                  validate={required()}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      roles: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  source="status"
                  data-test-id="select-status"
                  className={classes.input}
                  choices={[
                    { id: true, name: 'Enable' },
                    { id: false, name: 'Disable' },
                  ]}
                  fullWidth
                  validate={required()}
                  onChange={(e) =>
                    setCreatedData((prevState: any) => ({
                      ...prevState,
                      status: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  label="OTP 2FA"
                  fullWidth
                  data-test-id="input-otp"
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={handleAdd}
            data-test-id="add-user-btn"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
