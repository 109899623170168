import { Box, Grid, TextField } from "@mui/material";
import { useState } from "react";
import {
  BooleanInput,
  Confirm,
  DateTimeInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import mbaas from "../../provider/Mbaas";
import Swal from "sweetalert2";

const Title = () => {
  const record = useRecordContext();
  return (
    <span data-test-id="edit-title">
      &rsaquo; Edit {record ? record?.id : ""}
    </span>
  );
};

export const EditPage = ({ ...props }: any) => {
  const { isLoading, permissions } = usePermissions();

  const isStaff =
    !isLoading && permissions.find((el: string) => el === "Staff");

  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const [cOpen, setCOpen] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const [otpCode, setOtpCode] = useState("");

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi("totp", {
        action: "VALIDATE",
        token: otpCode,
      });
      console.log("ERROR-TOTP", error?.error);
      if (data === "OK") {
        update(
          "supplier-catalog",
          { id: formData?.id, data: formData },
          {
            onSuccess: () => {
              redirect("/supplier-catalog");
              refresh();
            },
            onError(error, variables, context) {
              notify("An error has occurred. Please try again later", {
                type: "error",
              });
            },
          }
        );

        setOtpCode("");
        setCOpen(false);
        refresh();
      } else {
        setOtpCode("");
        setCOpen(false);
        notify("An error has occurred. Please try again later", {
          type: "error",
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, "error");
    }
  };

  const onSubmitHandler = (data: any) => {
    setCOpen(true);
    setFormData(data);
  };
  return (
    <Edit title={<Title />} {...props} undoable={false}>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Box pt={5} width={{ xs: "100vW", sm: 750 }} mt={{ xs: 2, sm: 1 }}>
        <SimpleForm onSubmit={onSubmitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                source="id"
                data-test-id="input-id"
                isRequired
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                source="created_at"
                data-test-id="input-created-at"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                source="supplier_id_data.name"
                label="Supplier Name"
                data-test-id="input-supplier"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                source="label"
                data-test-id="input-product-label"
                label="Product Label"
                isRequired
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                source="description"
                data-test-id="input-description"
                isRequired
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={15}>
            <Grid item xs={6}>
              <NumberInput
                source="cost"
                data-test-id="input-cost"
                isRequired
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                margin: "10px 0px 0px 0px",
              }}
            >
              <BooleanInput
                source="is_available"
                data-test-id="input-status"
                label="Available"
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
