export const pageBadgeIcon = {
  color: '#fff',
  height: '29px',
  width: '29px',
};

export const topToolbarStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'center',
};

export const stackResponsive = {
  marginRight: '17px',
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '55px',
};

export const stackDefault = {
  marginRight: '17px',
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const paperBadgeStyle = {
  height: '75px',
  width: '75px',
  backgroundColor: '#02a499',
  position: 'absolute',
  top: '-31px',
  left: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const defaultCreateBtn = {
  backgroundColor: '#02a499',
  minWidth: '110px',
};

export const createButtonResponsiveStyle = {
  backgroundColor: '#38a4f8',
};

export const exportButtonDefaultStyle = {
  backgroundColor: '#02a499',
  minWidth: '110px',
};

export const exportButtonResponsivetStyle = {
  backgroundColor: '#02a499',
  minWidth: '50px',
  fontSize: '0.929em',
  color: 'white',
};

export const goButtonDefaultStyle = {
  color: 'white',
  backgroundColor: '#02a499',
};

export const goButtonResponsiveStyle = {
  backgroundColor: '#02a499',
  fontSize: '0.5em',
  color: 'white',
  padding: '0px',
};
