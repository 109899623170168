/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { useLogin, useNotify, useRedirect } from 'react-admin';
import mbaas from '../provider/Mbaas';
import Button from '@mui/material/Button';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

const CustomizedTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={
      {
        disableUnderline: true,
      } as Partial<OutlinedInputProps>
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: 'none',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#e8f0fe',
    color: 'black',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#e8f0fe',
    },
    '&.Mui-focused': {
      backgroundColor: '#e8f0fe',
      boxShadow: `#1976d2 0 0 0 2px`,
      borderColor: 'white',
      color: 'black',
    },
  },
  '& .MuiFormLabel-root': {
    color: '#000000',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#000000',
  },
}));

function getparam(parameterName: String) {
  let result = null;
  let tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export default function LoginScreen() {
  const [loading, setLoading] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify = async () => {
    const code = getparam('code');
    const socmed = getparam('prompt') ? 'google' : 'facebook';
    if (!code) {
      return;
    } else {
      setLoadingGoogle(true);

      try {
        const loginRes = await mbaas.client.auth.login(socmed, {
          callback: `${window.location.origin}/login`,
          code,
        });

        console.log(loginRes);

        if (!loginRes.data) {
          setLoadingGoogle(false);
          notify('invalid code', { type: 'warning' });
          return;
        }

        if (loginRes.data.type === 'LoginExisting') {
          console.log('loginexist');
          const check = await mbaas.client.user.get();
          const email = check.data?.email;
          console.log(check);
          localStorage.setItem('username', email || '');
          window.location.assign('/');
          setLoadingGoogle(false);
          return;
        } else {
          notify('User doesnt exist', { type: 'warning' });
        }
      } catch (e) {
        notify('Error', { type: 'warning' });
      }
    }

    setLoadingGoogle(false);
  };

  const newLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await login({ username: email, password: pass });
      console.log(res);
    } catch (e: any) {
      console.log(e);
      notify(e?.title, { type: 'warning' });
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        height: '100vh',
        backgroundImage:
          'linear-gradient(45deg, hsla(354, 71%, 22%, 1) 0%, hsla(353, 67%, 45%, 1) 50%, hsla(24, 82%, 49%, 1) 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          marginTop: '-87px',
          maxWidth: '325px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '11px 27px',
            boxShadow: '14px 15px 30px -7px rgba(0,0,0,0.55)',
            backgroundColor: '#EBEBEB',
          }}
          component={Paper}
        >
          <img src="/MKIOS New Logo.png" alt="MKIOS logo" width="100%" />
          <Typography
            component="h5"
            variant="h6"
            mt={5}
            sx={{ color: '#141414', fontWeight: 'light', fontSize: '0.95rem' }}
          >
            Please Sign In to Continue!
          </Typography>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <CustomizedTextField
              fullWidth
              label="Email"
              id="email-input"
              variant="filled"
              value={email}
              InputLabelProps={{ shrink: true }}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                mt: 2,
              }}
            />
            <CustomizedTextField
              fullWidth
              label="Password"
              id="password-input"
              type="password"
              variant="filled"
              value={pass}
              autoFocus
              onChange={(e) => setPass(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                mt: 2,
              }}
            />

            <Button
              id="sign-in-button"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={newLogin}
              disabled={loading && loadingGoogle}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
}
