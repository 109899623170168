import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import ReactJson from "react-json-view";

interface DialogParams {
  open: boolean;
  dialogContent: any;
  handleClose: () => void;
}

export default function ResultDialog({
  open,
  dialogContent,
  handleClose,
}: DialogParams) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Result of Check NGRS Status</DialogTitle>
        <DialogContent>
          <Box>
            <ReactJson
              src={dialogContent}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
              name={false}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
            data-test-id="close-btn"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
