import { DataProvider } from "react-admin";
import { ProviderBase } from "./ProviderBase";

export class MultiProviders {
  _providers: Map<string, ProviderBase>;

  constructor() {
    this._providers = new Map();
  }

  register(keys: string | string[], provider: ProviderBase) {
    if (Array.isArray(keys)) {
      keys.forEach((key) => this._providers.set(key, provider));
    } else {
      this._providers.set(keys, provider);
    }
  }

  private find(key: string) {
    const provider = this._providers.get(key);
    if (!provider) throw new Error("Missing Provider");
    return provider;
  }

  serve() {
    const BaseProvider: DataProvider = {
      getList: async (resource, params) =>
        this.find(resource).getList(resource, params), // get a list of records based on sort, filter, and pagination
      getOne: async (resource, params) =>
        this.find(resource).getOne(resource, params), // get a single record by id
      getMany: async (resource, params) =>
        this.find(resource).getMany(resource, params), // get a list of records based on an array of ids
      getManyReference: async (resource, params) =>
        this.find(resource).getManyReference(resource, params), // get the records referenced to another record, e.g. comments for a post
      create: async (resource, params) =>
        this.find(resource).create(resource, params), // create a record
      update: async (resource, params) =>
        this.find(resource).update(resource, params), // update a record based on a patch
      updateMany: async (resource, params) =>
        this.find(resource).updateMany(resource, params), // update a list of records based on an array of ids and a common patch
      delete: async (resource, params) =>
        this.find(resource).delete(resource, params), // delete a record by id
      deleteMany: async (resource, params) =>
        this.find(resource).deleteMany(resource, params), // delete a list of records based on an array of idss
    };
    return BaseProvider;
  }
}
