import { Box, Grid, TextField } from "@mui/material";
import { useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Confirm,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import Swal from "sweetalert2";
import mbaas from "../../provider/Mbaas";

export const CreatePage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [formData, setFormData] = useState<any>(null);
  const [create] = useCreate();

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi("totp", {
        action: "VALIDATE",
        token: otpCode,
      });
      console.log("ERROR-TOTP", error?.error);
      if (data === "OK") {
        create(
          "supplier-catalog",
          { data: formData },
          {
            onSuccess: () => {
              redirect("/supplier-catalog");
              refresh();
            },
            onError(error, variables, context) {
              console.log("ERRRROOOOORRRRRRRRR", error);
              notify("" + error, {
                type: "error",
              });
            },
          }
        );

        setOtpCode("");
        setCOpen(false);
        refresh();
      } else {
        setOtpCode("");
        setCOpen(false);

        notify("An error has occurred. Please try again later", {
          type: "error",
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, "error");
    }
  };

  const onSubmitHandler = (data: any) => {
    console.log("DATAA", data);
    setCOpen(true);
    setFormData(data);
  };

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Create title="&rsaquo; Create Supplier Catalog">
        <Box pt={5} width={{ xs: "100vW", sm: 700 }} mt={{ xs: 2, sm: 1 }}>
          <SimpleForm onSubmit={onSubmitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ReferenceInput
                  source="supplier_id"
                  reference="suppliers"
                  alwaysOn
                >
                  <AutocompleteInput
                    label="Supplier Name"
                    source="supplier_id"
                    data-test-id="input-supplier"
                    fullWidth
                    optionText={(choice) => choice.name}
                  />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  label="Product Label"
                  source="label"
                  data-test-id="input-product-label"
                  validate={[required()]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  source="description"
                  data-test-id="input-description"
                  validate={[required()]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={15}>
              <Grid item xs={6}>
                <NumberInput
                  source="cost"
                  data-test-id="input-cost"
                  isRequired
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  margin: "10px 0px 0px 0px",
                }}
              >
                <BooleanInput
                  source="is_available"
                  label="Available"
                  data-test-id="input-status"
                  fullWidth
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};
