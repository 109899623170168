import { Box, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import {
  TimeInput,
  Confirm,
  Create,
  SimpleForm,
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
  TextInput,
  NumberInput,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';
import JSONInput from '../JSONInput';
import InputPartner from './InputPartner';

export const CreatePage = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [formData, setFormData] = useState<any>(null);
  const [create] = useCreate();

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (error?.error) {
        throw error;
      }
      if (data === 'OK') {
        create(
          'configuration-page',
          { data: formData },
          {
            onSuccess: () => {
              redirect('/configuration-page');
              refresh();
            },
            onError(error, variables, context) {
              console.log('error', error);
              notify('' + error, {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire({
        icon: 'error',
        title: e?.error,
        target: '#muiModal',
      });
    }
  };

  const onSubmitHandler = (data: any) => {
    console.log('DATAA', data);
    setCOpen(true);
    setFormData(data);
  };

  return (
    <>
      <Confirm
        id="muiModal"
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Create title="&rsaquo; Create Configuration">
        <Box pt={5} width={{ xs: '100vW', sm: 700 }} mt={{ xs: 2, sm: 1 }}>
          <SimpleForm onSubmit={onSubmitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TimeInput fullWidth source="start_time" />
              </Grid>
              <Grid item xs={6}>
                <TimeInput fullWidth source="end_time" />
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                background: '#fafafa',
                padding: '7px 20px',
                borderRadius: '17px',
                border: '2px solid #a0a0a0',
              }}
            >
              <Grid item xs={12}>
                <Typography component="h5" variant="h6">
                  Rules
                </Typography>
                <NumberInput fullWidth source="failed_transaction_percentage" />
                <NumberInput fullWidth source="transaction_per_minute" />
                <NumberInput fullWidth source="transaction_same_code_percentage" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                marginTop: '3px',
              }}
            >
              <Grid item xs={12}>
                <TextInput fullWidth source="name" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputPartner source="partner" />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};
