import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class SupplierCatalogProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log("supplier catalog params", params);

    const filterArray = [];
    const where: Record<string, any> = {};

    if (params.filter.search_by_label) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.search_by_label}%`,
        },
      });
    }

    console.log(params.filter.search_by_availability);
    if (params.filter.search_by_availability) {
      filterArray.push({
        is_available: {
          _eq: params.filter.search_by_availability === "true",
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    const { allSupplyItemsList, error } = await this.client.gql.query({
      query: `
      query supplierCatalogsList($where: SupplyItemsWhereInput) {
        allSupplyItemsList(where: $where, ${args}) {
          data {
            id
            created_at
            supplier_id
            label
            description
            cost
            is_available
            supplier_id_data {
              name
            }
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    console.log("7777788888", { allSupplyItemsList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    console.log(allSupplyItemsList);

    return {
      data: allSupplyItemsList.data,
      total: allSupplyItemsList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log(params);
    const { getSupplyItemsById } = await this.client.gql.query({
      query: `
      query supplyItemsById($id: UUID) {
        getSupplyItemsById(id: $id) {
          id
          created_at
          supplier_id
          label
          description
          cost
          is_available
          supplier_id_data {
            name
          }
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    console.log("getSupplyItemByID", getSupplyItemsById);
    return { data: getSupplyItemsById };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log("create supp catalog", params);

    await this.client.getTable("supply_items").insert(
      Object.assign({
        supplier_id: params.data.supplier_id,
        label: params.data.label,
        cost: params.data.cost,
        is_available: params.data.is_available,
        description: params.data.description,
      })
    );

    const { data: userData } = await this.client.user.get();
    return { data: userData };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log("update catalog params", params);

    const { data } = await this.client.getTable("supply_items").update(
      Object.assign({
        id: params.id,
        data: {
          label: params.data.label,
          cost: params.data.cost,
          is_available: params.data.is_available,
          description: params.data.description,
        },
      })
    );
    return { data: data };
  }
}
