import { useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  Confirm,
  PasswordInput,
  regex,
  useCreate,
  useRedirect,
  useRefresh,
  useNotify,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';
import SelectAccount from './SelectAccount';

const useStyles = makeStyles({
  input: {
    marginBottom: '0px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const validateWhitelistIPs = regex(
  /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:;\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})*$/,
  'Delimited by semicolon or format IPs is wrong'
);

const validateConfirmPassword = (value: any, values: any) => {
  console.log('VALUE', value);
  console.log('ALL VALUE', values);
  if (value === undefined) {
    return 'Required';
  } else if (value !== values.password) {
    return "Password didn't match";
  }
  return undefined;
};

export const CreatePage = (props: any) => {
  const classes = useStyles();

  const [create] = useCreate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [formData, setFormData] = useState<any>(null);

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });

      console.log(8873123, 'ERROR-TOTP', error?.error);

      if (data === 'OK') {
        create(
          'users',
          { data: formData },
          {
            onSuccess: () => {
              refresh();
              redirect('/users');
            },
            onError(error, variables, context) {
              notify('An error has occurred. Please try again later', {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify(error?.error, {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const onSubmitHandler = (data: any) => {
    setCOpen(true);
    setFormData(data);
  };

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Create title=" &rsaquo; Create User" {...props}>
        <Box pt={5} width={{ xs: '100vW', sm: 700 }} mt={{ xs: 2, sm: 1 }}>
          <SimpleForm onSubmit={onSubmitHandler}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-email"
                  className={classes.input}
                  label="Email"
                  source="email"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-name"
                  className={classes.input}
                  label="Name"
                  source="name"
                  validate={[required()]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <PasswordInput
                  fullWidth
                  data-test-id="input-password"
                  className={classes.input}
                  label="Password"
                  source="password"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput
                  fullWidth
                  data-test-id="input-confirm-pass"
                  className={classes.input}
                  label="Confirm Password"
                  source="confirm_password"
                  onChange={(event) => console.log(event.target.value)}
                  validate={validateConfirmPassword}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-address"
                  className={classes.input}
                  label="Address"
                  source="address"
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  fullWidth
                  data-test-id="input-phone"
                  className={classes.input}
                  label="Phone"
                  source="phone"
                  validate={[required()]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SelectInput
                  source="roles"
                  data-test-id="select-roles"
                  className={classes.input}
                  choices={[
                    { id: 'Administrator', name: 'Administrator' },
                    { id: 'Admin Stock-In', name: 'Admin Stock-In' },
                    { id: 'Admin Stock-Out', name: 'Admin Stock-Out' },
                    { id: 'Staff', name: 'Staff' },
                    { id: 'Partner', name: 'Partner' },
                    { id: 'Partner View', name: 'Partner View' },
                    { id: 'Partner Top-Up', name: 'Partner Top Up' },
                    { id: 'Supplier', name: 'Supplier' },
                  ]}
                  fullWidth
                  validate={required()}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  source="status"
                  data-test-id="select-status"
                  className={classes.input}
                  choices={[
                    { id: true, name: 'Enable' },
                    { id: false, name: 'Disable' },
                  ]}
                  fullWidth
                  validate={required()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '-17px',
                }}
              >
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    console.log(472398423, formData);
                    return formData?.roles === 'Partner View' ||
                      formData?.roles === 'Partner Top-Up' ? (
                      <SelectAccount source="account_id_data" />
                    ) : (
                      <></>
                    );
                  }}
                </FormDataConsumer>
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};
