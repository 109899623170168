import { useCallback, MouseEvent } from 'react';
import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  CreateButton,
  FunctionField,
  RaRecord,
} from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Chip } from '@mui/material';
import { EditPage } from './EditPage';
import { CreatePage } from './CreatePage';
import { Settings as SettingsIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import {
  pageBadgeIcon,
  defaultCreateBtn,
  createButtonResponsiveStyle,
} from 'src/styles/global-styles';
import ReactJson from 'react-json-view';

const ListPage = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading, permissions } = usePermissions();

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const matchCreate = matchPath('/configuration-page/create', location.pathname);
  const matchEdit = matchPath('/configuration-page/:id/show/*', location.pathname);

  const handleClose = useCallback(() => {
    navigate('/configuration-page');
  }, [navigate]);

  return (
    <>
      <List
        title="Configuration List"
        actions={<></>}
        perPage={25}
        sort={{ field: 'created_at', order: 'DESC' }}
        debounce={1200}
        sx={{
          width: '1000px',
        }}
      >
        <>
          <CustomTopToolbar pageBadge={<SettingsIcon style={pageBadgeIcon} />}>
            {isAdmin && (
              <CreateButton
                variant="contained"
                size="medium"
                style={lessThanSm ? createButtonResponsiveStyle : defaultCreateBtn}
              />
            )}
          </CustomTopToolbar>
          <Datagrid
            bulkActionButtons={false}
            // @ts-ignore
            rowClick={(id) => {
              navigate(`/configuration-page/${id}/show`);
            }}
            style={{
              width: '1000px',
            }}
          >
            <FunctionField
              label="Start Time"
              render={(record: RaRecord) => {
                return <span>{record?.start_time}</span>;
              }}
            />

            <FunctionField
              label="End Time"
              render={(record: RaRecord) => {
                return <span>{record?.end_time}</span>;
              }}
            />

            <TextField source="name" />

            <FunctionField
              label="Partner"
              render={(record: RaRecord) =>
                record?.partner
                  ?.split(',')
                  .map((data: string, index: number) => (
                    <Chip key={index} style={{ margin: '5px' }} label={data} />
                  ))
              }
            />
            <FunctionField
              label="Rules"
              onClick={(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
                e.stopPropagation()
              }
              render={(record: RaRecord) => (
                <div>
                  <label>
                    <ReactJson
                      src={record.rules || {}}
                      name={false}
                      enableClipboard={false}
                      displayDataTypes={false}
                      collapsed={true}
                      displayObjectSize={false}
                    />
                  </label>
                </div>
              )}
            />
            {/* <TextField source="status" /> */}
          </Datagrid>
        </>
      </List>
      <Drawer
        variant="temporary"
        open={!!matchCreate || (!!matchEdit && matchEdit?.params?.id !== 'create')}
        anchor="right"
        onClose={handleClose}
      >
        {!!matchEdit && matchEdit?.params?.id !== 'create' && (
          <EditPage id={(matchEdit as any).params.id} onCancel={handleClose} {...props} />
        )}
        {!!matchCreate && <CreatePage onCancel={handleClose} {...props} />}
      </Drawer>
    </>
  );
};

export default ListPage;
