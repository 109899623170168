// @ts-nocheck
import React, { useCallback } from 'react';
import { useRecordContext, FunctionField, Button } from 'react-admin';
import {
  Grid,
  Box,
  Typography,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Drawer,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ShowDownlineDialog } from './ShowDownlineDetail';

export const DownlineAccountTable = ({ permissions, isLoading }) => {
  const [openDownlineDetailDialog, setOpenDownlineDetailDialog] = React.useState(false);
  const [downlineId, setDownlineId] = React.useState('');

  const record = useRecordContext();
  const downlineAccLength = record?.accounts_parent_id_list?.data?.length;
  const permissionAllowed =
    permissions.includes('Administrator') ||
    permissions.includes('Partner') ||
    permissions.includes('Staff');

  const showTable = !isLoading && downlineAccLength !== 0 && permissionAllowed;

  const handleCancelDrawer = useCallback(() => {
    setOpenDownlineDetailDialog(false);
  }, [openDownlineDetailDialog]);

  return (
    <>
      {showTable && (
        <>
          <Grid container spacing={2} marginTop={1}>
            <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Box mr={2}>
                <Typography variant="h5">Downline Accounts</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            <Box flex={2} mt={2} mr={{ xs: 0, sm: '0.5em' }}>
              <FunctionField
                label="Name"
                render={(record: any) => (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            Label
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {record?.accounts_parent_id_list?.data.map(
                          (row: { id: string; label: string }) => (
                            <TableRow
                              key={row.id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.label}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  endIcon={<VisibilityIcon />}
                                  onClick={() => {
                                    console.log(8883123, 'row', row);
                                    setDownlineId(row.id);
                                    setOpenDownlineDetailDialog(true);
                                  }}
                                >
                                  Show
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
            </Box>
          </Grid>
        </>
      )}
      <Drawer
        variant="persistent"
        open={!!openDownlineDetailDialog}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!openDownlineDetailDialog && (
          <ShowDownlineDialog downlineId={downlineId} onCancel={handleCancelDrawer} />
        )}
      </Drawer>
    </>
  );
};
