import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import { GetListParams, GetListResult } from 'react-admin';
import { ProviderBase } from './ProviderBase';
import mbaas from './Mbaas';

export class BalanceProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    const { data: userData } = await mbaas.client.user.get();
    const balanceList = await this.client.invokeApi('balance-positions', {
      account_id:
        userData?.roles.includes('Partner') ||
        userData?.roles.includes('Partner View') ||
        userData?.roles.includes('Partner Top-Up')
          ? userData.account_id
          : params.filter.account_id,
    });

    const mapBalanceList = balanceList.data.dealer.map((dt: any, idx: any) => {
      var balance = dt.balance.reduce(
        (obj: any, item: any) => ((obj[item.label] = item.value), obj),
        {}
      );

      return {
        id: idx,
        dealer: dt.dealer,
        isParent: dt.is_parent,
        type15K: balance['15K'],
        type20K: balance['20K'],
        type25K: balance['25K'],
        type30K: balance['30K'],
        type40K: balance['40K'],
        type50K: balance['50K'],
        type75K: balance['75K'],
        type100K: balance['100K'],
        BULK: balance.BULK,
      };
    });

    // Find the index of the object with "is_parent" set to true
    const parentIndex = mapBalanceList.findIndex((obj: any) => obj.isParent === true);

    // If a parent object exists, move it to the first index
    if (parentIndex !== -1) {
      const parentObject = mapBalanceList.splice(parentIndex, 1)[0];
      mapBalanceList.unshift(parentObject);
    }

    return { data: mapBalanceList, total: balanceList.data.dealer.length };
  }
}
