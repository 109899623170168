// @ts-nocheck
import { useEffect } from 'react';
import ChipInput from 'material-ui-chip-input-v5';
import { useInput } from 'react-admin';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';

const chipRenderer = (
  handleDelete,
  handleClick,
  className,
  value,
  chip,
  allValue,
  onChange,
  isAdmin,
  isPartner
) => {
  const ttt2 = () => {
    onChange(allValue.filter((v) => v !== value));
  };
  return (
    <Chip
      key={value}
      onDelete={ttt2}
      label={chip}
      className={className}
      clickable
      onClick={handleClick}
      deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      disabled={!isAdmin && !isPartner}
      size="medium"
    />
  );
};

export default function IpWhiteListField(props: any) {
  const { onChange, onBlur, isAdmin, isPartner, ...rest } = props;
  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  let thisValue = field?.value;
  // console.log(3612312, "field", field);

  if (typeof field?.value === 'string') {
    thisValue = field?.value.split(',');
  }
  useEffect(() => {
    field.onChange(field?.value || []);
  }, [field?.value.toString()]);

  return (
    <ChipInput
      {...field}
      value={thisValue || []}
      defaultValue={['ALL']}
      chipRenderer={(val) =>
        chipRenderer(
          val.handleDelete,
          val.handleClick,
          val.className,
          val.value,
          val.chip,
          thisValue,
          field.onChange,
          isAdmin,
          isPartner
        )
      }
      fullWidth={true}
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onAdd={(chip) => {
        field?.onChange([...thisValue, chip]);
      }}
      disabled={!isAdmin && !isPartner}
      label={props.label}
      {...rest}
    />
  );
}
