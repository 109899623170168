import * as React from "react";
import {
  Confirm,
  useCreate,
  useDelete,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
//
import MaterialTable, { Column } from "@material-table/core";
import mbaas from "../../provider/Mbaas";
import gql from "graphql-tag";
import { Autocomplete, TextField, MenuItem, Select } from "@mui/material";
import Swal from "sweetalert2";

interface ISuppliedItem {
  id: string;
  supplier_id: string;
  supply_route_id: string;
  product_item_id: string;
  label: string;
  description: string;
  origin_currency: string;
  cost: number;
  is_available: boolean;
  supplier_name: string;
  supplier_id_data: { id: string; name: string };
}

const ProductRouting = () => {
  const record = useRecordContext();
  console.log("====================================");
  console.log(record, 666);
  console.log("====================================");
  const mapData = (rawData: any) => {
    return rawData.map((x: any) =>
      Object.assign({
        product_item_id: record.id,
        ...x.suppy_item_id_data,
        supply_route_id: x.id,
        supplier_name: x.suppy_item_id_data.supplier_id_data.name,
        supplier_id: x.suppy_item_id_data.supplier_id_data.id,
      })
    );
  };
  const originData = mapData(record.supply_routes_product_item_id_list.data);
  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState(originData);
  //
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteOne] = useDelete();
  const refresh = useRefresh();
  const notify = useNotify();
  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitConf, setSubmitConf] = React.useState({ method: "", data: {} });
  const handleDialogClose = () => setCOpen(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await mbaas.client.invokeApi("totp", {
        action: "VALIDATE",
        token: otpCode,
      });
      console.log("ERROR-TOTP", error?.error);
      if (data === "OK") {
        if (submitConf.method === "add-supply-route") {
          create(
            "product-routings",
            { data: submitConf.data },
            {
              onSuccess: async () => {
                await initData();
                notify("Success Add Pricings", {
                  type: "success",
                });
              },
              onError(error, variables, context) {
                notify("An error has occurred. Please try again later", {
                  type: "error",
                });
              },
            }
          );
        }

        if (submitConf.method === "update-supply-route") {
          update(
            "product-routings",
            {
              //@ts-ignore
              id: submitConf?.data?.supply_route_id,
              //@ts-ignore
              data: submitConf.data?.supplyRoutesData,
              //@ts-ignore
              previousData: submitConf.data?.oldData,
            },
            {
              onSuccess: async () => {
                await initData();
                notify("Success update data", {
                  type: "success",
                });
              },
              onError(error, variables, context) {
                notify("An error has occurred. Please try again later", {
                  type: "error",
                });
              },
            }
          );
        }

        if (submitConf.method === "delete-supply-route") {
          deleteOne(
            "product-routings",
            //@ts-ignore
            {
              //@ts-ignore
              id: submitConf?.data?.supply_route_id,
              //@ts-ignore
              previousData: submitConf.data?.oldData,
            },
            {
              onSuccess: async () => {
                await initData();
                notify("Success delete data", {
                  type: "success",
                });
              },
              onError(error, variables, context) {
                notify("An error has occurred. Please try again later", {
                  type: "error",
                });
              },
            }
          );
        }
        setOtpCode("");
        setCOpen(false);
        refresh();
      } else {
        setOtpCode("");
        setCOpen(false);

        notify("An error has occurred. Please try again later", {
          type: "error",
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, "error");
    }
    setIsLoading(false);
  };
  //
  const supplierOpts: any = {};
  record.supplier_list.forEach((x: { id: string; name: string }) => {
    supplierOpts[x.id] = x.name;
  });
  //
  const columns: Array<Column<ISuppliedItem>> = [
    {
      field: "product_item_id",
      type: "string",
      editable: "never",
      hidden: true,
    },
    {
      field: "supply_route_id",
      type: "string",
      editable: "never",
      hidden: true,
    },
    {
      title: "Supplier",
      field: "supplier_id",
      render: (rowData) => rowData.supplier_name,
      editComponent(props) {
        return (
          <Select
            id="supplier"
            value={props.value || ""}
            label="Supplier"
            onChange={(e) => props.onChange(e.target.value)}
          >
            {record.supplier_list.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Label",
      field: "label",
      render: (rowData) => rowData.label,
      editComponent: (props) => {
        return (
          <Autocomplete
            disablePortal
            defaultChecked={false}
            value={
              props.rowData?.supplier_id === props.rowData?.supplier_id_data?.id
                ? props.value
                : null
            }
            id="product-label"
            sx={{ width: 300 }}
            getOptionLabel={(option) =>
              option.label || props.rowData.label || ""
            }
            options={record.supplied_items}
            filterOptions={(options, state) =>
              options.filter(
                (x: ISuppliedItem) =>
                  x.supplier_id === props.rowData.supplier_id &&
                  x.label.toLowerCase().includes(state.inputValue.toLowerCase())
              )
            }
            onChange={(_e, v) => props.onChange(v)}
            renderInput={(params) => <TextField {...params} label="product" />}
          />
        );
      },
    },
    {
      title: "Cost",
      field: "cost",
      type: "numeric",
      editable: "never",
    },
    {
      title: "Status",
      field: "is_available",
      type: "boolean",
      editable: "never",
    },
  ];
  //
  const initData = async () => {
    setIsTableLoading(true);
    const { getProductItemsById } = await mbaas.client.gql.query({
      query: gql`
        query ProductItem($id: UUID!) {
          getProductItemsById(id: $id) {
            id
            item_key
            label
            description
            stock_type
            supply_routes_product_item_id_list {
              data {
                id
                suppy_item_id_data {
                  id
                  label
                  description
                  cost
                  is_available
                  data
                  supplier_id_data {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: record.id,
      },
    });
    //
    const mappedData = mapData(
      getProductItemsById.supply_routes_product_item_id_list.data
    );
    setTableData(mappedData);
    setIsTableLoading(false);
  };
  //

  if (!record) return null;
  return (
    <>
      <Confirm
        isOpen={cOpen}
        loading={otpCode.length < 6 ? true : isLoading}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            data-test-id="input-otp"
            fullWidth
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <MaterialTable
        columns={columns}
        isLoading={isTableLoading}
        options={{
          search: false,
        }}
        data={tableData}
        editable={{
          onRowAdd: async (newData) => {
            if (Object.keys(newData).length === 0) {
              Swal.fire("There is no data to be added!", "", "error");
            } else {
              setOtpCode("");
              setSubmitConf({
                method: "add-supply-route",
                data: {
                  product_item_id: record.id,
                  // @ts-ignore
                  suppy_item_id: newData.label.id,
                },
              });
              setCOpen(true);
            }
          },
          onRowUpdate: async (newData, oldData) => {
            console.log({
              oldData,
              newData,
            });
            if (typeof newData.label === "string") {
              Swal.fire("There is no data to be updated!", "", "error");
            } else {
              setOtpCode("");
              setSubmitConf({
                method: "update-supply-route",
                data: {
                  supplyRoutesData: {
                    product_item_id: newData.product_item_id,
                    // @ts-ignore
                    suppy_item_id: newData.label.id,
                  },
                  supply_route_id: newData.supply_route_id,
                  oldData: oldData,
                },
              });
              setCOpen(true);
            }
          },
          onRowDelete: async (oldData) => {
            console.log({ oldData });
            setOtpCode("");
            setSubmitConf({
              method: "delete-supply-route",
              data: {
                supply_route_id: oldData.supply_route_id,
                product_item_id: oldData.product_item_id,
                oldData: oldData,
              },
            });
            setCOpen(true);
          },
        }}
        title={`${record.label}`}
      />
    </>
  );
};

export default ProductRouting;
