// @ts-nocheck
import { Drawer } from '@mui/material';
import * as React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Button,
  DateField,
  usePermissions,
  FunctionField,
  useNotify,
  useListContext,
  Pagination,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  LoadingPage,
  Confirm,
  useCreate,
  FilterForm,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { DateRange } from '../DateRange';
import { DisputeDetail } from './DetailPage';

import { Paper, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { endOfToday, format } from 'date-fns';
import { MultipleStop } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import {
  pageBadgeIcon,
  exportButtonDefaultStyle,
  exportButtonResponsivetStyle,
} from 'src/styles/global-styles';

const ListActions = (props) => {
  const { className, exporter, filters, isAdmin, isAdminDispute, maxResults, navigate, ...rest } =
    props;
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState(null);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);
  const ExportPurchaseLogButton = () => {
    const { filterValues } = useListContext();
    //
    const type_name = 'transactions';
    const gen_file_name = `${type_name}_${format(new Date(endOfToday()), 'yyyy-MM-dd')}`;
    //
    //
    const [exportData, { data, error }] = useCreate('reports', {
      data: {
        account_id: userData?.account_id,
        author_id: userData?.id,
        type: type_name,
        status: 'QUEUED',
        data: {
          filter: {
            ...filterValues,
          },
          metadata: {
            generated_filename: gen_file_name, // jika ada
          },
        },
      },
    });

    if (data && !error) {
      //
      console.log(767, 'ERROR');
    }

    const handleOnClick = () => {
      exportData();
      setOpen(true);
    };

    const navigateToReportPage = () => {
      navigate(encodeURI(`/reports`));
    };
    const ConfirmDialogContent = () => {
      return (
        <Paper elevation={0}>
          <Typography variant="body1" gutterBottom>
            Your file is exported as "{gen_file_name}.csv", to view the progress please visit report
            page below
          </Typography>
        </Paper>
      );
    };

    return (
      <Button
        label="Export"
        onClick={() => handleOnClick()}
        variant="contained"
        size="medium"
        style={lessThanSm ? exportButtonResponsivetStyle : exportButtonDefaultStyle}
      >
        <GetAppIcon />
        <Confirm
          isOpen={open}
          title="Dear User,"
          content={<ConfirmDialogContent />}
          onClose={() => setOpen(false)}
          confirm="Go To Report"
          onConfirm={navigateToReportPage}
        />
      </Button>
    );
  };

  return <ExportPurchaseLogButton />;
};

const TransactionsFilter = [
  <DateRange alwaysOn />,
  <TextInput source="search" label="Search" alwaysOn data-testid="search-transaction-id" />,
  <SelectInput
    source="status"
    label="Status"
    choices={[
      { id: 'QUOTE', name: 'QUOTE' },
      { id: 'PENDING', name: 'PENDING' },
      { id: 'SUCCESS', name: 'SUCCESS' },
      { id: 'FAILED', name: 'FAILED' },
    ]}
    alwaysOn
    data-testid="search-status"
  />,
  <ReferenceInput
    source="partner"
    reference="partners"
    label="Partner Name"
    alwaysOn
    perPage={9999}
  >
    <AutocompleteInput
      fullWidth
      style={{ width: '230px' }}
      source="name"
      optionValue="account_id"
      optionText={(record) => record.name}
      data-testid="search-partner"
    />
  </ReferenceInput>,
];

const PartnerTransactionsFilter = [
  <DateRange alwaysOn />,
  <TextInput source="search" label="Search" alwaysOn data-testid="search-transaction-id" />,
  <SelectInput
    source="status"
    label="Status"
    choices={[
      { id: 'QUOTE', name: 'QUOTE' },
      { id: 'PENDING', name: 'PENDING' },
      { id: 'SUCCESS', name: 'SUCCESS' },
      { id: 'FAILED', name: 'FAILED' },
    ]}
    alwaysOn
    data-testid="search-status"
  />,
];

export const DisputeListPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = React.useState(null);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);
  const { isLoading, permissions } = usePermissions();
  if (!isLoading) {
    console.log(permissions);
  }

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');
  const isAdminDispute = !isLoading && permissions.find((el: string) => el === 'Admin Dispute');
  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');
  const isPartnerView = !isLoading && permissions.find((el: string) => el === 'Partner View');
  const isStaff = !isLoading && permissions.find((el: string) => el === 'Staff');
  const match = matchPath('/dispute/:id/show/*', location.pathname);

  const notify = useNotify();

  const handleCancelDrawer = React.useCallback(() => {
    navigate(`/dispute/`);
  }, [navigate]);

  const DisputePagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;
  const postRowStyle = (record, index) => ({
    backgroundColor:
      record.status === 'PENDING'
        ? '#d8efd4'
        : record.status === 'FAILED'
        ? '#FFb7b7'
        : record.status === 'QUOTE'
        ? '#fff0db'
        : record.status === 'SUCCESS'
        ? '#b1d7f0'
        : '#fff',
  });
  var date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return !isLoading && userData ? (
    <React.Fragment>
      <List
        filterDefaultValues={{ dateGte: firstDay, dateLte: lastDay }}
        resource="dispute"
        filter={{}}
        perPage={50}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<DisputePagination />}
        filter={
          !isAdmin && !isStaff && !isAdminDispute
            ? { partner: userData?.account_id }
            : { isAdmin: isAdmin, isDispute: true }
        }
        exporter={false}
      >
        <CustomTopToolbar pageBadge={<MultipleStop style={pageBadgeIcon} />}>
          <FilterForm
            filters={isAdmin || isAdminDispute ? TransactionsFilter : PartnerTransactionsFilter}
          />
          <ListActions navigate={navigate} isAdmin={isAdmin} isAdminDispute={isAdminDispute} />
        </CustomTopToolbar>

        <Datagrid
          bulkActionButtons={false}
          rowClick={(id) => {
            navigate(`/dispute/${id}/show`);
          }}
          rowStyle={postRowStyle}
        >
          <DateField source="created_at" label="Date" showTime />
          <TextField source="account_label" label="Account" sortable={false} />
          <TextField source="reference_id" label="Reference" />
          <TextField source="product_label" label="Product" sortable={false} />
          {(isAdmin || isAdminDispute) && (
            <TextField source="supplier_label" label="Supplier" sortable={false} />
          )}
          {(isAdmin || isAdminDispute) && <TextField source="order_id" />}
          <TextField source="target" />
          <TextField source="price" />
          <TextField source="serial_number" />
          <FunctionField
            label="Status"
            source="status"
            render={(record: any) => <label>{record.status.toUpperCase()}</label>}
          />
        </Datagrid>
      </List>
      <Drawer
        variant="temporary"
        open={!!match}
        anchor="right"
        onClose={handleCancelDrawer}
        sx={{ zIndex: 100 }}
      >
        {!!match && (
          <DisputeDetail
            id={(match as any).params.id}
            isStaff={isStaff}
            isAdmin={isAdmin}
            isAdminDispute={isAdminDispute}
            isPartner={isPartner}
            isPartnerView={isPartnerView}
            onCancel={handleCancelDrawer}
          />
        )}
      </Drawer>
    </React.Fragment>
  ) : (
    <LoadingPage />
  );
};
