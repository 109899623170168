import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
import { differenceWith, toPairs, isEqual, fromPairs } from 'lodash';
export class UsersProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    const filterArray = [];
    const where: Record<string, any> = {};
    if (params.filter.search_by_email) {
      filterArray.push({
        email: {
          _ilike: `%${params.filter.search_by_email}%`,
        },
      });
    }
    if (params.filter.search_by_name) {
      filterArray.push({
        name: {
          _ilike: `%${params.filter.search_by_name}%`,
        },
      });
    }
    if (params.filter.search_by_roles) {
      filterArray.push({
        roles: {
          _has_key: `${params.filter.search_by_roles}`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }
    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const { allUsersList, error } = await this.client.gql.query({
      query: `
      query usersList($where: UsersWhereInput) {
        allUsersList(where: $where, ${args}) {
          data {
            id
            created_at
            email
            name
            address
            phone
            roles
            status
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    if (error) {
      throw new Error('GraphQL query error');
    }

    return {
      data: allUsersList.data,
      total: allUsersList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          created_at
          email
          name
          address
          phone
          roles
          status
          account_id_data{
            id
            label
          }
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: getUsersById };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const extrasObj = {
      address: params.data.address,
      name: params.data.name,
      phone: params.data.phone,
      roles: params.data.roles,
      status: params.data.status,
    };

    if (params.data.hasOwnProperty('account_id_data')) {
      Object.assign(extrasObj, {
        account_id: params.data.account_id_data.id,
      });
    }

    const dataRegis = await this.client.auth.register('local', {
      email: params.data.email,
      password: params.data.password,
      extras: {
        ...extrasObj,
      },
    });

    if (dataRegis) {
      await this.client.getTable('users').update(
        Object.assign({
          id: dataRegis.data?.id,
          data: {
            roles: [params.data.roles],
          },
        })
      );
    }

    // data = await this.client.getTable(resource).insert({
    //   email: params.data.email,
    //   address: params.data.address,
    //   name: params.data.name,
    //   phone: params.data.phone,
    //   roles: [params.data.roles],
    //   status: params.data.status,
    // });

    const { data: userData } = await this.client.user.get();

    return { data: userData };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    let updatedData = {
      email: params.data.email,
      name: params.data.name,
      address: params.data.address,
      phone: params.data.phone,
      roles: [params.data.roles],
      status: params.data.status,
      account_id: params.data.account_id_data ? params.data.account_id_data.id : null,
    };

    let previousData = {
      email: params.previousData.email,
      name: params.previousData.name,
      address: params.previousData.address,
      phone: params.previousData.phone,
      roles: [params.previousData.roles],
      status: params.previousData.status,
      account_id: params.previousData.account_id_data
        ? params.previousData.account_id_data.id
        : null,
    };

    const changes = differenceWith(toPairs(updatedData), toPairs(previousData), isEqual);

    const changesButObject = fromPairs(changes);

    const { data } = await this.client.getTable('users').update(
      Object.assign({
        id: params.id,
        data: {
          ...changesButObject,
        },
      })
    );

    return { data: data };
  }
}
