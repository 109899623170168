import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
export class PartnersProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log(83123, { resource, params, method: 'getList' });
    const where: Record<string, any> = {};
    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    let result;
    if (params.filter.type === 'create') {
      result = await this.client.gql.query({
        query: `
          query Users($role: String!) {
            allUsersList(
              where: {
                roles: { _has_key: $role }
                account_id: { _is_null: true }
              },
              ${args}
            ) {
              data {
                id
                name
              }
              count
            }
          }
        `,
        variables: {
          role: 'Partner',
        },
      });

      if (result?.error) {
        console.warn(result?.error);
        throw new Error('GraphQL query error');
      }
    } else {
      console.log(83123, 'else');
      if (params.filter.q) {
        where._or = [
          {
            name: {
              _ilike: `%${params.filter.q}%`,
            },
          },
        ];
      }
      where._and = [
        {
          roles: { _has_key: 'Partner' },
        },
        {
          account_id: { _is_null: false },
        },
      ];
      result = await this.client.gql.query({
        query: `
          query Users($where: UsersWhereInput) {
            allUsersList(
              where: $where,
              ${args}
            ) {
              data {
                id
                account_id
                name
              }
              count
            }
          }
        `,
        variables: {
          where: where,
        },
      });

      if (result?.error) {
        console.warn(result?.error);
        throw new Error('GraphQL query error');
      }
    }
    return {
      data: result?.allUsersList?.data,
      // data: data,
      total: result?.allUsersList?.count,
      // total: data.length,
    };

    // console.log(allUsersList);

    // const data = [
    //   {
    //     id: "28dc27d3-8a08-4698-b24c-23e233c533a0",
    //     name: "Novitasari",
    //     email: "novita@gmail.com",
    //     roles: "Admin",
    //     status: true,
    //     created_at: new Date().toLocaleString(),
    //   },
    //   {
    //     id: "28dc27d3-8a08-4698-b24c-23e233c533a9",
    //     name: "Angelina",
    //     email: "angelina@gmail.com",
    //     roles: "Staff",
    //     status: false,
    //     created_at: new Date().toLocaleString(),
    //   },
    // ];
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    // const { getUsersById } = await this.client.gql.query({
    //   query: `
    //   query aUser($id:UUID) {
    //     getUsersById(id:$id) {
    //       id
    //       name
    //       email
    //       roles
    //       created_at
    //     }
    //   }
    //   `,
    //   variables: {
    //     id: params.id,
    //   },
    // });

    // const data = [
    //   {
    //     id: '28dc27d3-8a08-4698-b24c-23e233c533a0',
    //     name: 'Novitasari',
    //     email: 'novita@gmail.com',
    //     roles: 'Admin',
    //     address: 'Surabaya',
    //     phone: '08123456',
    //     status: 'Disable',
    //     api_password: 'asfsadfwre',
    //     whitelist_ips: '191.12.12.12',
    //     created_at: new Date().toLocaleString(),
    //   },
    //   {
    //     id: '28dc27d3-8a08-4698-b24c-23e233c533b9',
    //     name: 'Angelina',
    //     email: 'angelina@gmail.com',
    //     roles: 'Staff',
    //     address: 'Malang',
    //     phone: '08123456',
    //     status: 'Enable',
    //     api_password: 'asfsadfwre',
    //     whitelist_ips: '191.12.12.12',
    //     created_at: new Date().toLocaleString(),
    //   },
    // ];
    // return { data: getUsersById };
    return {
      data: [],
    };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    return { data: {} };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    await this.client.invokeApi('update-user', {
      token: params.data.token,
      data: {
        email: params.data.email,
        roles: typeof params.data.roles === 'string' ? [params.data.roles] : params.data.roles,
        name: params.data.name,
        enabled: !params.data.edit_deactivated,
      },
    });

    // const { getUsersById } = await this.client.gql.query({
    //   query: `
    //   query aUser($id:UUID) {
    //     getUsersById(id:$id) {
    //       id
    //       name
    //       email
    //       roles
    //       created_at
    //     }
    //   }
    //   `,
    //   variables: {
    //     id: params.data.id,
    //   },
    // });

    // console.log(getUsersById);

    // return { data: getUsersById };
    return { data: {} };
  }
}
