// @ts-nocheck
import './App.css';
import { createBrowserHistory as createHistory } from 'history';
import { Layout } from './layout';
import { Admin, CustomRoutes, Resource, defaultTheme } from 'react-admin';
import UsersComponent from './component/Users';
import AccountsComponent from './component/Accounts';
import ProductCatalogsComponent from './component/ProductCatalogs';
import ReportsComponent from './component/Reports';
import { BalanceListPage } from './component/Balance/ListPage';
import { StatementsListPage } from './component/Statement/ListPage';
import { TransactionsListPage } from './component/Transaction/ListPage';
import { DisputeListPage } from './component/Dispute/ListPage';
import StockManagementsComponent from './component/StockManagements';
import SupplierCatalogComponent from './component/SupplierCatalog';
import { CheckTransactionListPage } from './component/CheckTransaction/ListPage';
import { authProvider, dataProvider } from './provider';
import LoginScreen from './component/LoginScreen';
import { Route } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import Dashboard from './component/Dashboard';
import Configuration from './component/ConfigurationPage';

const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: '#6f646b',
      },
      secondary: {
        main: '#6f646b',
      },
      text: {
        primary: '#263238',
        secondary: '#000000',
      },
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif', 'notasansregular'].join(','),
    },
  })
);

function validatePermission(permission: string[], ref: string[]) {
  if (permission.length > 0) {
    return ref.includes(permission[0]);
  } else {
    return false;
  }
}

function App() {
  const history = createHistory();

  return (
    <Admin
      title="New MKIOS"
      layout={Layout}
      dashboard={Dashboard}
      history={history}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginScreen}
      // @ts-ignore
      theme={theme}
    >
      {(permissions) => {
        return [
          validatePermission(permissions, ['Administrator', 'Admin Dispute', 'Staff']) ? (
            <Resource name="users" {...UsersComponent} />
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
          ]) ? (
            <Resource name="accounts" {...AccountsComponent} />
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
            'Partner View',
          ]) ? (
            <Resource name="transactions" />
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
            'Partner View',
          ]) ? (
            <CustomRoutes>
              <Route path="/check-transactions" element={<CheckTransactionListPage />} />
            </CustomRoutes>
          ) : (
            <></>
          ),
          validatePermission(permissions, ['Administrator', 'Admin Dispute']) ? (
            <Resource name="dispute" />
          ) : (
            <></>
          ),
          validatePermission(permissions, ['Administrator', 'Admin Dispute', 'Staff']) ? (
            <Resource
              name="product-catalogs"
              create={<ProductCatalogsComponent.list />}
              show={<ProductCatalogsComponent.list />}
              list={<ProductCatalogsComponent.list />}
            />
          ) : (
            <></>
          ),
          validatePermission(permissions, ['Administrator']) ? (
            <Resource name="configuration-page" {...Configuration} />
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
            'Partner View',
          ]) ? (
            <CustomRoutes>
              <Route path="/transactions" element={<TransactionsListPage />} />
              <Route path="/transactions/:id/show/*" element={<TransactionsListPage />} />
            </CustomRoutes>
          ) : (
            <></>
          ),
          validatePermission(permissions, ['Administrator', 'Admin Dispute']) ? (
            <CustomRoutes>
              <Route path="/dispute" element={<DisputeListPage />} />
              <Route path="/dispute/:id/show/*" element={<DisputeListPage />} />
            </CustomRoutes>
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Admin Stock-In',
            'Admin Stock-Out',
            'Partner',
            'Partner View',
            'Partner Top-Up',
          ]) ? (
            <CustomRoutes>
              <Route path="/current-balance" element={<BalanceListPage />} />
            </CustomRoutes>
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
            'Partner View',
            'Admin Stock-In',
            'Admin Stock-Out',
          ]) ? (
            <CustomRoutes>
              <Route path="/statements" element={<StatementsListPage />} />
            </CustomRoutes>
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Admin Stock-In',
            'Admin Stock-Out',
            'Partner',
            'Partner Top-Up',
          ]) ? (
            <Resource name="stock-managements" {...StockManagementsComponent} />
          ) : validatePermission(permissions, [
              'Administrator',
              'Admin Dispute',
              'Staff',
              'Partner',
              'Partner View',
            ]) ? (
            <Resource name="stock-managements" list={StockManagementsComponent.list} />
          ) : (
            <></>
          ),
          validatePermission(permissions, ['Administrator', 'Admin Dispute', 'Staff']) ? (
            <Resource name="supplier-catalog" {...SupplierCatalogComponent} />
          ) : (
            <></>
          ),
          validatePermission(permissions, [
            'Administrator',
            'Admin Dispute',
            'Staff',
            'Partner',
            'Partner View',
          ]) ? (
            <Resource name="reports" {...ReportsComponent} />
          ) : (
            <></>
          ),
        ];
      }}
    </Admin>
  );
}

export default App;
