import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { PersonAdd as AddUserIcon } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import { Confirm, usePermissions, useRedirect, useRefresh } from 'react-admin';
import mbaas from '../../provider/Mbaas';
import Swal from 'sweetalert2';
import ReactJson from 'react-json-view';
import CustomAutoComplete from './CustomAutoComplete';
/** for demo need only */
/** import { authenticator } from "otplib"; */

export default function PartnerGeneratorDialog() {
  let partnerPayload = {
    email: '',
    account_name: '',
    user_name: '',
  };

  const [open, setOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [parentId, setParentId] = React.useState('');

  const [partnerData, setPartnerData] = React.useState(partnerPayload);
  const [generatedData, setGeneratedData] = React.useState({});
  const [openConfirm, setOpenConfirm] = React.useState(false);
  console.log({ generatedData });

  React.useEffect(() => {
    if (parentId !== '' && parentId !== undefined) {
      Object.assign(partnerPayload, {
        parent_id: parentId,
      });
    }

    setPartnerData(partnerPayload);
  }, [parentId]);

  const redirect = useRedirect();
  const refresh = useRefresh();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleGen = async () => {
    const { data: otpData, error: otpError } = await mbaas.client.invokeApi('totp', {
      action: 'VALIDATE',
      token: otpCode,
    });
    if (otpError) {
      Swal.fire(`Request failed: ${otpError?.error}`, '', 'error');
      setOpen(false);
    }
    if (otpData === 'OK') {
      const { data: partnerGenerationData, error: partnerGenerationError } =
        await mbaas.client.invokeApi('partner-generation', partnerData);
      if (partnerGenerationError) {
        setOpen(false);
        setOtpCode('');
        Swal.fire(`Request failed: ${partnerGenerationError?.error}`, '', 'error');
      }
      setGeneratedData(partnerGenerationData.data);
      setOtpCode('');
      setOpen(false);
      setOpenConfirm(true);
      setPartnerData({
        email: '',
        account_name: '',
        user_name: '',
      });
      setParentId('');
      redirect(`/users`);
      refresh();
    }
  };
  const handleConfirm = () => {
    setOpenConfirm(false);
  };
  const { permissions } = usePermissions();

  console.log(773124, 'partnerData', partnerData);
  console.log(773124, 'partnerPayload', partnerPayload);
  console.log(773124, 'parentId', parentId);

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        startIcon={<AddUserIcon />}
        onClick={handleClickOpen}
        disabled={permissions.includes('Staff') ? true : false}
        data-test-id="new-user-btn"
        style={{
          backgroundColor: '#02a499',
        }}
      >
        Generate Partner
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Generate Partner</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <CustomAutoComplete setParentId={setParentId} />

            <TextField
              type="email"
              label="Email"
              fullWidth
              variant="standard"
              data-test-id="input-email"
              value={partnerData.email}
              onChange={(e) => setPartnerData({ ...partnerData, email: e.target.value })}
            />
            <TextField
              type="text"
              label="Name of User"
              variant="standard"
              fullWidth
              data-test-id="input-user"
              value={partnerData.user_name}
              onChange={(e) => setPartnerData({ ...partnerData, user_name: e.target.value })}
            />
            <TextField
              type="text"
              label="Account Name"
              variant="standard"
              fullWidth
              data-test-id="input-account"
              value={partnerData.account_name}
              onChange={(e) => setPartnerData({ ...partnerData, account_name: e.target.value })}
              style={{ marginBottom: '20px' }}
            />
          </Stack>
          <TextField
            type="number"
            label="OTP 2FA"
            variant="standard"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            /** for demo need only */
            // onMouseOver={() => {
            //   const token = authenticator.generate("NMMCUKJPLJSF663H");
            //   setOtpCode(token);
            // }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ margin: 2 }}
            variant="contained"
            color="error"
            size="small"
            onClick={handleGen}
            data-test-id="add-user-btn"
          >
            Generate!
          </Button>
        </DialogActions>
      </Dialog>
      <Confirm
        cancel="close"
        isOpen={openConfirm}
        title="Generated Partner"
        content={
          <ReactJson
            src={generatedData || []}
            shouldCollapse={false}
            enableClipboard={false}
            displayDataTypes={false}
            displayObjectSize={false}
            name={false}
          />
        }
        onConfirm={handleConfirm}
        onClose={handleConfirm}
        confirm="OK"
      />
    </div>
  );
}
