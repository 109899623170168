// @ts-nocheck
import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
  Confirm,
  useRefresh,
  useCreate,
  useRedirect,
  useNotify,
} from 'react-admin';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';
import CustomAutoComplete from './CustomAutoComplete';

const CreatePage = (props: any) => {
  const { isLoading, permissions } = usePermissions();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const refresh = useRefresh();
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();

  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const handleSubmit = async () => {
    try {
      const { data } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      if (data === 'OK') {
        create(
          'accounts',
          { data: formData },
          {
            onSuccess: () => {
              redirect('/accounts');
            },
            onError(error, variables, context) {
              notify('An error has occurred. Please try again later', {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);
        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const onSubmitHandler = (data) => {
    setCOpen(true);
    setFormData(data);
  };

  console.log(73124343, 'formData', formData);

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Create redirect="/accounts">
        <Box display="block" m={2} justifyContent="center" alignItems="center">
          <SimpleForm onSubmit={onSubmitHandler}>
            <Grid container spacing={2}>
              <Box flex={2}>
                {!isLoading && isAdmin && (
                  <>
                    <div
                      style={{
                        marginBottom: '17px',
                      }}
                    >
                      <CustomAutoComplete context="createAccount" source="parentId" />
                    </div>
                    <ReferenceInput
                      filter={{ type: 'create' }}
                      source="id"
                      reference="partners"
                      label="User"
                    >
                      <SelectInput
                        data-test-id="select-user"
                        variant="standard"
                        source="name"
                        optionText={(choice) => choice.name}
                        label="User"
                        validate={[required()]}
                        fullWidth
                      />
                    </ReferenceInput>
                  </>
                )}
                <TextInput
                  data-test-id="input-label"
                  fullWidth
                  variant="standard"
                  label="Label"
                  source="label"
                  validate={[required()]}
                />
                <TextInput
                  data-test-id="input-contact"
                  fullWidth
                  label="Contact"
                  variant="standard"
                  source="contact"
                  validate={[required()]}
                />
                <SelectInput
                  data-test-id="select-api-ver"
                  defaultValue={'v1'}
                  source="api_version"
                  label="API Version"
                  variant="standard"
                  choices={[
                    { id: 'v1', value: 'v1' },
                    { id: 'v2', value: 'v2' },
                  ]}
                  optionText={(record) => record.value}
                  fullWidth
                />
              </Box>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" alignItems="center" gap={1}>
            Are you sure to do that?
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">Insert your TOTP code to confirm</DialogContentText>
          <Grid
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            style={{ marginTop: '20px' }}
          ></Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setFormData(null);
            }}
            color="primary"
            size="small"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreatePage;
