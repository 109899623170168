import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
export class ProductRoutingsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const data = await this.client.getTable("supply_routes").insert({
      product_item_id: params?.data.product_item_id,
      suppy_item_id: params?.data.suppy_item_id,
    });

    return { data: data?.data };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const { updateSupplyRoutesById } = await this.client.gql.mutation({
      mutation: `
        mutation updateSupplyRoutes($ids: [UUID!]!, $input: SupplyRoutesUpdateInput!) {
          updateSupplyRoutesById(ids: $ids, input: $input) {
            id
            product_item_id
            suppy_item_id
          }
        }
      `,
      variables: {
        ids: params?.id,
        input: params?.data,
      },
    });
    console.log(updateSupplyRoutesById, "INI APA");

    return { data: updateSupplyRoutesById[0] };
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    const queryResult = await this.client.gql.mutation({
      mutation: `
            mutation($ids: [UUID!]!){
              deleteSupplyRoutesById(ids:$ids)
            }
          `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return { data: queryResult?.deleteSupplyRoutesById[0] } as DeleteResult;
  }
}
