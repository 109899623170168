import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class StockTransfersProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params.filter.q) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: "ASC",
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: "created_at",
    });
    const { allDepositTypesList, error } = await this.client.gql.query({
      query: `
      query depositTypeList($where: DepositTypesWhereInput) {
        allDepositTypesList(where: $where, ${args}) {
          data {
            id
            label
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    if (error) {
      throw new Error("GraphQL query error");
    }

    console.log("aswfda", allDepositTypesList);
    // const mapAllDepositTypeList = allDepositTypesList.data.map((dt: any) => {
    //   return {
    //     id: dt.id,
    //     label: dt.label,
    //   };
    // });
    const mapAllDepositTypeList = allDepositTypesList.data.filter(function (
      dt: any
    ) {
      return dt.label !== "1K" && dt.label !== "5K" && dt.label !== "10K";
    });

    console.log(mapAllDepositTypeList, "====================");

    return {
      data: mapAllDepositTypeList,
      total: mapAllDepositTypeList.length,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    return { data: {} };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    return { data: [] };
  }
}
