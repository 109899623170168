import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class CheckTransactionsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log(123123, "params", params);

    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    if (params?.filter?.target) {
      filterArray.push({
        target: {
          _eq: `${params?.filter?.target}`,
        },
      });
    }

    if (params?.filter?.id) {
      filterArray.push({
        reference_id: {
          _eq: `${params?.filter?.id}`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page + 1,
      sort: params.sort.field,
    });

    const [
      { allTransactionsList, error },
      { data: accountData },
      { data: productData },
    ] = await Promise.all([
      this.client.gql.query({
        query: `
        query transactionsList($where: TransactionsWhereInput) {
          allTransactionsList(where: $where, ${args}) {
            data {
              id
              product_item_id
              account_id
              reference_id
              order_id
              is_dispute
              serial_number
              created_at
              target
              price
              status
              metadata
            }
            count
          }
        }
        `,
        variables: {
          where,
        },
      }),
      this.client.getTable("accounts").limit(100).read(),
      this.client.getTable("product_items").limit(150).read(),
    ]);
    const products: { [key: string]: string } = {};
    const accounts: { [key: string]: string } = {};
    accountData.forEach((x) => (accounts[x.id] = x.label));
    productData.forEach((x) => (products[x.id] = x.label));
    if (error) {
      throw new Error("GraphQL query error");
    }

    const mapped = allTransactionsList.data.map((el: any) => {
      return {
        ...el,
        account_label: accounts[el.account_id],
        product_label: products[el.product_item_id],
        supplier_label: "NGRS",
      };
    });

    console.log(123123, "mapped", mapped);
    console.log(123123, "total", allTransactionsList.count);

    return { data: mapped, total: allTransactionsList.count };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const [
      { allTransactionsList, error },
      { data: accountData },
      { data: productData },
    ] = await Promise.all([
      this.client.gql.query({
        query: `
        query transactionsList($where: TransactionsWhereInput) {
          allTransactionsList(where: $where) {
            data {
              id
              product_item_id
              account_id
              reference_id
              order_id
              is_dispute
              serial_number
              created_at
              target
              price
              status
              metadata
            }
            count
          }
        }
        `,
        variables: {
          where: {
            reference_id: {
              _eq: params.id,
            },
          },
        },
      }),
      this.client.getTable("accounts").limit(100).read(),
      this.client.getTable("product_items").limit(150).read(),
    ]);
    const products: { [key: string]: string } = {};
    const accounts: { [key: string]: string } = {};
    accountData.forEach((x) => (accounts[x.id] = x.label));
    productData.forEach((x) => (products[x.id] = x.label));
    if (error) {
      throw new Error("GraphQL query error");
    }

    const mapped = allTransactionsList.data.map((el: any) => {
      return {
        ...el,
        account_label: accounts[el.account_id],
        product_label: products[el.product_item_id],
        supplier_label: "NGRS",
      };
    });

    return { data: mapped };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    return { data: {} };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    return { data: [] };
  }
}
