//@ts-nocheck
import React, { useState } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  CreateButton,
  DateField,
  Confirm,
  Button,
  usePermissions,
  useNotify,
  useRefresh,
  TextField,
  FilterForm,
  useGetOne,
} from 'react-admin';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';
import { TextField as TextFieldMui } from '@mui/material';
import { SyncAlt as StockManagementsIcon } from '@mui/icons-material';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import { pageBadgeIcon } from 'src/styles/global-styles';

const listFilters = [
  <ReferenceInput source="account_id" reference="accounts" alwaysOn>
    <AutocompleteInput
      label="Destination"
      source="id"
      optionText={(choice) => choice.label}
      sx={{ width: '250px' }}
      variant="standard"
    />
  </ReferenceInput>,
];

const listFilterPartner = [];

const ActionButton = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const handleClick = () => {
    setCOpen(true);
  };

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (data === 'OK') {
        const cancelDeposit = await mbaas.client.invokeApi('deposits-cancel', {
          deposit_reference_id: props.reference,
        });

        if (cancelDeposit.error) {
          console.warn(cancelDeposit.error);
          notify('' + cancelDeposit.error.error, {
            type: 'error',
          });
        }

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        data-test-id="cancel-deposit"
        color="secondary"
        size="small"
        label="Cancel Deposit"
      />

      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextFieldMui
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
    </>
  );
};

const TransferLogsListPage = () => {
  const [reference, setReference] = useState('');
  const { isLoading, permissions } = usePermissions();
  const [userData, setUserData] = React.useState(null);
  const {
    data: dataAccount,
    isLoading: getOneAccountLoading,
    error,
  } = useGetOne('accounts', { id: userData?.account_id });

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const isAdminStockOut = !isLoading && permissions.find((el: string) => el === 'Admin Stock-Out');

  const isAdminStockIn = !isLoading && permissions.find((el: string) => el === 'Admin Stock-In');

  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');

  const isPartnerView = !isLoading && permissions.find((el: string) => el === 'Partner View');

  const isPartnerTopUp = !isLoading && permissions.find((el: string) => el === 'Partner Top-Up');

  // account child length
  const thisAccountChild = dataAccount?.accounts_parent_id_list?.data.length;

  // create button in role PARTNER is appear when role is PARTNER AND have child
  const allowedPartner = isPartner && thisAccountChild !== 0;

  // create button is appear when user login as admin_stock_in (TELIN) OR
  // admin_stock_out (TELIN) OR
  // partner (PARTNER)
  const showButton = isAdminStockIn || isAdminStockOut || allowedPartner || isPartnerTopUp;
  // log =====
  console.log(883721312, 'userData', userData);
  console.log(883721312, 'dataAccount', dataAccount);

  return (
    <>
      <List
        resource="stock-managements"
        title={isPartner || isPartnerView || isPartnerTopUp ? 'Transfer Log' : 'Stock Managements'}
        perPage={25}
        sort={{ field: 'datetime', order: 'desc' }}
        debounce={1200}
        filter={
          isPartner || isPartnerView || isPartnerTopUp ? { account_id: userData?.account_id } : {}
        }
        exporter={false}
        hasCreate={false}
      >
        <CustomTopToolbar pageBadge={<StockManagementsIcon style={pageBadgeIcon} />}>
          <FilterForm
            filters={
              !isPartner && !isPartnerView && !isPartnerTopUp ? listFilters : listFilterPartner
            }
          />
          {showButton ? <CreateButton /> : <></>}
        </CustomTopToolbar>
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id: any, resource: any, record: any) => {
            setReference(record.reference_id);
            return record;
          }}
        >
          <DateField source="datetime" label="Date" showTime />
          <TextField source="from" label="Source" sortable={false} />
          <TextField source="destination" />
          <TextField source="reference_id" label="Reference" />

          <NumberField textAlign="left" label="15K" source="type15K" sortable={false} />
          <NumberField textAlign="left" label="20K" source="type20K" sortable={false} />
          <NumberField textAlign="left" label="25K" source="type25K" sortable={false} />
          <NumberField textAlign="left" label="30K" source="type30K" sortable={false} />
          <NumberField textAlign="left" label="40K" source="type40K" sortable={false} />
          <NumberField textAlign="left" label="50K" source="type50K" sortable={false} />
          <NumberField textAlign="left" label="75K" source="type75K" sortable={false} />
          <NumberField textAlign="left" label="100K" source="type100K" sortable={false} />
          <NumberField textAlign="left" label="BULK" source="BULK" sortable={false} />

          {showButton ? (
            <FunctionField
              render={(record: any) => {
                if (record.status === 'ACTIVE') {
                  return <ActionButton reference={reference} />;
                } else {
                  return <TextField source="status" sortable={false} />;
                }
              }}
            />
          ) : (
            <TextField source="status" sortable={false} />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default TransferLogsListPage;
