// @ts-nocheck
import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useGetOne, Loading, useAuthenticated, usePermissions } from 'react-admin';

export default () => {
  useAuthenticated(); // redirects to login if not authenticated
  const { isLoading, permissions } = usePermissions();
  const [value, setValue] = React.useState('1');
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');

  const {
    isLoading: loadingPartner,
    data: dataPartner,
    error: errorPartner,
  } = useGetOne('dashboard', {
    id: 35,
  });

  const {
    data: dataPartnerWithCallback,
    isLoading: loadingPartnerWithCallback,
    error: errorPartnerWithCallback,
  } = useGetOne('dashboard', { id: 107 });

  if (loadingPartner || loadingPartnerWithCallback) {
    return <Loading />;
  }

  if (errorPartnerWithCallback || errorPartner) {
    console.log({
      error: errorPartnerWithCallback || errorPartner,
    });
  }
  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Suspicious" value="1" />
          <Tab label="Partner With Callback" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1" style={{ height: '75%', padding: '0px' }}>
        {dataPartner && dataPartner.metabaseUrl && (
          <iframe
            frameBorder={0}
            src={dataPartner.metabaseUrl}
            width="100%"
            height="100%"
            allowTransparency
          />
        )}
      </TabPanel>
      {isPartner && (
        <TabPanel value="2" style={{ height: '75%', padding: '0px' }}>
          {dataPartnerWithCallback && dataPartnerWithCallback.metabaseUrl && (
            <iframe
              frameBorder={0}
              src={dataPartnerWithCallback.metabaseUrl}
              width="100%"
              height="100%"
              allowTransparency
            />
          )}
        </TabPanel>
      )}
    </TabContext>
  );
};
