import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class ReportsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const where: Record<string, any> = {};
    if (params.filter.type) {
      where._or = [
        {
          type: {
            _ilike: `%${params.filter.type}%`,
          },
        },
      ];
    }
    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const { allReportsList, error } = await this.client.gql.query({
      query: `
      query reportsList($where: ReportsWhereInput!) {
        allReportsList(where: $where, ${args}) {
            data{
                id
                created_at
                account_id_data{
                    label
                }
                reason
                author_id_data{
                    name
                }
                status
                uri
            }
          count
        }
      }
      `,
      variables: { where },
    });
    console.log("7777788888", { allReportsList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    console.log(allReportsList);

    return {
      data: allReportsList.data,
      total: allReportsList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log(params);
    const { getReportsById } = await this.client.gql.query({
      query: `
      query aReport($id:UUID) {
        getReportsById(id:$id) {
            id
            created_at
            account_id_data{
                label
            }
            author_id_data{
                name
            }
            status
            uri
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    console.log("getuserbyID", getReportsById);

    return { data: getReportsById };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }
  async create(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<any>> {
    console.log({ method: "createReport", resource, params });
    const toBeCreatedData: any = {
      ...params.data,
    };
    const queryResult = await this.client.gql.mutation({
      mutation: `
        mutation ($input: [ReportsCreateInput!]!) {
          createReports(input: $input) {
            id
            created_at
            updated_at
            author_id
            account_id
            type
            status
            uri
            data
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createReports[0] } as CreateResult;
  }
}
