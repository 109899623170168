import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { differenceWith, toPairs, isEqual, fromPairs } from 'lodash';
import { ProviderBase } from './ProviderBase';
import gql from 'graphql-tag';
import { buildArgs } from './utils';
import convertDateTimetoTime from 'src/utils/functions/convertDateTimetoTime';

export class ConfigurationPageProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log(1234123, { resource, params, method: 'getList' });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    const args = buildArgs({
      // @ts-ignore
      order: params?.sort?.order || 'DESC',
      size: params?.pagination?.perPage,
      pageNumber: params?.pagination?.page,
      sort: params?.sort?.field || 'created_at',
    });

    const queryResult = await this.client.gql.query({
      query: gql`
        query virtualMsisdn($where:ConfigurationsWhereInput) {
          allConfigurationsList(where: $where, ${args}) {
            data{
              id
              created_at
              updated_at
              rules
              name
              start_time
              end_time
              partner
              status
              updated_by
          }
            count
          }
        }
      `,
      variables: {
        where,
      },
    });
    return {
      data: queryResult?.allConfigurationsList?.data || [],
      total: queryResult?.allConfigurationsList?.count || 0,
    } as GetListResult;
  }

  async create(resource: string, params: CreateParams<any>): Promise<CreateResult<any>> {
    console.log(83123, { resource, params, method: 'create' });

    const toBeCreatedData: any = {
      ...params.data,
      start_time: convertDateTimetoTime(params?.data?.start_time),
      end_time: convertDateTimetoTime(params?.data?.end_time),
      partner: params?.data?.partner.join(','),
      rules: {
        'failed-transaction-percentage': params?.data.failed_transaction_percentage,
        'transaction-per-minute': params?.data.transaction_per_minute,
        'transaction-same-code-percentage': params?.data.transaction_same_code_percentage,
      },
    };

    delete toBeCreatedData?.failed_transaction_percentage;
    delete toBeCreatedData?.transaction_per_minute;
    delete toBeCreatedData?.transaction_same_code_percentage;

    console.log(723, 'toBeCreatedData', toBeCreatedData);
    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($input: [ConfigurationsCreateInput!]!) {
          createConfigurations(input: $input) {
            id
            created_at
            updated_at
            rules
            name
            start_time
            end_time
            partner
            status
            updated_by
          }
        }
      `,
      variables: {
        input: toBeCreatedData,
      },
    });

    return { data: queryResult?.createConfigurations[0] } as CreateResult;
  }

  async getOne(resource: string, params: GetOneParams<any>): Promise<GetOneResult<any>> {
    console.log({ method: 'getOne', resource, params });
    const queryResult = await this.client.gql.query({
      query: gql`
        query aConfiguration($id: UUID!) {
          getConfigurationsById(id: $id) {
            id
            created_at
            updated_at
            rules
            name
            start_time
            end_time
            partner
            status
            updated_by
          }
        }
      `,
      variables: {
        id: params.id,
      },
    });

    const newObj = {
      ...queryResult?.getConfigurationsById,
      start_time: queryResult?.getConfigurationsById?.start_time.substring(0, 5),
      end_time: queryResult?.getConfigurationsById?.end_time.substring(0, 5),
      failed_transaction_percentage:
        queryResult?.getConfigurationsById?.rules['failed-transaction-percentage'],
      transaction_per_minute: queryResult?.getConfigurationsById?.rules['transaction-per-minute'],
      transaction_same_code_percentage:
        queryResult?.getConfigurationsById?.rules['transaction-same-code-percentage'],
    };

    return {
      data: newObj,
    } as GetOneResult;
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    console.log(748923, { method: 'update', resource, params });

    delete params?.data?.created_at;
    delete params?.data?.updated_at;

    const updatedData = {
      start_time: convertDateTimetoTime(params.data.start_time),
      end_time: convertDateTimetoTime(params.data.end_time),
      status: params.data.status,
      rules: {
        'failed-transaction-percentage': params?.data.failed_transaction_percentage,
        'transaction-per-minute': params?.data.transaction_per_minute,
        'transaction-same-code-percentage': params?.data.transaction_same_code_percentage,
      },
      name: params.data.name,
      partner:
        typeof params.data?.partner === 'string'
          ? params.data?.partner
          : params.data?.partner?.join() || 'ALL',
    };

    const previousData = {
      start_time: convertDateTimetoTime(params.previousData.start_time),
      end_time: convertDateTimetoTime(params.previousData.end_time),
      status: params.previousData.status,
      rules: {
        'failed-transaction-percentage': params?.previousData.failed_transaction_percentage,
        'transaction-per-minute': params?.previousData.transaction_per_minute,
        'transaction-same-code-percentage': params?.previousData.transaction_same_code_percentage,
      },
      name: params.previousData.name,
      partner:
        typeof params.previousData?.partner === 'string'
          ? params.previousData?.partner
          : params.previousData?.partner?.join() || 'ALL',
    };

    const changes = differenceWith(toPairs(updatedData), toPairs(previousData), isEqual);

    const changesButObject = fromPairs(changes);

    const queryResult = await this.client.gql.mutation({
      mutation: gql`
        mutation ($ids: [UUID!]!, $input: ConfigurationsUpdateInput!) {
          updateConfigurationsById(ids: $ids, input: $input) {
            id
            created_at
            updated_at
            rules
            name
            start_time
            end_time
            partner
            status
            updated_by
          }
        }
      `,
      variables: {
        ids: [`${params.id}`],
        input: changesButObject,
      },
    });

    return { data: queryResult?.updateConfigurationsById[0] } as UpdateResult;
  }
}
