// @ts-nocheck
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { dataProvider } from '../../provider';
import debounce from 'lodash/debounce';

export default function CustomAutoComplete({ setParentId }) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    const fetchData = debounce((searchValue) => {
      setLoading(true);
      dataProvider
        .getList('accounts', {
          filter: { q: searchValue },
          pagination: { page: 0, perPage: 10 },
          sort: {
            field: 'created_at',
            order: 'DESC',
          },
        })
        .then((data) => {
          console.log(31243123, 'data', data);
          setOptions(data.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }, 750);

    fetchData(value);

    return () => {
      fetchData.cancel(); // Cancel any pending debounce invocation on component unmount
    };
  }, [value]);

  return (
    <Autocomplete
      options={options}
      onChange={(event, val) => setParentId(val?.id)}
      getOptionLabel={(option) => option?.label || ''}
      renderInput={(params) => <TextField {...params} label="Select Parent" variant="standard" />}
      onInputChange={(event, value) => setValue(value)}
      loading={loading}
      fullWidth
    />
  );
}
