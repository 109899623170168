import { Drawer } from '@mui/material';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  usePermissions,
  FilterButton,
  CreateButton,
  TextInput,
  SelectInput,
  ExportButton,
  FilterForm,
  NumberField,
} from 'react-admin';
import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { EditPage } from './EditPage';
import { CreatePage } from './CreatePage';
import { Warehouse as SupplierCatalogIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import {
  pageBadgeIcon,
  exportButtonDefaultStyle,
  exportButtonResponsivetStyle,
  defaultCreateBtn,
  createButtonResponsiveStyle,
} from 'src/styles/global-styles';

const listFilters = [
  <TextInput label="Label" source="search_by_label" variant="standard" />,
  <SelectInput
    label="Availability"
    source="search_by_availability"
    optionText="label"
    choices={[
      { id: 'true', label: 'Available' },
      { id: 'false', label: 'Unavailable' },
    ]}
    variant="standard"
  />,
];

const ListPage = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading, permissions } = usePermissions();

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const matchCreate = matchPath('/supplier-catalog/create', location.pathname);
  const matchEdit = matchPath('/supplier-catalog/:id/show/*', location.pathname);

  const handleClose = useCallback(() => {
    navigate('/supplier-catalog');
  }, [navigate]);

  return (
    <>
      <List
        title="Supplier Catalog"
        actions={<></>}
        perPage={25}
        sort={{ field: 'created_at', order: 'ASC' }}
        debounce={1200}
        sx={{
          width: '1000px',
        }}
      >
        <>
          <CustomTopToolbar pageBadge={<SupplierCatalogIcon style={pageBadgeIcon} />}>
            {/* show filter input field when filter is selected from filter button */}
            <FilterForm filters={listFilters} />
            {/* show filter button */}
            <FilterButton filters={listFilters} />
            {isAdmin && (
              <CreateButton
                variant="contained"
                size="medium"
                style={lessThanSm ? createButtonResponsiveStyle : defaultCreateBtn}
              />
            )}
            {/* show export button */}
            <ExportButton
              variant="contained"
              size="medium"
              style={lessThanSm ? exportButtonResponsivetStyle : exportButtonDefaultStyle}
            />
          </CustomTopToolbar>
          <Datagrid
            bulkActionButtons={false}
            // @ts-ignore
            rowClick={(id) => {
              navigate(`/supplier-catalog/${id}/show`);
            }}
            style={{
              width: '1000px',
            }}
          >
            <TextField source="supplier_id_data.name" label="Supplier" />
            <TextField source="label" label="Product" />
            <TextField source="description" />
            <NumberField source="cost" textAlign="left" align="left" />
            <BooleanField source="is_available" label="Available" />
          </Datagrid>
        </>
      </List>
      <Drawer
        variant="temporary"
        open={!!matchCreate || (!!matchEdit && matchEdit?.params?.id !== 'create')}
        anchor="right"
        onClose={handleClose}
      >
        {!!matchEdit && matchEdit?.params?.id !== 'create' && (
          <EditPage id={(matchEdit as any).params.id} onCancel={handleClose} {...props} />
        )}
        {!!matchCreate && <CreatePage onCancel={handleClose} {...props} />}
      </Drawer>
    </>
  );
};

export default ListPage;
