// @ts-nocheck
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  CreateButton,
  usePermissions,
  LoadingPage,
  useNotify,
  BooleanField,
  FunctionField,
  Button,
  useRefresh,
  RaRecord,
  Confirm,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import { SupervisedUserCircle } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import { pageBadgeIcon, defaultCreateBtn } from 'src/styles/global-styles';
import blockAccount from 'src/utils/functions/blockAccount';
import { TextField as MuiTextfield } from '@mui/material';
import Swal from 'sweetalert2';

const ListPage = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [userData, setUserData] = React.useState(null);
  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [recordId, setRecordId] = React.useState('');
  const [isBlocked, setIsBlocked] = React.useState(false);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const { isLoading, permissions } = usePermissions();

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const isStaff = !isLoading && permissions.find((el: string) => el === 'Staff');

  const handleConfirm = async (recordId, is_blocked) => {
    console.log(636124, 'recordId', recordId);
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (error?.error) {
        throw error;
      }
      if (data === 'OK') {
        const result = await blockAccount(recordId as string, !is_blocked);

        if (result?.data) {
          refresh();
          setOtpCode('');
          setCOpen(false);
          refresh();
        }
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire({
        icon: 'error',
        title: e?.error,
        target: '#muiModal',
      });
    }
  };

  return !isLoading && userData ? (
    <>
      <Confirm
        id="muiModal"
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <MuiTextfield
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={() => handleConfirm(recordId, isBlocked)}
        onClose={() => setCOpen(false)}
      />
      <List
        perPage={25}
        sort={{ field: 'created_at', order: 'desc' }}
        filter={!isAdmin && !isStaff ? { partner: userData?.account_id } : {}}
        hasCreate={false}
        exporter={false}
        sx={{
          width: '1000px',
        }}
      >
        <CustomTopToolbar pageBadge={<SupervisedUserCircle style={pageBadgeIcon} />}>
          {isAdmin ? (
            <CreateButton
              data-test-id="create-btn"
              variant="contained"
              size="medium"
              style={defaultCreateBtn}
            />
          ) : (
            <></>
          )}
        </CustomTopToolbar>
        <Datagrid bulkActionButtons={false}>
          <DateField source="created_at" label="Date" data-test-id="created-ad" showTime />
          <TextField source="label" data-test-id="label" />
          <TextField source="contact" data-test-id="contact" />
          <TextField source="api_version" data-test-id="api-version" />
          {isAdmin && <BooleanField source="is_blocked" label="Blocked" />}
          {isAdmin && (
            <FunctionField
              label=""
              data-test-id="field-reason"
              render={(record: RaRecord) => {
                return (
                  <Button
                    startIcon={record?.is_blocked ? <LockOpenIcon /> : <BlockIcon />}
                    onClick={() => {
                      setRecordId(record?.id);
                      setIsBlocked(record?.is_blocked);
                      setCOpen(true);
                    }}
                    label={record?.is_blocked ? 'Unblock' : 'Block'}
                  />
                );
              }}
            />
          )}

          <ShowButton data-test-id="show-btn" />
        </Datagrid>
      </List>
    </>
  ) : (
    <LoadingPage />
  );
};

export default ListPage;
