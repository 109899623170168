import {
  Edit,
  TextInput,
  SimpleForm,
  DateTimeInput,
  SelectInput,
  regex,
  Confirm,
  useRedirect,
  useRefresh,
  useUpdate,
  useRecordContext,
  useNotify,
  usePermissions,
  FormDataConsumer,
} from 'react-admin';
import { Box, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import mbaas from '../../provider/Mbaas';
import Swal from 'sweetalert2';
import SelectAccount from './SelectAccount';

const validateWhitelistIPs = regex(
  /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:;\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})*$/,
  'Delimited by semicolon or format IPs is wrong'
);

const Title = () => {
  const record = useRecordContext();
  return <span data-test-id="edit-title">&rsaquo; Edit {record ? record?.id : ''}</span>;
};

export const EditPage = ({ onCancel, match, ...props }: any) => {
  const { isLoading, permissions } = usePermissions();
  const [update] = useUpdate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [formData, setFormData] = useState<any>(null);
  const [prevData, setPrevData] = useState<any>(null);

  const isStaff = !isLoading && permissions.find((el: string) => el === 'Staff');

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (data === 'OK') {
        update(
          'users',
          { id: formData?.id, data: formData, previousData: prevData },
          {
            onSuccess: () => {
              refresh();
              redirect('/users');
            },
            onError(error, variables, context) {
              notify('An error has occurred. Please try again later', {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);
        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const onSubmitHandler = (data: any) => {
    setCOpen(true);
    setFormData(data);
  };

  const HiddenInput = () => {
    const record = useRecordContext();
    setPrevData(record);
    return <></>;
  };

  return (
    <Edit title={<Title />} {...props}>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Box pt={5} width={{ xs: '100vW', sm: 700 }} mt={{ xs: 2, sm: 1 }}>
        <SimpleForm onSubmit={onSubmitHandler}>
          <HiddenInput />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                source="id"
                data-test-id="input-id"
                label="Record ID"
                isRequired
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                source="created_at"
                fullWidth
                data-test-id="input-created-at"
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                source="email"
                data-test-id="input-email"
                isRequired
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                source="name"
                fullWidth
                data-test-id="input-name"
                defaultValue={''}
                disabled={isStaff && true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextInput
                source="address"
                data-test-id="input-address"
                isRequired
                fullWidth
                disabled={isStaff && true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                source="phone"
                data-test-id="input-phone"
                fullWidth
                defaultValue={''}
                disabled={isStaff && true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SelectInput
                source="roles"
                data-test-id="select-roles"
                fullWidth
                isRequired
                choices={[
                  { id: 'Administrator', name: 'Administrator' },
                  { id: 'Admin Stock-In', name: 'Admin Stock-In' },
                  { id: 'Admin Stock-Out', name: 'Admin Stock-Out' },
                  { id: 'Staff', name: 'Staff' },
                  { id: 'Partner', name: 'Partner' },
                  { id: 'Partner View', name: 'Partner View' },
                  { id: 'Partner Top-Up', name: 'Partner Top Up' },
                  { id: 'Supplier', name: 'Supplier' },
                ]}
                disabled={isStaff && true}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                source="status"
                data-test-id="select-status"
                fullWidth
                isRequired
                choices={[
                  { id: true, name: 'Enable' },
                  { id: false, name: 'Disable' },
                ]}
                disabled={isStaff && true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  console.log(472398423, formData);
                  return formData?.roles.includes('Partner View') ||
                    formData?.roles.includes('Partner Top-Up') ? (
                    <SelectAccount source="account_id_data" />
                  ) : (
                    <></>
                  );
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  );
};
