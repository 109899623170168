import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useAuthenticated } from 'ra-core';

export const Empty = (props: any) => {
  const { className, title, ...rest } = props;

  useAuthenticated();
  return (
    <Root className={className} {...sanitizeRestProps(rest)}>
      <Grid
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }}
        alignItems="center"
        justifyItems="center"
      >
        <Box>
          <div className={EmptyClasses.message}>
            <ScreenSearchDesktopIcon className={EmptyClasses.icon} />
            <h1>Search a Transaction</h1>
            <div>by filling one of the search field above</div>
          </div>
        </Box>
      </Grid>
    </Root>
  );
};

const sanitizeRestProps = ({ staticContext, history, location, match, ...rest }: any) => rest;

Empty.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.object,
};

const PREFIX = 'RaEmpty';

export const EmptyClasses = {
  icon: `${PREFIX}-icon`,
  message: `${PREFIX}-message`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    marginTop: '-3em',
  },

  [`& .${EmptyClasses.icon}`]: {
    width: '7em',
    height: '7em',
    [theme.breakpoints.up('md')]: {
      marginTop: '150px',
    },
  },

  [`& .${EmptyClasses.message}`]: {
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    opacity: 0.5,
    margin: '0 1em',
  },

  [`& .${EmptyClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));
