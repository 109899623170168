// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Show,
  FunctionField,
  WithRecord,
  Pagination,
  Toolbar,
  SaveButton,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  Button,
  useRecordContext,
  useNotify,
  useRedirect,
  required,
  useRefresh,
  Error,
} from "react-admin";
import ReactJson from "react-json-view";
import moment from "moment";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import JSONInput from "../JSONInput";
import { makeStyles } from "@mui/styles";
import { RefreshOutlined } from "@mui/icons-material";
import mbaas from "../../provider/Mbaas";
import humanizeDuration from "humanize-duration";
import ResultDialog from "./ResultDialog";

const useStyles = makeStyles({
  detailsBox: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
  },
  topGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
    paddingBottom: "20px",
    paddingTop: "40px !important",
  },
  bottomGrid: {
    paddingBottom: "20px",
  },
  histExpTopGrid: {
    borderBottom: "solid thin rgba(0, 0, 0, 0.12)",
  },
  histExpDetailsBox: {
    paddingBottom: "5px",
    paddingTop: "5px !important",
  },
});

const TransactionsHistories = ({
  histories,
  id,
  isStaff,
  isAdmin,
  orderId,
  isPartner,
  isPartnerView,
}) => {
  const refresh = useRefresh();
  const [page, setPage] = useState(1);
  const [ngrsCheckStatus, setNgrsCheckStatus] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const perPage = 10;
  const total = histories?.length;
  const shownHistories = histories
    ?.sort((a, b) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .filter(
      (x: any, index) =>
        x && index >= (page - 1) * perPage && index < page * perPage
    );
  console.log({ shownHistories, offset: (page - 1) * perPage });

  const newShownHistories = shownHistories?.map((item) => {
    if (isPartner || isPartnerView) {
      if (item.status !== "QUOTE") {
        delete item?.data?.transaction?.channel;
      }
    }

    return item;
  });

  const syncTrx = async () => {
    const { error } = await mbaas.client.invokeApi("sync-transaction", {
      transaction_id: id,
    });

    if (!error) {
      refresh();
    }
  };

  const CheckNgrsStatus = async () => {
    try {
      const { error, data } = await mbaas.client.invokeApi(
        "tsel-check-status",
        {
          order_id: orderId,
        }
      );

      if (data) {
        setNgrsCheckStatus(data);
        setOpenDialog(true);
      }

      if (error) {
        console.log(error);
      }
    } catch (error) {
      throw new Error("Error: ", error);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "7px",
        }}
      >
        {(isAdmin || isStaff) && (
          <Button
            variant="contained"
            size="large"
            style={{ color: "#fff" }}
            startIcon={<RefreshOutlined />}
            label="Check NGRS Status"
            onClick={async () => {
              await CheckNgrsStatus();
            }}
          />
        )}

        <Button
          variant="contained"
          size="large"
          style={{ color: "#fff" }}
          startIcon={<RefreshOutlined />}
          label="Sync History"
          onClick={async () => {
            await syncTrx();
          }}
        />
      </Grid>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell width={60}>Remark</TableCell>
            <TableCell>Attempts</TableCell>
            <TableCell>Data</TableCell>
            {isAdmin && <TableCell>Override By</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {newShownHistories?.map((data) => (
            <TableRow key={data?.id}>
              <TableCell>
                {moment(data?.created_at).format("DD/MM/YYYY, hh:mm:ss.SSS A")}
              </TableCell>
              <TableCell>{data?.status.toUpperCase()}</TableCell>
              <TableCell width={60}>{data?.remark}</TableCell>
              <TableCell>{data?.attempts}</TableCell>
              <TableCell>
                <ReactJson
                  src={
                    isAdmin || isPartner || isPartnerView
                      ? data?.status === "CALLBACK"
                        ? data?.dataOg
                        : data?.data || {}
                      : data?.data?.transaction_status || {}
                  }
                  collapsed
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  name={false}
                />
              </TableCell>
              {isAdmin && (
                <TableCell>{data?.overriden_by_data?.name}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
        rowsPerPageOptions={[2]}
      />

      <ResultDialog
        open={openDialog}
        handleClose={handleClose}
        dialogContent={ngrsCheckStatus}
      />
    </>
  );
};

export const TransactionDetail = ({ onCancel, match, ...props }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const CustomToolbar = ({ onCancel }) => (
    <Toolbar>
      <Grid item xs={12}>
        <SaveButton data-test-id="Override" label="Override" />
        <Button
          variant="contained"
          color="error"
          style={{
            minWidth: "64px",
            padding: "6px 16px",
            borderRadius: "4px",
            lineHeight: "1.75",
            marginLeft: "14px",
          }}
          label="ra.action.cancel"
          onClick={onCancel}
        >
          <CloseIcon />
        </Button>
      </Grid>
    </Toolbar>
  );

  const Override = ({ onCancel }) => {
    const record = useRecordContext();

    const notify = useNotify();
    const redirect = useRedirect();
    const classes = useStyles();

    const onSuccess = () => {
      redirect("/transactions");
      notify("Transaction Has Been Overridden");
    };

    return (
      <Create
        resource="override"
        mutationOptions={{
          onSuccess: onSuccess,
        }}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />}>
          <div className={classes.root}>
            <Grid container maxWidth={600}>
              <Grid item xs={12}>
                <TextInput
                  source="id"
                  fullWidth
                  validate={required()}
                  defaultValue={record.id}
                  disabled
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  data-test-id="status"
                  source="status"
                  fullWidth
                  validate={required()}
                  choices={
                    record?.status === "SUCCESS"
                      ? [
                          { id: "FAILED_SILENT", name: "FAILED_SILENT" },
                          { id: "FAILED", name: "FAILED" },
                        ]
                      : record?.status === "FAILED"
                      ? [
                          { id: "SUCCESS_SILENT", name: "SUCCESS_SILENT" },
                          { id: "SUCCESS", name: "SUCCESS" },
                        ]
                      : [
                          { id: "FAILED_SILENT", name: "FAILED_SILENT" },
                          { id: "FAILED", name: "FAILED" },
                          { id: "SUCCESS_SILENT", name: "SUCCESS_SILENT" },
                          { id: "SUCCESS", name: "SUCCESS" },
                          {
                            id: "QUOTE_EXECUTE_ONLY",
                            name: "QUOTE_EXECUTE_ONLY",
                          },
                        ]
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <JSONInput
                  data-test-id="additional_data"
                  source="additional_data"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="remark"
                  source="reason"
                  validate={required()}
                  fullWidth
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  data-test-id="totp"
                  source="totp"
                  validate={required()}
                  fullWidth
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleForm>
      </Create>
    );
  };

  return (
    <Show resource="transactions" title=" &rsaquo; Detail" {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: "auto" }}
        style={{ paddingBlockStart: 0 }}
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid maxWidth={600} container spacing={3}>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Reference ID</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="reference_id" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Serial Number</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="serial_number" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Account Name</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="account_id_data.label" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Status</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record.status.toUpperCase();
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Product</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="product_snapshot.label" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Price</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="price" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Target</strong>
              </Grid>
              <Grid item xs={8}>
                <TextField source="target" />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              {!props?.isPartnerView && (
                <>
                  <Grid item xs={3}>
                    <strong>Duration</strong>
                  </Grid>
                  <Grid item xs={8}>
                    {/* <TextField source="duration"/> */}
                    <FunctionField
                      render={(record) => {
                        return record.duration !== 0
                          ? humanizeDuration(record.duration, {
                              delimiter: " ",
                            })
                          : "-";
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    &nbsp;
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                <strong>Product Snapshot</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return (
                      <ReactJson
                        src={record.product_snapshot || {}}
                        shouldCollapse={() => {}}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name={false}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Store ID</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record?.metadata?.store_id ?? "-";
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>
              <Grid item xs={3}>
                <strong>Delivery Channel</strong>
              </Grid>
              <Grid item xs={8}>
                <FunctionField
                  render={(record) => {
                    return record?.metadata?.delivery_channel ?? "-";
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                &nbsp;
              </Grid>

              <Grid item xs={1}>
                &nbsp;
              </Grid>
            </Grid>
          </Tab>
          {!props?.isPartnerView && (
            <Tab label="histories">
              <WithRecord
                render={(record) => {
                  console.log(655324, "props", props);
                  return (
                    <TransactionsHistories
                      isAdmin={props?.isAdmin}
                      isPartner={props?.isPartner}
                      isPartnerView={props?.isPartnerView}
                      orderId={record.order_id}
                      id={record.id}
                      histories={
                        record.transaction_histories_transaction_id_list?.data
                      }
                    />
                  );
                }}
              />
            </Tab>
          )}
          {props?.isAdmin && (
            <Tab data-test-id="action" label="action">
              <Override onCancel={onCancel} />
            </Tab>
          )}
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
