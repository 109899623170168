// @ts-nocheck

import { Drawer, Paper, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import {
  List,
  Datagrid,
  Button,
  TextField,
  DateField,
  ChipField,
  BooleanField,
  FilterButton,
  TopToolbar,
  useListContext,
  CreateButton,
  TextInput,
  SelectInput,
  Confirm,
  useCreate,
  usePermissions,
  FilterForm,
} from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { EditPage } from './EditPage';
import { CreatePage } from './CreatePage';
import mbaas from '../../provider/Mbaas';
import GetAppIcon from '@mui/icons-material/GetApp';
import { endOfToday, format } from 'date-fns';
import PartnerGeneratorDialog from './PartnerGeneratorDialog';
import { ManageAccounts as AccountIcon } from '@mui/icons-material';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import { pageBadgeIcon } from 'src/styles/global-styles';

const listFilters = [
  <TextInput label="Name" source="search_by_name" key="filter_name" />,
  <TextInput label="Email" source="search_by_email" key="filter_email" />,
  <SelectInput
    key="filter_role"
    label="Role"
    source="search_by_roles"
    optionText="label"
    choices={[
      { id: 'Administrator', label: 'Administrator' },
      { id: 'Staff', label: 'Staff' },
      { id: 'Partner', label: 'Partner' },
      { id: 'Partner View', name: 'Partner View' },
      { id: 'Partner Top-Up', name: 'Partner Top Up' },
      { id: 'Admin Stock-In', label: 'Admin Stock-In' },
      { id: 'Admin Stock-Out', label: 'Admin Stock-Out' },
    ]}
  />,
];

const ConfirmDialogContent = ({ genFileName }) => {
  return (
    <Paper elevation={0}>
      <Typography variant="body1" gutterBottom>
        Your file is exported as "{genFileName}.csv", to view the progress please visit report page
        below
      </Typography>
    </Paper>
  );
};

const ExportPurchaseLogButton = ({ open, setOpen, userData }) => {
  const { filterValues } = useListContext();
  //
  const type_name = 'users';
  const gen_file_name = `${type_name}_${format(
    new Date(endOfToday()),
    'yyyy-MM-dd'
  )}_${new Date().getTime()}`;
  //
  console.log(737, 'filterValues', filterValues);
  //
  const [exportData, { data, error }] = useCreate('reports', {
    data: {
      account_id: userData?.account_id,
      author_id: userData?.id,
      type: type_name,
      status: 'QUEUED',
      data: {
        filter: {
          ...filterValues,
        },
        metadata: {
          generated_filename: gen_file_name, // jika ada
        },
      },
    },
  });

  if (data && !error) {
    //
    console.log(767, 'ERROR');
  }

  function handleOnClick() {
    exportData();
    setOpen(true);
  }

  const navigateToReportPage = () => {
    navigate(encodeURI(`/reports`));
  };

  return (
    <Button label="Export" onClick={handleOnClick}>
      <GetAppIcon />
      <Confirm
        isOpen={open}
        title="Dear User,"
        content={<ConfirmDialogContent genFileName={gen_file_name} />}
        onClose={() => setOpen(false)}
        confirm="Go To Report"
        onConfirm={navigateToReportPage}
      />
    </Button>
  );
};

const ListActions = () => {
  const { isLoading, permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState(null);

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      console.log(res.data, 1234);

      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  React.useEffect(() => {
    getUserData();
  }, []);
  return (
    <TopToolbar>
      <FilterButton filters={listFilters} />
      {isAdmin && <CreateButton />}
      <ExportPurchaseLogButton open={open} setOpen={setOpen} userData={userData} />
      <PartnerGeneratorDialog />
    </TopToolbar>
  );
};

const ListPage = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const matchCreate = matchPath('/users/create', location.pathname);
  const matchEdit = matchPath('/users/:id/show/*', location.pathname);

  console.log('CREATEE', matchCreate);
  console.log(location.pathname);

  const handleClose = useCallback(() => {
    console.log('handleClose', { cancel: navigate });
    navigate('/users');
  }, [navigate]);

  console.log('prroop', props);
  return (
    <>
      <List
        perPage={50}
        sort={{ field: 'created_at', order: 'asc' }}
        debounce={1200}
        exporter={false}
        sx={{
          width: '1000px',
        }}
      >
        <CustomTopToolbar pageBadge={<AccountIcon style={pageBadgeIcon} />}>
          <FilterForm filters={listFilters} />
          <ListActions />
        </CustomTopToolbar>
        <Datagrid
          bulkActionButtons={false}
          // @ts-ignore
          rowClick={(id) => {
            navigate(`/users/${id}/show`);
          }}
        >
          <DateField source="created_at" label="Date" showTime />
          <TextField source="email" />
          <TextField source="name" />
          <ChipField source="roles" sortable={false} />
          <BooleanField source="status" label="Enable" sortable={false} />
        </Datagrid>
      </List>
      <Drawer
        variant="temporary"
        open={!!matchCreate || (!!matchEdit && matchEdit?.params?.id !== 'create')}
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== 'create' && (
          <EditPage id={(matchEdit as any).params.id} onCancel={handleClose} {...props} />
        )}
        {!!matchCreate && <CreatePage onCancel={handleClose} />}
      </Drawer>
    </>
  );
};

export default ListPage;
