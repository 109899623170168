// @ts-nocheck
import React, { Fragment } from 'react';
import { Loading, useAuthenticated, useGetIdentity } from 'react-admin';
import Mbaas from '../../provider/Mbaas';
import DefaultDashboard from './DefaultDashboard';
import InternalDashboard from './InternalDashboard';
import Paper from '@mui/material/Paper';
import { Dashboard as DashboardIcon } from '@mui/icons-material';
import { pageBadgeIcon } from 'src/styles/global-styles';
import CssBaseline from '@mui/material/CssBaseline';

export default () => {
  useAuthenticated(); // redirects to login if not authenticated
  if (Mbaas.isProduction) {
    const { identity, isLoading: identityLoading } = useGetIdentity();
    if (identityLoading) {
      return <Loading />;
    }
    const isInternal = ['Administrator'].filter((x) => identity.roles.includes(x)).length > 0;

    return (
      <div>
        <CssBaseline />
        <Paper sx={{ marginTop: '64px', height: '89vh' }}>
          <Paper
            sx={{
              height: '75px',
              width: '75px',
              backgroundColor: '#02a499',
              position: 'relative',
              top: '-31px',
              left: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ zIndex: '2' }}
            elevation={5}
          >
            <DashboardIcon style={pageBadgeIcon} />
          </Paper>
          <Fragment>{isInternal ? <InternalDashboard /> : <DefaultDashboard />}</Fragment>
        </Paper>
      </div>
    );
  } else return;
};
