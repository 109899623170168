import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
import { differenceWith, toPairs, isEqual, fromPairs } from 'lodash';
export class AccountsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    console.log(31243123, 'params', params);
    console.log('GET Accounts');
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    console.log('GET ACCOUNTS PARAMS', params);

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    if (params.filter.partner) {
      filterArray.push({
        id: {
          _eq: `${params.filter.partner}`,
        },
      });
    }

    if (params.filter.parentId) {
      filterArray.push({
        parent_id: {
          _eq: `${params.filter.parentId}`,
        },
      });
    }

    if (params.filter.isAdminStockIn) {
      filterArray.push({
        id: {
          _eq: `${params.filter.isAdminStockIn}`,
        },
      });
    }

    if (params.filter.isAdminStockOut) {
      filterArray.push({
        id: {
          _neq: `${params.filter.isAdminStockOut}`,
        },
        parent_id: {
          _is_null: true,
        },
      });
    }

    if (params.filter.isPartnerStock) {
      filterArray.push({
        id: {
          _neq: `${params.filter.isPartnerStock}`,
        },
      });
    }

    if (params.filter.q) {
      filterArray.push({
        label: {
          _ilike: `%${params.filter.q}%`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }
    const { allAccountsList, error } = await this.client.gql.query({
      query: `
      query accountsList($where: AccountsWhereInput) {
        allAccountsList(where: $where,${args}) {
          data {
            id
            created_at
            label
            contact
            is_blocked
            api_version
            api_password
            whitelist_ips
            public_key
            private_key_signature
            users_account_id_list{
              data{
                name
                email
                roles
              }
            }
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    console.log({ allAccountsList, error });
    if (error) {
      console.warn(error);
      throw new Error('GraphQL query error');
    }
    console.log(31243123, 'allAccountsList', allAccountsList);

    return {
      data: allAccountsList.data,
      total: allAccountsList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log(883123123, 'params', params);
    const { getAccountsById } = await this.client.gql.query({
      query: `
      query anAccount($id:UUID) {
        getAccountsById(id:$id) {
          id
          created_at
          label
          contact
          api_version
          api_password
          public_key
          private_key_signature
          webhook_configuration
          whitelist_ips
          parent_id_data{
            id
            label
          }
          accounts_parent_id_list{
            data{
              id
              created_at
              updated_at
              contact
              public_key
              private_key_signature
              api_version
              api_password
              webhook_configuration
              whitelist_ips
              label
            }
          }
          users_account_id_list{
            data{
              id
              name
              roles
              email
            }
          }
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    // const data = [
    //   {
    //     id: "0",
    //     name: "Novitasari",
    //     email: "novita@gmail.com",
    //     roles: "Admin",
    //     address: "Surabaya",
    //     phone: "08123456",
    //     status: "Disable",
    //     api_password: "asfsadfwre",
    //     whitelist_ips: "191.12.12.12",
    //     created_at: new Date().toLocaleString(),
    //   },
    //   {
    //     id: "1",
    //     name: "Angelina",
    //     email: "angelina@gmail.com",
    //     roles: "Staff",
    //     address: "Malang",
    //     phone: "08123456",
    //     status: "Enable",
    //     api_password: "asfsadfwre",
    //     whitelist_ips: "191.12.12.12",
    //     created_at: new Date().toLocaleString(),
    //   },
    // ];

    return {
      data: {
        ...getAccountsById,
        api_password: window.atob(getAccountsById?.api_password || ''),
      },
    };
    // return {
    //   data: data.filter((dt) => {
    //     return dt.id === params.id;
    //   })[0],
    // };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log(73124343, 'params', params);
    let data = {};

    function createApiPass(length: number) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    const api_pass = createApiPass(16);
    const base64Api_pass = window.btoa(api_pass);
    console.log(base64Api_pass);

    data = await this.client.getTable(resource).insert({
      parent_id: params.data.parentId ? params.data.parentId.id : null,
      label: params.data.label,
      contact: params.data.contact,
      api_version: params.data.api_version,
      api_password: base64Api_pass,
    });
    // update user data
    const { data: userData } = await this.client.user.get();
    // @ts-ignore
    console.log({ data: data.data.id, userData });
    if (userData && data) {
      const isAdmin = userData.roles.includes('Administrator');
      const user_id = isAdmin ? params.data.id : userData.id;
      if (user_id) {
        await this.client.getTable('users').update({
          // @ts-ignore
          id: user_id,
          // @ts-ignore
          data: { account_id: data.data?.id },
        });
      }
    }
    return data as CreateResult;
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    console.log(46327923894, { resource, params, method: 'update' });

    let updatedData = {
      label: params.data.label,
      parent_id: params.data.parent_id_data ? params.data.parent_id_data.id : null,
      contact: params.data.contact,
      api_version: params.data.api_version,
      webhook_configuration: params.data.webhook_configuration,
      whitelist_ips:
        typeof params.data?.whitelist_ips === 'string'
          ? params.data?.whitelist_ips
          : params.data?.whitelist_ips?.join() || 'ALL',
    };

    let previousData = {
      label: params.previousData.label,
      parent_id: params.previousData.parent_id_data ? params.previousData.parent_id_data.id : null,
      contact: params.previousData.contact,
      api_version: params.previousData.api_version,
      webhook_configuration: params.previousData.webhook_configuration,
      whitelist_ips:
        typeof params.previousData?.whitelist_ips === 'string'
          ? params.previousData?.whitelist_ips
          : params.previousData?.whitelist_ips?.join() || 'ALL',
    };

    const changes = differenceWith(toPairs(updatedData), toPairs(previousData), isEqual);

    const changesButObject = fromPairs(changes);

    const { data } = await this.client.getTable('accounts').update(
      Object.assign({
        id: params.id,
        data: {
          ...changesButObject,
        },
      })
    );
    console.log(46327923894, 'data-updateResult', data);
    return { data: data } as UpdateResult;
  }
}
