import { MultiProviders } from './MultiProviders';
import { UsersProviderClass } from './users';
import { UsersWithoutAccountProviderClass } from './users_without_account';
import { PartnersProviderClass } from './partners';
import { TransactionsProviderClass } from './transactions';
import { StatementsProviderClass } from './statements';
import { StockTransfersProviderClass } from './stock-transfers';
import { StockManagementsProviderClass } from './stock-managements';
import MbaaS from './Mbaas';
import { OverrideProviderClass } from './override';
import { AccountsProviderClass } from './accounts';
import { ReportsProviderClass } from './reports';
import { SupplierCatalogProviderClass } from './supplier-catalog';
import { SuppliersProviderClass } from './suppliers';
import { BalanceProviderClass } from './balance';
import { AccountsKeyUpdateProviderClass } from './accounts_key_update';
import { ProductCatalogsProviderClass } from './product-catalogs';
import { ProductPricingsProviderClass } from './product-pricings';
import { ProductRoutingsProviderClass } from './product-routings';
import { DisputeProviderClass } from './dispute';
import { OverrideDisputeProviderClass } from './overrideDispute';
import { CheckTransactionsProviderClass } from './check-transactions';
import { DashboardProviderClass } from './dashboard';
import { AccountsApiPasswordUpdateProviderClass } from './accounts_api_password_update';
import { ConfigurationPageProviderClass } from './configuration-page';
export { Auth as authProvider } from './auth';

/* -------------------------------------------------------------------------- */
/*                         Data Provider (Aggregator)                         */
/* -------------------------------------------------------------------------- */
const aggregator = new MultiProviders();
/* ------------------------- Register each provider ------------------------- */
aggregator.register('users', new UsersProviderClass(MbaaS.client));
aggregator.register('users_without_account', new UsersWithoutAccountProviderClass(MbaaS.client));
aggregator.register('transactions', new TransactionsProviderClass(MbaaS.client));
aggregator.register('partners', new PartnersProviderClass(MbaaS.client));
aggregator.register('accounts_key_update', new AccountsKeyUpdateProviderClass(MbaaS.client));
aggregator.register(
  'accounts_api_password_update',
  new AccountsApiPasswordUpdateProviderClass(MbaaS.client)
);
aggregator.register('override', new OverrideProviderClass(MbaaS.client));
aggregator.register('statements', new StatementsProviderClass(MbaaS.client));
aggregator.register('accounts', new AccountsProviderClass(MbaaS.client));
aggregator.register('reports', new ReportsProviderClass(MbaaS.client));
aggregator.register('product-catalogs', new ProductCatalogsProviderClass(MbaaS.client));
aggregator.register('product-pricings', new ProductPricingsProviderClass(MbaaS.client));
aggregator.register('product-routings', new ProductRoutingsProviderClass(MbaaS.client));
aggregator.register('dashboard', new DashboardProviderClass(MbaaS.client));
aggregator.register('stock-transfers', new StockTransfersProviderClass(MbaaS.client));
aggregator.register('stock-managements', new StockManagementsProviderClass(MbaaS.client));
aggregator.register('supplier-catalog', new SupplierCatalogProviderClass(MbaaS.client));
aggregator.register('suppliers', new SuppliersProviderClass(MbaaS.client));
aggregator.register('balance', new BalanceProviderClass(MbaaS.client));
aggregator.register('dispute', new DisputeProviderClass(MbaaS.client));
aggregator.register('overrideDispute', new OverrideDisputeProviderClass(MbaaS.client));
aggregator.register('check-transactions', new CheckTransactionsProviderClass(MbaaS.client));
aggregator.register('configuration-page', new ConfigurationPageProviderClass(MbaaS.client));

export const dataProvider = aggregator.serve();
