import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
export class AccountsKeyUpdateProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log("GET Accounts");
    const where: Record<string, any> = {};
    const filterArray: any[] = [];

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    if (params.filter.partner) {
      filterArray.push({
        id: {
          _eq: `${params.filter.partner}`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }
    const { allAccountsList, error } = await this.client.gql.query({
      query: `
      query accountsList($where: AccountsWhereInput) {
        allAccountsList(where: $where,${args}) {
          data {
            id
            created_at
            label
            contact
            api_version
            public_key
            private_key_signature
            users_account_id_list{
              data{
                name
                email
              }
            }
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    console.log({ allAccountsList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    console.log(allAccountsList);

    return {
      data: allAccountsList.data,
      total: allAccountsList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const { getAccountsById } = await this.client.gql.query({
      query: `
      query anAccount($id:UUID) {
        getAccountsById(id:$id) {
          id
          created_at
          label
          contact
          api_version
          public_key
          private_key_signature
          users_account_id_list{
            data{
              name
              email
            }
          }
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    // const data = [
    //   {
    //     id: "0",
    //     name: "Novitasari",
    //     email: "novita@gmail.com",
    //     roles: "Admin",
    //     address: "Surabaya",
    //     phone: "08123456",
    //     status: "Disable",
    //     api_password: "asfsadfwre",
    //     whitelist_ips: "191.12.12.12",
    //     created_at: new Date().toLocaleString(),
    //   },
    //   {
    //     id: "1",
    //     name: "Angelina",
    //     email: "angelina@gmail.com",
    //     roles: "Staff",
    //     address: "Malang",
    //     phone: "08123456",
    //     status: "Enable",
    //     api_password: "asfsadfwre",
    //     whitelist_ips: "191.12.12.12",
    //     created_at: new Date().toLocaleString(),
    //   },
    // ];

    return { data: getAccountsById };
    // return {
    //   data: data.filter((dt) => {
    //     return dt.id === params.id;
    //   })[0],
    // };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    let data = {};

    console.log(params, "COCOBOH");

    data = await this.client.getTable(resource).insert({
      label: params.data.label,
      contact: params.data.contact,
      api_version: params.data.api_version,
    });
    // update user data
    const { data: userData } = await this.client.user.get();
    // @ts-ignore
    console.log({ data: data.data.id, userData });
    if (userData && data) {
      const isAdmin = userData.roles.includes("Administrator");
      const user_id = isAdmin ? params.data.id : userData.id;
      if (user_id) {
        await this.client.getTable("users").update({
          // @ts-ignore
          id: user_id,
          // @ts-ignore
          data: { account_id: data.data?.id },
        });
      }
    }
    return data as CreateResult;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log("ALOHA123", resource);
    let updatedData = params.data;

    const { data } = await this.client.getTable("accounts").update(
      Object.assign({
        id: params.id,
        data: {
          private_key_signature: updatedData.private_key_signature,
          public_key: updatedData.public_key,
        },
      })
    );
    return { data: data } as UpdateResult;
  }
}
