import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import moment from "moment";
import { buildArgs } from "./utils";
export class StatementsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log(661244, {
      resource: resource,
      params: params,
      method: "getlist",
    });
    let dateGTE = moment().startOf("month").startOf("day").toISOString();
    let dateLTE = moment().endOf("month").endOf("day").toISOString();

    const filterArray: any[] = [];
    const where: Record<string, any> = {};

    if (params.filter.search_by_label) {
      filterArray.push({
        deposit_type_id: {
          _eq: `${params.filter.search_by_label}`,
        },
      });
    }

    if (params.filter.search_by_reference_id) {
      filterArray.push({
        transaction_entry_id_data: {
          reference_id: { _eq: `${params.filter.search_by_reference_id}` },
        },
      });
    }

    if (params.filter.filterCredit === false) {
      filterArray.push({
        credit: {
          _eq: 0,
        },
      });
    }
    if (params.filter.filterDebit === false) {
      filterArray.push({
        debit: {
          _eq: 0,
        },
      });
    }

    if (params.filter.partner) {
      filterArray.push({
        account_id: {
          _eq: `${params.filter.partner}`,
        },
      });
    }

    if (params.filter.account_id) {
      filterArray.push({
        account_id: {
          _eq: `${params.filter.account_id}`,
        },
      });
    }

    if (params.filter.admin_stock) {
      filterArray.push({
        transaction_id: {
          _is_null: true,
        },
      });
    }

    if (params.filter.dateGte || params.filter.dateLte) {
      dateLTE = moment(params.filter.dateLte).endOf("day").toISOString();
      dateGTE = moment(params.filter.dateGte).startOf("day").toISOString();
      filterArray.push({
        created_at: {
          _gte: dateGTE,
        },
      });
      filterArray.push({
        created_at: {
          _lte: dateLTE,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });

    if (params.filter.admin_stock) {
      const { allAccountLedgersList, error } = await this.client.gql.query({
        query: `
      query statementList($where: AccountLedgersWhereInput) {
        allAccountLedgersList(where: $where, ${args}) {
          data {
            id
            created_at
            updated_at
            account_id
            credit
            debit
            balance
            remark
            transaction_id
            deposit_id
            deposit_type_id
            deposit_id_data{
              deposit_reference_id
            }
            deposit_type_id_data {
              id
              label
            }
            account_id_data {
              id
              created_at
              label
            }
          }   
          count
      }
    }
      `,
        variables: {
          where,
        },
      });
      console.log("ssstttattee", { allAccountLedgersList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }

      const mapAccountLedgersList = allAccountLedgersList.data.map(
        (dt: any) => {
          return {
            id: dt.id,
            created_at: dt.created_at,
            updated_at: dt.updated_at,
            account_id: dt.account_id,
            credit: dt.credit,
            debit: dt.debit,
            balance: dt.balance,
            remark: dt.remark,
            deposit_reference_id: dt.deposit_id_data.deposit_reference_id,
            label: dt.deposit_type_id_data.label,
            deposit_type_id: dt.deposit_type_id,
            name: dt.account_id_data.label,
          };
        }
      );
      console.log("mapAccountLedgersList", mapAccountLedgersList);

      return {
        data: mapAccountLedgersList,
        total: allAccountLedgersList.count,
      };
    } else {
      const { allAccountLedgersList, error } = await this.client.gql.query({
        query: `
        query statementList($where: AccountLedgersWhereInput) {
          allAccountLedgersList(where: $where, ${args}) {
            data {
              id
              created_at
              updated_at
              account_id
              credit
              debit
              balance
              remark
              transaction_id
              transaction_entry_id_data {
                id
                reference_id
                created_at
                order_id
                target
                product_item_id_data {
                  label
                }
              }
              deposit_id
              deposit_id_data{
                deposit_reference_id
              }
              deposit_type_id
              deposit_type_id_data {
                id
                label
              }
              account_id_data {
                id
                created_at
                label
              }
            }   
            count
        }
      }
        `,
        variables: {
          where,
        },
      });
      console.log("ssstttattee", { allAccountLedgersList, error });
      if (error) {
        console.warn(error);
        throw new Error("GraphQL query error");
      }
      const mapAccountLedgersList = allAccountLedgersList.data.map(
        (dt: any) => {
          return {
            id: dt.id,
            created_at: dt.created_at,
            updated_at: dt.updated_at,
            account_id: dt.account_id,
            credit: dt.credit,
            debit: dt.debit,
            balance: dt.balance,
            remark: dt.remark,
            target:
              dt.transaction_id === null
                ? "-"
                : dt.transaction_entry_id_data.target,
            deposit_reference_id:
              dt.deposit_id_data === null
                ? dt.transaction_entry_id_data.order_id
                : dt.deposit_id_data.deposit_reference_id,
            partner_ref_id:
              dt.transaction_id === null
                ? "-"
                : dt.transaction_entry_id_data.reference_id,
            // label:
            //   dt.transaction_id === null
            //     ? dt.deposit_type_id_data.label
            //     : dt.transaction_entry_id_data.product_item_id_data.label,
            label: dt.deposit_type_id_data.label,
            deposit_type_id: dt.deposit_type_id,
            name: dt.account_id_data.label,
          };
        }
      );
      console.log("mapAccountLedgersList", mapAccountLedgersList);

      return {
        data: mapAccountLedgersList,
        total: allAccountLedgersList.count,
      };
    }
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    // const { getStatementsById } = await this.client.gql.query({
    //   query: `
    //   query aStatement($id:UUID) {
    //     getStatementsById(id:$id) {
    //       id
    //       name
    //       email
    //       roles
    //       created_at
    //     }
    //   }
    //   `,
    //   variables: {
    //     id: params.id,
    //   },
    // });

    // return { data: getStatementsById };
    return { data: {} };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    return { data: {} };
  }
}
