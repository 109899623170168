import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
import { buildArgs } from "./utils";
import moment from "moment";
export class DisputeProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    console.log(6513, {
      method: "get-list",
      params: params,
      resource: resource,
    });
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    let dateGTE = moment().startOf("month").startOf("day").toISOString();
    let dateLTE = moment().endOf("month").endOf("day").toISOString();
    if (params.filter.search) {
      where._or = [
        {
          serial_number: {
            _eq: `${params.filter.search}`,
          },
        },
        {
          order_id: {
            _eq: `${params.filter.search}`,
          },
        },
        {
          target: {
            _eq: `${params.filter.search}`,
          },
        },
        {
          reference_id: {
            _eq: `${params.filter.search}`,
          },
        },
      ];
    }

    if (params.filter.isDispute) {
      filterArray.push({
        is_dispute: {
          _eq: params.filter.isDispute,
        },
      });
    }

    if (params.filter.partner) {
      filterArray.push({
        account_id: {
          _eq: `${params.filter.partner}`,
        },
      });
    }

    if (params.filter.dateGte || params.filter.dateLte) {
      dateLTE = moment(params.filter.dateLte).endOf("day").toISOString();
      dateGTE = moment(params.filter.dateGte).startOf("day").toISOString();
    }

    filterArray.push({
      created_at: {
        _gte: dateGTE,
      },
    });
    filterArray.push({
      created_at: {
        _lte: dateLTE,
      },
    });

    if (params.filter.status) {
      filterArray.push({
        status: {
          _eq: `${params.filter.status}`,
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const [
      { allTransactionsList, error },
      { data: accountData },
      { data: productData },
    ] = await Promise.all([
      this.client.gql.query({
        query: `
        query transactionsList($where: TransactionsWhereInput) {
          allTransactionsList(where: $where, ${args}) {
            data {
              id
              product_item_id
              account_id
              reference_id
              order_id
              is_dispute
              serial_number
              created_at
              target
              price
              status
            }
            count
          }
        }
        `,
        variables: {
          where,
        },
      }),
      this.client.getTable("accounts").limit(100).read(),
      this.client.getTable("product_items").limit(150).read(),
    ]);
    const products: { [key: string]: string } = {};
    const accounts: { [key: string]: string } = {};
    accountData.forEach((x) => (accounts[x.id] = x.label));
    productData.forEach((x) => (products[x.id] = x.label));
    if (error) {
      throw new Error("GraphQL query error");
    }

    const mapped = allTransactionsList.data.map((el: any) => {
      return {
        ...el,
        account_label: accounts[el.account_id],
        product_label: products[el.product_item_id],
        supplier_label: "NGRS",
      };
    });
    return {
      data: mapped,
      total: allTransactionsList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    var data = await this.client.user
      .get()
      .then((result) => result.data || null);
    const isAdmin = data?.roles?.includes("Administrator");
    const isAdminDispute = data?.roles?.includes("Admin Dispute");

    const { getTransactionsById } = await this.client.gql.query({
      query: `
      query aTransaction($id:UUID) {
        getTransactionsById(id:$id) {
          id
          account_id_data{
            id
            label
          }
          serial_number
          order_id
          duration
          reference_id
          product_item_id_data{
            ${isAdmin || isAdminDispute ? `id` : ``}
            label
          }
          ${
            isAdmin || isAdminDispute
              ? `
          supply_item_id_data{
            supplier_id_data{
              id
              name
            }
          } 
          `
              : ``
          }
          transaction_histories_transaction_id_list{
            data{
              created_at
              status
              is_dispute
              remark
              data
              overriden_by_data{
                name
                email
              }
            }
          }
          created_at
          target
          price
          status
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    return { data: getTransactionsById };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    return { data: {} };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    await this.client.invokeApi("update-user", {
      token: params.data.token,
      data: {
        email: params.data.email,
        roles:
          typeof params.data.roles === "string"
            ? [params.data.roles]
            : params.data.roles,
        name: params.data.name,
        enabled: !params.data.edit_deactivated,
      },
    });

    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          name
          email
          roles
          created_at
        }
      }
      `,
      variables: {
        id: params.data.id,
      },
    });

    return { data: getUsersById };
  }
}
