// @ts-nocheck
import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Grid from "@mui/material/Grid";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import mbaas from "../provider/Mbaas";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [isError, setIsError] = useState({ email: false, password: false });
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get("email")) {
      setIsError((old) => ({ ...old, email: true }));
    }
    if (!data.get("password")) {
      setIsError((old) => ({ ...old, password: true }));
    }
    login({
      username: data.get("email"),
      password: data.get("password"),
    }).catch(() => notify("Invalid email or password"));
  };

  const google = (e) => {
    e.preventDefault();
    mbaas.client.auth.oauthRedirect(
      "google",
      window.location.origin + window.location.pathname
    );
  };

  const forgotPass = (e) => {
    e.preventDefault();

    mbaas.client.auth.forgotPassword("projectdream068@gmail.com");
  };

  const thisUrl = new URL(window.location.href);
  const googleCode = thisUrl.searchParams.get("code");
  window.history.pushState(null, null, window.location.pathname);
  if (googleCode) {
    mbaas.client.auth
      .login("google", {
        callback: window.location.origin + window.location.pathname,
        code: googleCode,
      })
      .then((res) => {
        console.log(res);
        if (res.error) {
          return notify("Invalid OAuth2 authorization", "warning");
        }
        if (res.data.type === "LoginExisting" && res.data.verified) {
          // history.push("/");
          window.location.assign("/");
        }
      });
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage:
          "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "300px",
          background: "white",
          padding: "8px",
          borderRadius: "5px",
          marginTop: "-150px",
        }}
      >
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar style={{ margin: "auto" }}>
            <LockOpenIcon />
          </Avatar>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={isError.email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                isError.email &&
                  setIsError((old) => ({ ...old, email: false }));
                setEmail(e.target.value);
              }}
              helperText={isError.email && "Required"}
            />
            <TextField
              error={isError.password}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                isError.password &&
                  setIsError((old) => ({ ...old, password: false }));
                setPassword(e.target.value);
              }}
              helperText={isError.password && "Required"}
            />
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: "8px" }}
                disabled={false}
                // disabled={!token}
              >
                Sign In
              </Button>
            </Grid>

            <Grid item style={{ marginTop: 12 }}>
              <Button
                variant="contained"
                fullWidth
                color="error"
                onClick={google}
              >
                Google Sign-In
              </Button>
            </Grid>

            <Grid item style={{ marginTop: 12 }}>
              <Button
                size="small"
                fullWidth
                color="secondary"
                onClick={forgotPass}
              >
                Forgot your password?
              </Button>
            </Grid>
          </Box>
        </Box>
      </div>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LcrSUkeAAAAAFV0UM2LsYGfFKnHGH0mjjcE1CW2"> */}
      <GoogleReCaptchaProvider reCaptchaKey="">
        <GoogleReCaptcha
          onVerify={(v) => {
            if (!token) setToken(v);
          }}
        />
        <YourReCaptchaComponent />
      </GoogleReCaptchaProvider>
      <Notification />
    </div>
  );
}

const YourReCaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    await executeRecaptcha("homepage");
    // Do whatever you want with the token
  }, []);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
};
