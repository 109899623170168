import { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { dataProvider } from '../../provider';
import useDebounce from 'src/hooks/useDebounce';

export default function InputPartner(props: any) {
  const { onChange, onBlur, ...rest } = props;
  const [listPartner, setListPartner] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState<string>('');
  const debouncedValue = useDebounce<string>(filter, 750);

  const {
    field,
    fieldState: { isTouched, error },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    onChange,
    onBlur,
    ...props,
  });

  let thisValue = field?.value;

  if (typeof field?.value === 'string') {
    thisValue = field?.value.split(',');
  }

  useEffect(() => {
    field.onChange(field?.value || []);
  }, [field?.value.toString()]);

  useEffect(() => {
    (async () => {
      try {
        const { data: listPartners, total: totalResult } = await dataProvider.getList('partners', {
          filter: {
            q: filter,
          },
          pagination: { page: currentPage, perPage: 50 },
          sort: {
            field: 'created_at',
            order: 'DESC',
          },
        });
        const partnerRemapped = listPartners?.map((partner) => {
          return {
            id: partner?.id,
            name: partner?.name,
          };
        });

        setListPartner([{ id: 'ALL', name: 'ALL' }, ...partnerRemapped]);
      } catch (error) {
        console.log(83123, error);
      }
    })();
  }, [debouncedValue]);

  const handleOnChange = (event: any, newFilter: any) => {
    // @ts-ignore
    let selectedValues = [];
    if (newFilter.includes('ALL')) {
      selectedValues = ['ALL'];
    } else {
      selectedValues = [...thisValue];
      newFilter.forEach((selectedOption: string) => {
        const option = listPartner.find((partner: any) => partner.name === selectedOption);
        // @ts-ignore
        if (option && !selectedValues.includes(option.id)) {
          selectedValues.push(`${option.id}:${option.name}`);
        }
      });
    }
    field?.onChange(selectedValues);
  };

  const handleIsOptionEqualToValue = (option: any, value: any) => {
    if (Array.isArray(value)) {
      return value.some((selectedValue: string) => selectedValue.startsWith(option));
    }
    return false;
  };

  const getOptionLabel = (option: any) => option;

  const handleChipDelete = (value: string) => {
    const updatedValues = thisValue.filter((selectedValue: string) => selectedValue !== value);
    field?.onChange(updatedValues);
  };

  const renderTags = (value: any, getTagProps: any) =>
    value.map((option: any, index: number) => (
      <Chip
        key={option}
        label={getOptionLabel(option)}
        {...getTagProps({ index })}
        onDelete={() => handleChipDelete(option)}
      />
    ));

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={listPartner.map((dat: any) => dat?.name)}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      renderTags={renderTags}
      value={thisValue || []}
      getOptionLabel={getOptionLabel}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="Partner" placeholder="Partner" />
      )}
    />
  );
}

// import { useEffect, useState } from 'react';
// import { useInput } from 'react-admin';
// import Chip from '@mui/material/Chip';
// import Autocomplete from '@mui/material/Autocomplete';
// import TextField from '@mui/material/TextField';
// import { dataProvider } from '../../provider';
// import useDebounce from 'src/hooks/useDebounce';

// export default function InputPartner(props: any) {
//   const { onChange, onBlur, ...rest } = props;
//   const [listPartner, setListPartner] = useState<any>([]);
//   const [currentPage, setCurrentPage] = useState(0);
//   const [filter, setFilter] = useState<string>('');
//   const debouncedValue = useDebounce<string>(filter, 750);

//   const {
//     field,
//     fieldState: { isTouched, error },
//     formState: { isSubmitted },
//     isRequired,
//   } = useInput({
//     onChange,
//     onBlur,
//     ...props,
//   });

//   let thisValue = field?.value;
//   // console.log(3612312, 'field', field);

//   if (typeof field?.value === 'string') {
//     thisValue = field?.value.split(',');
//   }
//   useEffect(() => {
//     field.onChange(field?.value || []);
//   }, [field?.value.toString()]);

//   useEffect(() => {
//     (async () => {
//       try {
//         const { data: listPartners, total: totalResult } = await dataProvider.getList('partners', {
//           filter: {
//             q: filter,
//           },
//           pagination: { page: currentPage, perPage: 50 },
//           sort: {
//             field: 'created_at',
//             order: 'DESC',
//           },
//         });
//         const partnerRemapped = listPartners?.map((partner) => {
//           return {
//             id: partner?.id,
//             name: partner?.name,
//           };
//         });

//         // @ts-ignore
//         setListPartner([{ id: 'ALL', name: 'ALL' }, ...partnerRemapped]);
//       } catch (error) {
//         console.log(83123, error);
//       }
//     })();
//   }, [debouncedValue]);

//   console.log(83123, 'listPartner', listPartner);

//   const handleOnChange = (event: any, newFilter: any) => {
//     field?.onChange([...newFilter]);
//     // setFilter(newFilter);
//     // setCurrentPage(0);
//   };
//   const handleIsOptionEqualToValue = (option: any, value: any) => value.indexOf(option) !== -1;
//   const getOptionLabel = (option: any) => option;
//   const renderTags = (value: any, getTagProps: any) =>
//     value.map((option: any, index: number) => {
//       return <Chip key={option} label={getOptionLabel(option)} {...getTagProps({ index })} />;
//     });

//   return (
//     <Autocomplete
//       multiple
//       id="tags-standard"
//       options={listPartner.map((dat: any) => dat?.name)}
//       isOptionEqualToValue={handleIsOptionEqualToValue}
//       renderTags={renderTags}
//       value={thisValue || []}
//       getOptionLabel={getOptionLabel}
//       onChange={handleOnChange}
//       renderInput={(params) => (
//         <TextField {...params} variant="standard" label="Partner" placeholder="Partner" />
//       )}
//     />
//   );
// }
