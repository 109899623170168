// @ts-nocheck
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  usePermissions,
  LoadingPage,
  useNotify,
  BooleanField,
  TextInput,
  SelectInput,
  FilterForm,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { ProductCreatePage } from './CreatePage';
import { DetailPage } from './DetailPage';
import { ListAlt as ProductIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import {
  pageBadgeIcon,
  exportButtonDefaultStyle,
  exportButtonResponsivetStyle,
} from 'src/styles/global-styles';

const ListActions = (props: any) => {
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { className, exporter, filters, isAdmin, maxResults, navigate, ...rest } = props;

  return (
    <>
      <FilterForm filters={TransactionsFilter} />
      <CreateButton
        variant="contained"
        size="medium"
        style={lessThanSm ? exportButtonResponsivetStyle : exportButtonDefaultStyle}
      />
    </>
  );
};

const TransactionsFilter = [
  <TextInput
    source="search"
    label="Search"
    alwaysOn
    data-testid="search-transaction-id"
    variant="standard"
  />,
  <SelectInput
    source="stock_type"
    label="Stock Type"
    choices={[
      { id: 'BULK', name: 'BULK' },
      { id: 'FIXED', name: 'FIXED' },
      { id: 'BULK/FIX', name: 'BULK/FIX' },
    ]}
    alwaysOn
    data-testid="search-status"
    variant="standard"
  />,
  <SelectInput
    source="is_available"
    label="Availability"
    choices={[
      { id: 'FALSE', name: 'Unavailable' },
      { id: 'TRUE', name: 'Available' },
    ]}
    alwaysOn
    data-testid="search-status"
    variant="standard"
  />,
];

const ProductCatalogsListPage = () => {
  const notify = useNotify();

  const location = useLocation();
  const navigate = useNavigate();
  const matchCreate = matchPath('/product-catalogs/create', location.pathname);
  const matchEdit = matchPath('/product-catalogs/:id/show/*', location.pathname);

  const [userData, setUserData] = React.useState(null);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      console.log(res.data, 1234);

      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const handleClose = React.useCallback(() => {
    console.log('handleClose', { cancel: navigate });
    navigate('/product-catalogs');
  }, [navigate]);
  const { isLoading, permissions } = usePermissions();

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  return !isLoading && userData ? (
    <>
      <List
        title="Product Catalog"
        resource="product-catalogs"
        perPage={25}
        sort={{ field: 'label', order: 'asc' }}
        exporter={false}
        hasCreate={false}
        sx={{
          width: '1200px',
        }}
      >
        <CustomTopToolbar pageBadge={<ProductIcon style={pageBadgeIcon} />}>
          <ListActions navigate={navigate} isAdmin={isAdmin} />
        </CustomTopToolbar>

        <Datagrid
          bulkActionButtons={false}
          rowClick={(id) => {
            navigate(`/product-catalogs/${id}/show`);
          }}
        >
          <TextField source="item_key" data-test-id="field-item-key" />
          <TextField source="label" data-test-id="field-label" />
          <TextField source="description" data-test-id="field-description" />
          <TextField source="stock_type" data-test-id="field-stock-type" />
          <BooleanField source="is_available" label="Status" data-test-id="field-status" />
        </Datagrid>
      </List>
      <Drawer
        variant="temporary"
        open={!!matchCreate || (!!matchEdit && matchEdit?.params?.id !== 'create')}
        anchor="right"
        onClose={handleClose}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!matchEdit && matchEdit?.params?.id !== 'create' && (
          <DetailPage id={(matchEdit as any).params.id} onCancel={handleClose} isAdmin={isAdmin} />
        )}
        {!!matchCreate && <ProductCreatePage onCancel={handleClose} />}
      </Drawer>
    </>
  ) : (
    <LoadingPage />
  );
};

export default ProductCatalogsListPage;
