import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  CreateParams,
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateParams,
  UpdateResult,
  DeleteParams,
  DeleteResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
export class ProductPricingsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const data = await this.client.getTable("product_pricings").insert({
      //   item_key: params?.data.item_key,
      //   label: params?.data.label,
      //   stock_type: params?.data.stock_type,
      //   is_available: params?.data.availability,
      //   description: params?.data.description,
      product_item_id: params?.data.product_item_id,
      account_id: params?.data.account_id,
      method: params?.data.method,
      value: params?.data.value,
      is_available: params?.data.is_available.toLowerCase() === "true",
    });

    return { data: data?.data };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    const { updateProductPricingsById } = await this.client.gql.mutation({
      mutation: `
        mutation updateProductPricings($ids: [UUID!]!, $input: ProductPricingsUpdateInput!) {
          updateProductPricingsById(ids: $ids, input: $input) {
            id
            product_item_id
            account_id
            method
            value
            is_available
          }
        }
      `,
      variables: {
        ids: params?.id,
        input: params?.data,
      },
    });

    return { data: updateProductPricingsById[0] };
  }

  async delete(
    resource: string,
    params: DeleteParams<any>
  ): Promise<DeleteResult<any>> {
    const queryResult = await this.client.gql.mutation({
      mutation: `
            mutation($ids: [UUID!]!){
              deleteProductPricingsById(ids:$ids)
            }
          `,
      variables: {
        ids: [`${params.id}`],
      },
    });

    return { data: queryResult?.deleteProductPricingsById[0] } as DeleteResult;
  }
}
