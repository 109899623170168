// @ts-nocheck
import React from 'react';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Edit,
  TextInput,
  SimpleForm,
  useRecordContext,
  useRefresh,
  usePermissions,
  FunctionField,
  SelectInput,
  Button,
  LoadingPage,
  useUpdate,
  useShowController,
  Confirm,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  useRedirect,
} from 'react-admin';
import {
  Grid,
  Box,
  Typography,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import mbaas from '../../provider/Mbaas';
import AddUserDialog from './AddUserDialog';
import Swal from 'sweetalert2';
import GenerateKeys from './GenerateKey';
import GenerateApiPassword from './GenerateApiPassword';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IpWhiteListField from './IpWhitelistField';
import { DownlineAccountTable } from './DownlineAccountTable';
import CustomAutoComplete from './CustomAutoComplete';

const Title = () => {
  const record = useRecordContext();
  return <span data-test-id="edit-title">Detail {record ? record?.id : ''}</span>;
};

interface ICreatedData {
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
  address: string;
  phone: string;
  roles: any;
  status: boolean;
}

const EditPage = ({ ...props }: EditProps) => {
  const { record } = useShowController(props);
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const [cOpen, setCOpen] = React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [createdData, setCreatedData] = React.useState<ICreatedData>({
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
    address: '',
    phone: '',
    roles: [],
    status: false,
  });
  const [update] = useUpdate();
  const redirect = useRedirect();
  //
  const handleClickOpen = () => {
    setOtpCode('');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });

      if (data === 'OK') {
        try {
          const dataRegis = await mbaas.client.auth.register('local', {
            email: createdData.email,
            password: createdData.password,
            extras: {
              address: createdData.address,
              name: createdData.name,
              phone: createdData.phone,
              roles: createdData.roles,
              status: createdData.status,
              account_id: record?.id,
            },
          });

          if (dataRegis) {
            await mbaas.client.getTable('users').update(
              Object.assign({
                id: dataRegis.data?.id,
                data: {
                  roles: [createdData.roles],
                },
              })
            );
          }
        } catch (e) {
          console.log('error', e);
        }

        setOtpCode('');
        setOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setOpen(false);
        Swal.fire(error?.error, '', 'error');
      }
    } catch (e: any) {
      Swal.fire('Something wrong!', e, 'error');
    }
  };

  const handleConfirm = async () => {
    console.log(663123, 'record', record);
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });

      if (data === 'OK') {
        if (mode === 'DELETE') {
          await mbaas.client.gql.mutation({
            mutation: `
              mutation updateUserAccount($ids: [UUID!]!, $input: UsersUpdateInput!) {
                updateUsersById(ids: $ids, input: $input) {
                  id
                  created_at
                  updated_at
                  account_id
                }
              }
            `,
            variables: {
              ids: userId,
              input: { account_id: null },
            },
          });
        } else {
          update(
            'accounts',
            {
              id: formData?.id,
              data: formData,
              previousData: { id: formData?.id, ...record },
            },
            {
              onSuccess: () => {
                refresh();
              },
            }
          );
        }

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);
        Swal.fire(error?.error, '', 'error');
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const { isLoading, permissions } = usePermissions();
  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');
  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');
  const [formData, setFormData] = React.useState<any>(null);
  const [mode, setMode] = React.useState<any>(null);

  const onSubmitHandler = (data) => {
    setMode('UPDATE');
    setCOpen(true);
    setFormData(data);
  };

  console.log(883123123, 'formData-editPage', formData);

  return !isLoading ? (
    <Edit title={<Title />}>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={() => setCOpen(false)}
      />
      <Box display="block" m={2} justifyContent="center" alignItems="center">
        <SimpleForm onSubmit={onSubmitHandler}>
          <Grid container spacing={2}>
            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
              <div
                style={{
                  marginBottom: '17px',
                }}
              >
                <CustomAutoComplete context="createAccount" source="parent_id_data" />
              </div>
              <TextInput
                data-test-id="input-label"
                source="label"
                isRequired
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />
              <TextInput
                data-test-id="input-contact"
                source="contact"
                isRequired
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />
              <SelectInput
                data-test-id="select-api-ver"
                source="api_version"
                label="API Version"
                choices={[
                  { id: 'v1', value: 'v1' },
                  { id: 'v2', value: 'v2' },
                ]}
                optionText={(record) => record.value}
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />
            </Box>
          </Grid>
          {!isLoading &&
            (permissions.includes('Administrator') ||
              permissions.includes('Partner') ||
              permissions.includes('Staff')) && (
              <>
                <Grid container spacing={2} marginTop={1}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box mr={2}>
                      <Typography variant="h5">Associated Users</Typography>
                    </Box>
                    <Box mr={1}>
                      <AddUserDialog
                        setUserId={setUserId}
                        setCreatedData={setCreatedData}
                        open={open}
                        otpCode={otpCode}
                        setOtpCode={setOtpCode}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        handleAdd={handleAdd}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid container spacing={2} marginTop={1}>
                  <Box flex={2} mt={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <FunctionField
                      label="Name"
                      render={(record: any) => (
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                  E-mail
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                  Name
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                                  Role
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {record?.users_account_id_list?.data.map(
                                (row: { name: string; email: string; id: string }) => (
                                  <TableRow
                                    key={row.email}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.email}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.roles}</TableCell>
                                    <TableCell align="center">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => {
                                          setUserId(row.id);
                                          setOtpCode('');
                                          setMode('DELETE');
                                          setCOpen(true);
                                        }}
                                        disabled={permissions.includes('Staff') ? true : false}
                                      >
                                        Delete
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    />
                  </Box>
                </Grid>
              </>
            )}

          <DownlineAccountTable permissions={permissions} isLoading={isLoading} />

          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData?.api_version === 'v2' ? (
                <>
                  <Grid container spacing={2} marginTop={2}>
                    <Box flex={4} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="private_key_signature"
                        label="Private key Signature"
                        data-test-id="private-key-sign"
                        disabled
                        multiline
                        fullWidth
                      />
                      <TextInput
                        source="public_key"
                        data-test-id="public-key"
                        disabled
                        multiline
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  {isPartner && (
                    <Grid container spacing={2}>
                      <GenerateKeys data-test-id="generate-api-pass" refresh={refresh} />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid container spacing={2} marginTop={2}>
                  <Grid container spacing={2}>
                    <GenerateApiPassword
                      data-test-id="generate-api-pass"
                      refresh={refresh}
                      isPartner={isPartner}
                      source="api_password"
                    />
                  </Grid>
                </Grid>
              );
            }}
          </FormDataConsumer>
          <Grid container spacing={2} marginTop={1}>
            <Box flex={4} mr={{ xs: 12, sm: '0.5em' }}></Box>
            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12}>
                <IpWhiteListField
                  source="whitelist_ips"
                  isPartner={isPartner}
                  isAdmin={isAdmin}
                  label="Ip Whitelist"
                />
              </Grid>
            </Grid>
            <ArrayInput source="webhook_configuration" fullWidth>
              <SimpleFormIterator
                addButton={<Button label="Add" startIcon={<AddCircleOutlineIcon />} />}
                disableReordering
                inline
                disableAdd={!isPartner && true}
                disableRemove={permissions.includes('Staff') ? true : false}
              >
                <TextInput
                  source="url"
                  label="URL"
                  data-test-id="input-url"
                  style={{
                    width: '500px',
                  }}
                  disabled={permissions.includes('Staff') ? true : false}
                />
                <SelectArrayInput
                  source="status"
                  data-test-id="select-status"
                  choices={[
                    { id: 'QUOTE', name: 'QUOTE' },
                    { id: 'PENDING', name: 'PENDING' },
                    { id: 'SUCCESS', name: 'SUCCESS' },
                    { id: 'FAILED', name: 'FAILED' },
                  ]}
                  style={{
                    width: '200px',
                  }}
                  disabled={permissions.includes('Staff') ? true : false}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </SimpleForm>
      </Box>
    </Edit>
  ) : (
    <LoadingPage />
  );
};

export default EditPage;
