import { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  Confirm,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
  useRedirect,
  usePermissions,
  useCreate,
  useRefresh,
  useGetIdentity,
} from 'react-admin';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';
import NumberFormatInput from './NumberFormatInput';

const CreatePage = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { isLoading, permissions } = usePermissions();
  const [create] = useCreate();
  const refresh = useRefresh();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [formData, setFormData] = useState<any>(null);

  const { identity, isLoading: identityLoading } = useGetIdentity();

  const isAdminStockIn = !isLoading && permissions.find((el: string) => el === 'Admin Stock-In');
  const isAdminStockOut = !isLoading && permissions.find((el: string) => el === 'Admin Stock-Out');
  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');
  const isPartnerTopUp = !isLoading && permissions.find((el: string) => el === 'Partner Top-Up');

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (data === 'OK') {
        create(
          'stock-managements',
          { data: formData },
          {
            onSuccess: () => {
              redirect('/stock-managements');
            },
            onError(error, variables, context) {
              console.log('ERRRROOOOORRRRRRRRR', error);
              notify('' + error, {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const onSubmitHandler = (data: any) => {
    setCOpen(true);
    setFormData(data);
  };

  // filter for select input when user doing stock out or stock in
  const referenceInputFilter = isAdminStockIn
    ? { isAdminStockIn: '6dcc047a-1808-41c9-b76b-afd731c989a3' }
    : isAdminStockOut
    ? {
        isAdminStockOut: '6dcc047a-1808-41c9-b76b-afd731c989a3',
      }
    : isPartner || isPartnerTopUp
    ? { isPartnerStock: identity?.account_id }
    : {};

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e: any) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleSubmit}
        onClose={() => setCOpen(false)}
      />
      <Create title="Create Stock Managements" redirect="/stock-managements">
        <Box display="block" m={2} justifyContent="center" alignItems="center">
          <SimpleForm onSubmit={onSubmitHandler}>
            <ReferenceInput source="account_id" reference="accounts" filter={referenceInputFilter}>
              <AutocompleteInput
                source="label"
                optionText={(choice) => choice.label}
                label="Transfer to"
                data-test-id="input-transfer-to"
                fullWidth
                validate={[required()]}
              />
            </ReferenceInput>
            <TextInput fullWidth source="remark" data-test-id="input-remark" />

            <Grid container direction="column">
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">15K</Typography>
                <NumberFormatInput
                  label=""
                  source="15K"
                  placeholder=""
                  data-test-id="input-15k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">20K</Typography>
                <NumberFormatInput
                  label=""
                  source="20K"
                  placeholder=""
                  data-test-id="input-20k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">25K</Typography>
                <NumberFormatInput
                  label=""
                  source="25K"
                  placeholder=""
                  data-test-id="input-25k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">30K</Typography>
                <NumberFormatInput
                  label=""
                  source="30K"
                  placeholder=""
                  data-test-id="input-30k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">40K</Typography>
                <NumberFormatInput
                  label=""
                  source="40K"
                  placeholder=""
                  data-test-id="input-40k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">50K</Typography>
                <NumberFormatInput
                  label=""
                  source="50K"
                  placeholder=""
                  data-test-id="input-50k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">75K</Typography>
                <NumberFormatInput
                  label=""
                  source="75K"
                  placeholder=""
                  data-test-id="input-75k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '25px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end">100K</Typography>
                <NumberFormatInput
                  label=""
                  source="100K"
                  placeholder=""
                  data-test-id="input-100k"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '15px',
                  }}
                />
              </Grid>
              <Grid container item direction="row" style={{ marginBottom: '8px' }}>
                <Typography alignSelf="end" alignItems="right">
                  BULK
                </Typography>
                <NumberFormatInput
                  label=""
                  source="BULK"
                  placeholder=""
                  data-test-id="input-bulk"
                  fullWidth
                  style={{
                    width: '35%',
                    marginBottom: '-10px',
                    marginLeft: '10px',
                  }}
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Box>
      </Create>
    </>
  );
};

export default CreatePage;
