// @ts-nocheck
import Swal from "sweetalert2";
import { resetAuthenticator } from "./reset_authenticator";
// import { changePasswordFlow } from './changePassword';
import { resetPassword } from "./reset_password";

export async function manageProfile(promisedAuthData) {
  const authData = await promisedAuthData;
  const googleLinked = (authData.social_ids || {})["google"];

  console.log(567, "authData", authData);
  console.log(567, "googleLinked", googleLinked);

  let inputOptions;
  if (authData.roles.includes("Partner View")) {
    inputOptions = {
      changePassword: "Change Password",
    };
  } else {
    inputOptions = {
      resetAuthenticator: "Reset authenticator",
      changePassword: "Change Password",
    };
  }

  // if (!googleLinked) inputOptions["linkGoogle"] = "Link Google Account";
  // else inputOptions["unlinkGoogle"] = "Unlink Google Account";

  const { value } = await Swal.fire({
    title: "Manage your profile",
    text: authData.email,
    input: "select",
    inputOptions,
    inputPlaceholder: "",
    showCancelButton: true,
  });

  switch (value) {
    case "changePassword":
      return resetPassword(authData);
    case "resetAuthenticator":
      return resetAuthenticator();
    default:
  }
}

// async function linkGoogleAccount() {
// 	await mbaas.client.auth.oauthRedirect(
// 		"google",
// 		window.location.origin + "/account-link"
// 	);
// }

// async function unlinkGoogleAccount() {
// 	Swal.queue([
// 		{
// 			title: "Unlink current Google account",
// 			text: "Please press YES to continue",
// 			icon: "warning",
// 			showCancelButton: true,
// 			confirmButtonColor: "#3085d6",
// 			cancelButtonColor: "#d33",
// 			confirmButtonText: "Yes",
// 			preConfirm: () => {
// 				return mbaas.client.user
// 					.update("unlink", { provider: "google" })
// 					.then((resp) => {
// 						Swal.insertQueueStep(resp.message);
// 					})
// 					.catch(() => {
// 						Swal.insertQueueStep({
// 							icon: "error",
// 							title: "Unable to process your request",
// 						});
// 					});
// 			},
// 		},
// 	]);
// }
