import * as React from "react";
import { useRecordContext, useUpdate, Confirm } from "react-admin";
import { Button } from "@mui/material";
import mbaas from "../../provider/Mbaas";

import { SyncLock as GenerateIcon } from "@mui/icons-material";

const GenerateKeys = ({ refresh }: any) => {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [openPriv, setOpenPriv] = React.useState(false);
  const [update, { isLoading }] = useUpdate();

  const [priv, setPriv] = React.useState(null);
  const [pub, setPub] = React.useState(null);
  const [signedPriv, setSignedPriv] = React.useState(null);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const { data: keyPairData } = await mbaas.client.invokeApi(
      "account-credential-generator"
    );

    setPriv(keyPairData?.keyPair?.privateKey);
    setPub(keyPairData?.keyPair?.publicKey);
    setSignedPriv(keyPairData?.privKeySigned);
    setOpen(false);
    setOpenPriv(true);
  };

  const handleDialogClosePriv = () => setOpenPriv(false);
  const handleConfirmPriv = async () => {
    update(
      "accounts_key_update",
      {
        id: record?.id,
        data: {
          private_key_signature: signedPriv,
          public_key: pub,
        },
        previousData: record,
      },
      {
        onSettled() {
          refresh();
        },
      }
    );

    setOpenPriv(false);
  };

  return (
    <>
      <Button
        data-test-id="generate-key-btn"
        startIcon={<GenerateIcon />}
        variant="contained"
        color="secondary"
        disabled={isLoading}
        onClick={handleClick}
      >
        Generate
      </Button>
      <Confirm
        isOpen={open}
        title="Generate Keys"
        content="Are you sure you want to generate a new key pair? this action cannot be reverted"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
      <Confirm
        cancel="close"
        isOpen={openPriv}
        loading={isLoading}
        title="Generated Private Key"
        content={<label>{priv}</label>}
        onConfirm={handleConfirmPriv}
        onClose={handleDialogClosePriv}
      />
    </>
  );
};

export default GenerateKeys;
