import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { CreateParams, CreateResult } from "react-admin";
import Mbaas from "./Mbaas";
import { ProviderBase } from "./ProviderBase";
export class OverrideDisputeProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    console.log(params, 9090909090);
    const { data } = await Mbaas.client.invokeApi("totp", {
      action: "VALIDATE",
      token: params.data.totp,
    });

    if (data === "OK") {
      await Mbaas.client
        .invokeApi("override-dispute-transaction", {
          transaction_id: params.data.id,
          action: params.data.action,
        })
        .then((response) => {
          console.log(651231, "response", response.error);
          if (response?.error) {
            throw new Error(`Override Error: ${response.error.error}`);
          }
          return response;
        });
    } else {
      throw new Error("TOTP Invalid");
    }
    return { data: params.data } as CreateResult;
  }
}
