import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { ProviderBase } from "./ProviderBase";
export class UsersWithoutAccountProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult> {
    const where: Record<string, any> = {};
    if (params.filter.q) {
      where._or = [
        {
          email: {
            _ilike: `%${params.filter.q}%`,
          },
        },
      ];
    }
    where._and = [
      {
        account_id: {
          _is_null: true,
        },
        roles: {
          _has_key: "Partner",
        },
      },
    ];
    const { allUsersList, error } = await this.client.gql.query({
      query: `
      query usersList($where: UsersWhereInput!) {
        allUsersList(where: $where, limit: 9999, offset: 0) {
          data {
            id
            created_at
            email
            name
            roles
          }
          count
        }
      }
      `,
      variables: { where },
    });
    console.log("7777788888", { allUsersList, error });
    if (error) {
      console.warn(error);
      throw new Error("GraphQL query error");
    }
    console.log(allUsersList);

    return {
      data: allUsersList.data,
      total: allUsersList.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    console.log(params);
    const { getUsersById } = await this.client.gql.query({
      query: `
      query aUser($id:UUID) {
        getUsersById(id:$id) {
          id
          created_at
          email
          name
          roles
        }
      }
      `,
      variables: {
        id: params.id,
      },
    });

    console.log("getuserbyID", getUsersById);

    return { data: getUsersById };
  }

  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    let data = {};
    console.log("users create", params);

    data = await this.client.getTable(resource).insert({
      email: params.data.email,
      address: params.data.address,
      name: params.data.name,
      phone: params.data.phone,
      roles: [params.data.roles],
      status: params.data.status,
      api_password: params.data.api_password,
      whitelist_ips: params.data.whitelist_ips,
    });
    console.log(data);
    console.log("clientt", this.client);

    const { data: userData } = await this.client.user.get();

    return { data: userData };
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    console.log("update params", params);
    console.log(
      "compare",
      params.data.roles[0] === params.previousData.roles[0]
    );
    console.log("roles", params.data.roles[0]);

    const { data } = await this.client.getTable("users").update(
      Object.assign({
        id: params.id,
        data: {
          email: params.data.email,
          name: params.data.name,
          address: params.data.address,
          phone: params.data.phone,
          roles:
            params.data.roles[0] === params.previousData.roles[0]
              ? [params.data.roles[0]]
              : [params.data.roles],
          status: params.data.status,
          api_password: params.data.api_password,
          whitelist_ips: params.data.whitelist_ips,
        },
      })
    );

    return { data: data };
  }
}
