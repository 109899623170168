import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import {
  Create,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  Confirm,
  useCreate,
  useRedirect,
  useRefresh,
  useNotify,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import mbaas from '../../provider/Mbaas';

const useStyles = makeStyles({
  input: {
    marginBottom: '0px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const ProductCreatePage = (props: any) => {
  const classes = useStyles();

  const [create] = useCreate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const [cOpen, setCOpen] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [formData, setFormData] = useState<any>(null);

  const handleSubmit = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      console.log('ERROR-TOTP', error?.error);
      if (data === 'OK') {
        create(
          'product-catalogs',
          { data: formData },
          {
            onSuccess: () => {
              redirect('/product-catalogs');
            },
            onError(error, variables, context) {
              notify('An error has occurred. Please try again later', {
                type: 'error',
              });
            },
          }
        );

        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);

        notify('An error has occurred. Please try again later', {
          type: 'error',
        });
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  const onSubmitHandler = (data: any) => {
    setCOpen(true);
    setFormData(data);
  };

  return (
    <React.Fragment>
      <Box pt={5} width={{ xs: '100vW', sm: 600 }} mt={{ xs: 2, sm: 1 }}>
        <Confirm
          isOpen={cOpen}
          title="Submit OTP 2FA to Confirm Action"
          content={
            <TextField
              type="number"
              fullWidth
              data-test-id="input-otp"
              value={otpCode}
              onChange={(e: any) => setOtpCode(e.target.value)}
            />
          }
          onConfirm={handleSubmit}
          onClose={() => setCOpen(false)}
        />
        <Create title="&rsaquo; Create Product Catalog" {...props}>
          <Box width={{ xs: '100vW', sm: 700 }}>
            <Typography variant="h6" flex="1" style={{ marginInlineStart: 15 }}>
              Create New Product
            </Typography>
          </Box>
          <Box width={{ xs: '100vW', sm: 700 }} mt={{ xs: 2, sm: 1 }}>
            <SimpleForm onSubmit={onSubmitHandler}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    label="Item Key"
                    source="item_key"
                    data-test-id="input-item-key"
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    label="Label"
                    source="label"
                    data-test-id="input-label"
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    className={classes.input}
                    label="Description"
                    source="description"
                    data-test-id="input-description"
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <SelectInput
                    source="stock_type"
                    data-test-id="input-stock-type"
                    className={classes.input}
                    choices={[
                      { id: 'BULK', name: 'BULK' },
                      { id: 'FIXED', name: 'FIXED' },
                      { id: 'BULK/FIX', name: 'BULK/FIX' },
                    ]}
                    fullWidth
                    validate={required()}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <BooleanInput
                    source="is_available"
                    label="Available"
                    data-test-id="input-status"
                    className={classes.input}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </SimpleForm>
          </Box>
        </Create>
      </Box>
    </React.Fragment>
  );
};
