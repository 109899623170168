import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
} from 'react-admin';
import { ProviderBase } from './ProviderBase';
import mbaas from './Mbaas';

export class StockManagementsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    const { data: userData } = await this.client.user.get();
    const requestParams = {
      pagination: {
        page: params.pagination.page,
        perPage: params.pagination.perPage,
      },
      // kalau login sebagai telin filter ON, kalau partner filter OFF
      // filter: {
      //   account_id: params.filter.account_id,
      // },
      sort: {
        field: params.sort.field === 'reference_id' ? 'deposit_reference_id' : params.sort.field,
        order: params.sort.order.toUpperCase(),
      },
    };
    // kalau login sebagai telin filter ON, kalau partner filter OFF
    if (userData?.roles.includes('Administrator')) {
      Object.assign(requestParams, {
        filter: {
          account_id: params.filter.account_id,
        },
      });
    }

    const ledgerList = await this.client.invokeApi('deposits-list', requestParams);

    const mapAccountLedgersList = ledgerList.data.data.map((dt: any, idx: any) => {
      return {
        id: idx,
        reference_id: dt.deposit_reference_id,
        datetime: dt.datetime,
        from: dt.source,
        destination: dt.destination,
        status: dt.status,
        type15K: dt['15K'],
        type20K: dt['20K'],
        type25K: dt['25K'],
        type30K: dt['30K'],
        type40K: dt['40K'],
        type50K: dt['50K'],
        type75K: dt['75K'],
        type100K: dt['100K'],
        BULK: dt.BULK,
      };
    });

    return { data: mapAccountLedgersList, total: ledgerList.data.total };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    return { data: [] };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const { data } = await mbaas.client.user.get();

    const { allDepositTypesList } = await this.client.gql.query({
      query: `
      query depositTypeList {
        allDepositTypesList(limit: 50, offset: 0) {
          data {
            id
            label      
          }
          count
        }
      }
      `,
    });

    const deposit_list = allDepositTypesList.data
      .map((dt: any) => {
        return {
          deposit_type_id: dt.id,
          amount: parseInt(params.data[dt.label]) || 0,
        };
      })
      .filter((el: any) => el.amount);

    //? ================ create deposits
    const addDeposits = await mbaas.client.invokeApi('deposits-stock', {
      deposit_list: deposit_list,
      account_id: params.data.account_id,
      remark: params.data.remark,
    });

    if (addDeposits.error) {
      throw new Error(addDeposits.error.error);
    }
    //? ================
    return { data: data } as CreateResult;
  }
}
