import {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";

export class ProviderBase<T extends RaRecord = any> {
  // get a list of records based on sort, filter, and pagination
  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<T>> {
    throw new Error("Method not implemented.");
  }
  // get a single record by id
  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<T>> {
    throw new Error("Method not implemented.");
  }
  // get a list of records based on an array of ids
  async getMany(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<T>> {
    throw new Error("Method not implemented.");
  }
  // get the records referenced to another record, e.g. comments for a post
  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<T>> {
    throw new Error("Method not implemented.");
  }
  // create a record
  async create(
    resource: string,
    params: CreateParams
  ): Promise<CreateResult<T>> {
    throw new Error("Method not implemented.");
  }
  // update a record based on a patch
  async update(
    resource: string,
    params: UpdateParams
  ): Promise<UpdateResult<T>> {
    throw new Error("Method not implemented.");
  }
  // update a list of records based on an array of ids and a common patch
  async updateMany(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult<T>> {
    throw new Error("Method not implemented.");
  }
  // delete a record by id
  async delete(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<T>> {
    throw new Error("Method not implemented.");
  }
  // delete a list of records based on an array of idss
  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult<T>> {
    throw new Error("Method not implemented.");
  }
}
