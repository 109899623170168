import React, { useEffect, useState } from "react";
import { useListContext } from "react-admin";
import { makeStyles } from "@mui/styles";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const useStyles = makeStyles(() => ({
  date: {
    marginBottom: "5px",
  },
}));

export const DateRange = (props: any) => {
  const styleClass = useStyles();
  const { setFilters, filterValues } = useListContext();

  var date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  console.log("daattee", firstDay);
  console.log(filterValues.dateGte);
  const [dateValue, setDateValue] = useState({
    dateEnd:
      typeof filterValues?.dateLte === "string"
        ? new Date(filterValues.dateLte)
        : lastDay,
    dateBegin:
      typeof filterValues?.dateGte === "string"
        ? new Date(filterValues.dateGte)
        : firstDay,
  });

  useEffect(() => {
    setFilters(
      {
        dateGte: dateValue.dateBegin,
        dateLte: dateValue.dateEnd,
      },
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue]);

  console.log({ dateValue, props });

  return (
    <DateRangePicker
      value={[dateValue.dateBegin, dateValue.dateEnd]}
      //if the value clean, the date will set to null
      onClean={() => {
        setDateValue({
          dateBegin: null as any,
          dateEnd: null as any,
        });
      }}
      onChange={(value: any) => {
        setDateValue({
          dateBegin: value ? new Date(value[0]) : (null as any),
          dateEnd: value ? new Date(value[1]) : (null as any),
        });
      }}
      className={styleClass.date}
      size="lg"
    />
  );
};
