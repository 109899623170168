import MobileServiceClient from '@mocobaas/client-js/build/MobileServiceClient';
import {
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  CreateParams,
  CreateResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ProviderBase } from './ProviderBase';
import { buildArgs } from './utils';
export class ProductCatalogsProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getList(resource: string, params: GetListParams): Promise<GetListResult> {
    const where: Record<string, any> = {};
    const filterArray: any[] = [];
    console.log(47293, { resource, params, method: 'getList' });
    if (params.filter.search) {
      where._or = [
        {
          label: {
            _ilike: `%${params.filter.search}%`,
          },
        },
        {
          item_key: {
            _ilike: `%${params.filter.search}%`,
          },
        },
      ];
    }

    if (params.filter.stock_type) {
      filterArray.push({
        stock_type: {
          _eq: `${params.filter.stock_type}`,
        },
      });
    }

    if (params.filter.is_available) {
      filterArray.push({
        is_available: {
          _eq: params.filter.is_available === 'TRUE',
        },
      });
    }

    if (filterArray.length > 0) {
      where._and = filterArray;
    } else {
      where._and = undefined;
    }

    const args = buildArgs({
      order: params.sort.order.toUpperCase(),
      size: params.pagination.perPage,
      pageNumber: params.pagination.page,
      sort: params.sort.field,
    });
    const { allProductItemsList, error } = await this.client.gql.query({
      query: `
      query productItemsList($where: ProductItemsWhereInput) {
        allProductItemsList(where: $where, ${args}) {
          data {
            id
            created_at
            updated_at
            item_key
            description
            label
            stock_type
            is_available
            product_pricings_product_item_id_list{
              data{
                id
                account_id_data{
                  id
                  label
                }
                method
                value
                is_available
              }
            }
          }
          count
        }
      }
      `,
      variables: {
        where,
      },
    });
    if (error) {
      throw new Error('GraphQL query error');
    }

    const mapped = allProductItemsList.data.map((el: any) => {
      return {
        ...el,
        // account_label: el.account_id_data.label,
        // product_label: el?.product_item_id_data?.label,
        // supplier_label: el?.supply_item_id_data?.supplier_id_data?.name,
      };
    });
    console.log(472934, mapped);
    return {
      // data: allTransactionsList.data,
      data: mapped,
      total: allProductItemsList.count,
      // total: allTransactionListX.count,
    };
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    await this.client.user.get().then((result) => result.data || null);

    const { getProductItemsById, allAccountsList, allSuppliersList, allSupplyItemsList } =
      await this.client.gql.query({
        query: `
      query aProductItems($id:UUID) {
        getProductItemsById(id:$id) {
          id
          created_at
          updated_at
          item_key
          description
          label
          stock_type
          is_available
          product_pricings_product_item_id_list{
            data{
              id
              account_id_data{
                id
                label
              }
              method
              value
              is_available
            }
          }
          supply_routes_product_item_id_list{
            data{
              id
              suppy_item_id_data {
                id
                label
                description
                cost
                is_available
                data
                supplier_id_data{
                  id
                  name
                }
              }
            }
          }
        }
        allAccountsList(limit: 9999999){
          data {
            id
            label
          }
        }
        allSuppliersList(limit: 9999999){
          data {
            id
            name
          }
        }
        allSupplyItemsList(limit: 9999999){
          data{
            id
            supplier_id
            label
            description
            cost
            is_available
            data
          }
        }
      }
      `,
        variables: {
          id: params.id,
        },
      });

    return {
      data: Object.assign({
        ...getProductItemsById,
        supplier_list: allSuppliersList.data || [],
        supplied_items: allSupplyItemsList.data || [],
        account_list: allAccountsList.data || [],
      }),
    };
    // return { data: allTransactionListX.data.filter((dt) => {return dt.id === params.id})[0] };
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<any>> {
    return { data: [] };
  }

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    return { data: [], total: 0 };
  }

  async create(resource: string, params: CreateParams): Promise<CreateResult> {
    const data = await this.client.getTable('product_items').insert({
      item_key: params?.data.item_key,
      label: params?.data.label,
      stock_type: params?.data.stock_type,
      is_available: params?.data.availability,
      description: params?.data.description,
    });

    return { data: data?.data };
  }

  async update(resource: string, params: UpdateParams<any>): Promise<UpdateResult<any>> {
    await this.client.invokeApi('totp', {
      action: 'VALIDATE',
      token: params?.data?.totp,
    });
    const { updateProductItemsById } = await this.client.gql.mutation({
      mutation: `
        mutation updateProductCatalogs($ids: [UUID!]!, $input: ProductItemsUpdateInput!) {
          updateProductItemsById(ids: $ids, input: $input) {
            id
            created_at
            updated_at
            item_key
            description
            label
            stock_type
            is_available
          }
        }
      `,
      variables: {
        ids: params?.id,
        input: {
          description: params?.data.description,
          item_key: params?.data.item_key,
          label: params?.data.label,
          stock_type: params?.data.stock_type,
          is_available: params?.data.is_available,
        },
        // input: params?.data
      },
    });

    return { data: updateProductItemsById[0] };
  }
}
