// @ts-nocheck
import { createContext, useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  Button,
  FilterButton,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  DateField,
  Confirm,
  useListContext,
  useCreate,
  useGetList,
  TextInput,
  FilterForm,
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import { FilterDateCheck } from './FilterDateCheck';
import mbaas from '../../provider/Mbaas';
import { Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { endOfToday, format } from 'date-fns';
import moment from 'moment';
import { Check as StatementsIcon } from '@mui/icons-material';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  pageBadgeIcon,
  exportButtonDefaultStyle,
  exportButtonResponsivetStyle,
} from 'src/styles/global-styles';
export const FilterContextProv = createContext();

export const StatementsListPage = (props: any) => {
  const [open, setOpen] = useState(false);
  const [filterContext, setFilterContext] = useState({
    search_by_label: '',
    search_by_reference_id: '',
    account_id: '',
  });
  const [userData, setUserData] = useState(null);
  const { isLoading, permissions } = usePermissions();
  if (!isLoading) {
    console.log(permissions);
  }
  const navigate = useNavigate();

  const listFilters = [
    <FilterDateCheck data-test-id="filter-date-check" alwaysOn />,
    <ReferenceInput label="Label" source="search_by_label" reference="stock-transfers">
      <AutocompleteInput
        source="label"
        optionValue="id"
        data-test-id="filter-label"
        optionText={(choice) => choice.label}
        sx={{ width: '250px' }}
        onChange={(e) =>
          setFilterContext((prevState: any) => ({
            ...prevState,
            search_by_label: e,
          }))
        }
        variant="standard"
      />
    </ReferenceInput>,
    <TextInput
      variant="standard"
      source="search_by_reference_id"
      data-test-id="search_by_reference_id"
      sx={{ width: '250px' }}
      alwaysOn
      onChange={(e) =>
        setFilterContext((prevState: any) => ({
          ...prevState,
          search_by_reference_id: e.target.value,
        }))
      }
    />,
    <ReferenceInput label="Account" source="account_id" reference="accounts">
      <AutocompleteInput
        variant="standard"
        source="label"
        optionValue="id"
        data-test-id="filter-account"
        optionText={(choice) => choice.label}
        sx={{ width: '250px' }}
        onChange={(e) =>
          setFilterContext((prevState: any) => ({
            ...prevState,
            account_id: e,
          }))
        }
      />
    </ReferenceInput>,
  ];

  const listFiltersPartner = [
    <FilterDateCheck data-test-id="filter-date-check2" alwaysOn />,
    <ReferenceInput label="Label" source="search_by_label" reference="stock-transfers">
      <AutocompleteInput
        variant="standard"
        source="label"
        data-test-id="filter-label2"
        optionValue="id"
        optionText={(choice) => choice.label}
        sx={{ width: '250px' }}
        onChange={(e) =>
          setFilterContext((prevState: any) => ({
            ...prevState,
            search_by_label: e,
          }))
        }
      />
    </ReferenceInput>,
  ];

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');
  const isAdminDispute = !isLoading && permissions.find((el: string) => el === 'Admin Dispute');
  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');
  const isPartnerView = !isLoading && permissions.find((el: string) => el === 'Partner View');
  const isStaff = !isLoading && permissions.find((el: string) => el === 'Staff');
  const isAdminStockIn = !isLoading && permissions.find((el: string) => el === 'Admin Stock-In');
  const isAdminStockOut = !isLoading && permissions.find((el: string) => el === 'Admin Stock-Out');

  const ListActions = () => {
    const theme = useTheme();
    const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const ExportPurchaseLogButton = () => {
      const { filterValues } = useListContext();
      const {
        data: datStockTransfer,
        isLoading,
        error: errStockTransfer,
      } = useGetList('stock-transfers');
      //
      const type_name = 'statements';
      const gen_file_name = `${type_name}_${format(
        new Date(endOfToday()),
        'yyyy-MM-dd'
      )}_${new Date().getTime()}`;
      //
      let resLabel;
      if (filterValues?.search_by_label) {
        resLabel = datStockTransfer?.filter((dt) => {
          return dt.id === filterValues?.search_by_label;
        })[0];
      }
      //
      const [exportData, { data, error }] = useCreate('reports', {
        data: {
          account_id: userData?.account_id,
          author_id: userData?.id,
          type: type_name,
          status: 'QUEUED',
          data: {
            filter: {
              ...filterValues,
              search_by_label: resLabel?.label,
            },
            metadata: {
              generated_filename: gen_file_name, // jika ada
            },
          },
        },
      });

      if (data && !error) {
        //
        console.log(767, 'ERROR');
      }

      const handleOnClick = () => {
        exportData();
        setOpen(true);
      };

      const navigateToReportPage = () => {
        navigate(encodeURI(`/reports`));
      };
      const ConfirmDialogContent = () => {
        return (
          <Paper elevation={0}>
            <Typography variant="body1" gutterBottom>
              Your file is exported as "{gen_file_name}.csv", to view the progress please visit
              report page below
            </Typography>
          </Paper>
        );
      };

      return (
        <Button
          label="Export"
          onClick={() => handleOnClick()}
          variant="contained"
          size="medium"
          style={lessThanSm ? exportButtonResponsivetStyle : exportButtonDefaultStyle}
        >
          <GetAppIcon />
          <Confirm
            isOpen={open}
            title="Dear User,"
            content={<ConfirmDialogContent />}
            onClose={() => setOpen(false)}
            confirm="Go To Report"
            onConfirm={navigateToReportPage}
          />
        </Button>
      );
    };

    return (
      <>
        <FilterButton filters={!isPartner && !isPartnerView ? listFilters : listFiltersPartner} />
        <ExportPurchaseLogButton />
      </>
    );
  };

  const firstDay = moment().subtract(1, 'days').startOf('day').toISOString();
  const lastDay = moment().endOf('day').toISOString();

  return (
    <>
      <FilterContextProv.Provider value={{ filterContext, setFilterContext }}>
        <List
          {...props}
          resource="statements"
          filterDefaultValues={{
            dateGte: firstDay,
            dateLte: lastDay,
            filterCredit: true,
            filterDebit: true,
          }}
          perPage={25}
          sort={{ field: 'created_at', order: 'desc' }}
          debounce={1200}
          filter={
            !isAdmin && !isAdminDispute && !isAdminStockIn && !isAdminStockOut && !isStaff
              ? { partner: userData?.account_id }
              : isAdminStockIn || isAdminStockOut
              ? { admin_stock: {} }
              : {}
          }
          exporter={false}
        >
          <CustomTopToolbar pageBadge={<StatementsIcon style={pageBadgeIcon} />}>
            <FilterForm filters={!isPartner && !isPartnerView ? listFilters : listFiltersPartner} />
            <ListActions />
          </CustomTopToolbar>

          <Datagrid bulkActionButtons={false}>
            <DateField source="created_at" data-test-id="field-created-at" label="Date" showTime />
            {!isPartner && (
              <TextField source="name" data-test-id="field-name" label="Account" sortable={false} />
            )}
            <TextField source="label" data-test-id="field-label" sortable={false} />
            <TextField
              source="deposit_reference_id"
              data-test-id="field-deposit-ref"
              label="Order Id"
              sortable={false}
            />
            <TextField
              source="partner_ref_id"
              data-test-id="field-partner-ref-id"
              label="Reference Id"
              sortable={false}
            />
            <TextField source="target" data-test-id="field-target" sortable={false} />
            <NumberField source="credit" data-test-id="field-credit" sortable={false} />
            <NumberField source="debit" data-test-id="field-debit" sortable={false} />
            <NumberField source="balance" data-test-id="field-balance" sortable={false} />
            <TextField source="remark" data-test-id="field-remark" sortable={false} />
          </Datagrid>
        </List>
      </FilterContextProv.Provider>
    </>
  );
};
