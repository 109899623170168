// @ts-nocheck
import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  usePermissions,
  LoadingPage,
  useNotify,
  FilterButton,
  SelectInput,
  Pagination,
  sanitizeListRestProps,
  Button,
  FunctionField,
  FileField,
  FilterForm,
  RaRecord,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import { useNavigate } from 'react-router-dom';
import ReactJson from 'react-json-view';
import { DescriptionRounded } from '@mui/icons-material';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import { pageBadgeIcon } from 'src/styles/global-styles';

const ListActions = (props) => {
  const { className, exporter, filters, isAdmin, maxResults, navigate, ...rest } = props;

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <FilterButton filters={ReportsFilter} />
    </TopToolbar>
  );
};

const ReportsFilter = [
  <SelectInput
    source="type"
    label="Type"
    data-test-id="filter-type"
    choices={[
      { id: 'transaction', name: 'Transaction' },
      { id: 'users', name: 'Users' },
      { id: 'statements', name: 'Statements' },
    ]}
    alwaysOn
    data-testid="search-status"
    variant="standard"
  />,
];

const ListPage = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  const [userData, setUserData] = React.useState(null);

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      console.log(res.data, 1234);

      setUserData(res.data);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };
  React.useEffect(() => {
    getUserData();
  }, []);

  const { isLoading, permissions } = usePermissions();

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const ReportsPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

  return !isLoading && userData ? (
    <List
      perPage={50}
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<ReportsPagination />}
      style={{
        width: '1000px',
      }}
      exporter={false}
    >
      <CustomTopToolbar pageBadge={<DescriptionRounded style={pageBadgeIcon} />}>
        {/* show filter input field when filter is selected from filter button */}
        <FilterForm filters={ReportsFilter} />
      </CustomTopToolbar>
      <Datagrid
        bulkActionButtons={false}
        style={{
          width: '1000px',
        }}
      >
        <DateField source="created_at" data-test-id="field-create" showTime />
        <TextField source="author_id_data.name" data-test-id="field-author" label="Author" />
        <TextField
          source="account_id_data.label"
          data-test-id="field-account"
          label="Account Label"
        />
        <TextField source="status" data-test-id="field-status" />
        <FunctionField
          label="Reason"
          data-test-id="field-reason"
          render={(record) => {
            if (record?.status === 'FAILED') {
              return <div>{record?.reason?.error}</div>;
            }
            return <div>-</div>;
          }}
        />
        <FunctionField
          label="File"
          data-test-id="field-file"
          render={(record: RaRecord) =>
            record.uri ? (
              <Button style={{ color: '#fff' }}>
                <FileField source="uri" title={record.uri.split('/').pop() + '.csv'} label="file" />
              </Button>
            ) : (
              <label>{record.status.toUpperCase()}</label>
            )
          }
        />
      </Datagrid>
    </List>
  ) : (
    <LoadingPage />
  );
};

export default ListPage;
