// @ts-nocheck
import { useInput } from 'react-admin';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumberFormatInput = ({ fullWidth, style, ...props }) => {
  const {
    field: { onChange },
    fieldState: { isTouched, error },
  } = useInput(props);

  return (
    <NumericFormat
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      type="tel"
      customInput={TextField}
      thousandSeparator
      style={style}
      error={!!(isTouched && error)}
    />
  );
};

export default NumberFormatInput;
