// @ts-nocheck
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import mbaas from "../provider/Mbaas";

export async function resetPassword(authData) {
  // const promptResult = await Swal.fire({
  //   title: "Please input your password",
  //   showCancelButton: true,
  //   html:
  //     '<h6 style="margin-bottom: -14px" >Password</h6>' +
  //     '<input  id="swal-input1" class="swal2-input" type="password">' +
  //     '<h6 style="margin-top: 5px; margin-bottom: -14px">Verify Password</h6>' +
  //     '<input id="swal-input2" class="swal2-input" type="password">',
  //   focusConfirm: false,
  //   preConfirm: () => {
  //     return [
  //       document.getElementById("swal-input1").value,
  //       document.getElementById("swal-input2").value,
  //     ];
  //   },
  // });
  // // return if canceled
  // if (!promptResult.value || promptResult.cancel) {
  //   return;
  // }
  // // warn if incorrect password
  // if (promptResult.value[0] !== promptResult.value[1]) {
  //   Swal.fire("Failed", "Password missmatch", "warning");
  //   return;
  // }
  // confirm button
  const isConfirmed = await Swal.fire({
    title:
      '<h4 style="margin-top: 5px; margin-bottom: 5px">Are you sure want to reset your password?</h4>',
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085D6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.value) return true;
    return false;
  });
  //
  if (!isConfirmed) return;
  // const answer = promptResult.value[0];

  const MySwal = withReactContent(Swal);

  await MySwal.fire({
    title:
      '<h5 style="margin-top: 5px; margin-bottom: 5px">Please Fill Form Below to Reset Your Password</h5>',
    // imageUrl: authQr.data,
    confirmButtonText: "Verify",
    html: (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {/* <label>Old Password</label> */}
        <input
          type="password"
          autoComplete="off"
          id="old_password"
          name="old_password"
          class="swal2-input"
          placeholder="Old Password"
        />
        {/* <label>New Password</label> */}
        <input
          type="password"
          id="new_password"
          name="new_password"
          class="swal2-input"
          placeholder="New Password"
        />
        {/* <label>Confirm Password</label> */}
        <input
          type="password"
          id="confirm_password"
          name="confirm_password"
          class="swal2-input"
          placeholder="Confirm Password"
        />

        {!authData.roles.includes("Partner View") && (
          <input
            type="number"
            id="totp"
            name="totp"
            class="swal2-input"
            placeholder="TOTP"
          />
        )}
      </div>
    ),
    preConfirm: async () => {
      if (authData.roles.includes("Partner View")) {
        const old_password =
          Swal.getPopup().querySelector("#old_password").value;
        const new_password =
          Swal.getPopup().querySelector("#new_password").value;
        const confirm_password =
          Swal.getPopup().querySelector("#confirm_password").value;

        if (!old_password) {
          Swal.showValidationMessage(`Please enter your old password`);
        } else if (!new_password) {
          Swal.showValidationMessage(`Please enter new password`);
        } else if (!confirm_password) {
          Swal.showValidationMessage(`Please enter new password again`);
        } else if (new_password !== confirm_password) {
          Swal.showValidationMessage(`Incorrect new password`);
        }
        const { data, error } = await mbaas.client.user.update("password", {
          oldPassword: old_password,
          newPassword: confirm_password,
        });

        if (error) {
          return { error: error?.title };
        } else {
          return {
            data: data,
          };
        }
      } else {
        const old_password =
          Swal.getPopup().querySelector("#old_password").value;
        const new_password =
          Swal.getPopup().querySelector("#new_password").value;
        const confirm_password =
          Swal.getPopup().querySelector("#confirm_password").value;
        const totp = Swal.getPopup().querySelector("#totp").value;
        if (!old_password) {
          Swal.showValidationMessage(`Please enter your old password`);
        } else if (!new_password) {
          Swal.showValidationMessage(`Please enter new password`);
        } else if (!confirm_password) {
          Swal.showValidationMessage(`Please enter new password again`);
        } else if (new_password !== confirm_password) {
          Swal.showValidationMessage(`Incorrect new password`);
        } else if (!totp || totp.length < 6) {
          Swal.showValidationMessage(`Invalid TOTP`);
        }

        const { data, error } = await mbaas.client.invokeApi("totp", {
          action: "VALIDATE",
          token: totp,
        });

        console.log("ERROR-TOTP", error?.error);

        if (data === "OK") {
          const { data, error } = await mbaas.client.user.update("password", {
            oldPassword: old_password,
            newPassword: confirm_password,
          });

          if (error) {
            return { error: error?.title };
          } else {
            return {
              data: data,
            };
          }
        } else {
          Swal.showValidationMessage(`Invalid TOTP`);
        }
      }
    },
  }).then((result) => {
    if (result.value?.error) {
      Swal.fire(
        `
				${result.value?.error}
			`.trim()
      );
    } else if (result.value?.data) {
      Swal.fire({
        title: '<h4 style="margin-top: 5px; margin-bottom: 5px">Success</h4>',
        text: "Your password was successfully updated.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085D6",
        confirmButtonText: "Finish",
      });
    }
  });
}
