// @ts-nocheck
import { TopToolbar } from 'react-admin';
import { Stack, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  topToolbarStyle,
  stackDefault,
  stackResponsive,
  paperBadgeStyle,
} from 'src/styles/global-styles';

export default function CustomTopToolbar({ children, pageBadge }) {
  const isBreak = useMediaQuery('(max-width:1441px)');

  return (
    <>
      <TopToolbar sx={topToolbarStyle} id="custom-top-toolbar">
        <Stack direction="row" spacing={3} sx={isBreak ? stackResponsive : stackDefault}>
          {/* children */}
          {children}
        </Stack>
      </TopToolbar>
      <Paper sx={paperBadgeStyle} elevation={5}>
        {/* pageBadge here */}
        {pageBadge}
      </Paper>
    </>
  );
}
