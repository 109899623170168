import Mbaas from 'src/provider/Mbaas';

export default async function blockAccount(accountId: string, isBlocked: boolean) {
  const res = await Mbaas.client.getTable('accounts').update(
    Object.assign({
      id: accountId,
      data: {
        is_blocked: isBlocked,
      },
    })
  );

  return res;
}
