import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { GetOneParams, GetOneResult } from "react-admin";
import { ProviderBase } from "./ProviderBase";

export class DashboardProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async getOne(resource: string, params: GetOneParams): Promise<GetOneResult> {
    const { data: userData } = await this.client.user.get();
    if (!userData) return { data: { metabaseUrl: null } };
    let dealers = {};
    if (userData.roles.includes("Partner")) {
      const { getUsersById } = await this.client.gql.query({
        query: `
              query getAccountData($id: UUID) {
                  getUsersById(id: $id) {
                    account_id_data{
                      label
                    }
                  }
                }
              `,
        variables: {
          id: userData.id,
        },
      });
      dealers = {
        choose_dealer: [getUsersById.account_id_data.label],
      };
    }
    const jwt = require("jsonwebtoken");
    const METABASE_SITE_URL = "https://analytics.telin.com.my";
    const METABASE_SECRET_KEY =
      "61ae503ce4d3aef83d79513467bf5308280a3c6375c1446c8037b6c29b8bdabf";
    const payload = {
      resource: { dashboard: params.id },
      params: dealers,
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };
    const token = jwt.sign(payload, METABASE_SECRET_KEY);

    const iframeUrl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=false&titled=false";
    return { data: { id: userData.id, metabaseUrl: iframeUrl } };
  }
}
