import * as React from 'react';
import MuiButton from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import {
  Confirm,
  usePermissions,
  useRedirect,
  useRefresh,
  RaRecord,
  SelectInput,
  Button,
  TextInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  SimpleForm,
  Edit,
  useGetOne,
  Toolbar,
  useUpdate,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import Swal from 'sweetalert2';
import { Box, Typography } from '@mui/material';
import IpWhiteListField from './IpWhitelistField';
import GenerateKeys from './GenerateKey';
import GenerateApiPassword from './GenerateApiPassword';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const MyToolbar = ({ onSubmitHandler }: { onSubmitHandler: any }) => (
  <Toolbar>
    <MuiButton onClick={onSubmitHandler} variant="contained">
      Save
    </MuiButton>
  </Toolbar>
);

export const ShowDownlineDialog = ({
  open,
  handleClose,
  downlineId,
  onCancel,
  ...props
}: {
  open: boolean;
  handleClose: any;
  onCancel: any;
  downlineId: string;
}) => {
  const {
    data: accountData,
    isLoading: isLoadingUseGetOne,
    error,
  } = useGetOne('accounts', { id: downlineId });
  const [update] = useUpdate();
  const [formData, setFormData] = React.useState<any>(null);
  const [otpCode, setOtpCode] = React.useState('');
  const [cOpen, setCOpen] = React.useState(false);

  React.useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [onCancel]);

  const { isLoading, permissions } = usePermissions();
  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');
  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleConfirm = async () => {
    try {
      const { data, error } = await mbaas.client.invokeApi('totp', {
        action: 'VALIDATE',
        token: otpCode,
      });
      if (data === 'OK') {
        update(
          'accounts',
          {
            id: formData?.id,
            data: formData,
            previousData: { id: formData?.id, ...accountData },
          },
          {
            onSuccess: () => {
              refresh();
            },
          }
        );
        setOtpCode('');
        setCOpen(false);
        refresh();
      } else {
        setOtpCode('');
        setCOpen(false);
        Swal.fire(error?.error, '', 'error');
      }
    } catch (e: any) {
      Swal.fire(e?.error, e, 'error');
    }
  };

  console.log(88312, 'formData', formData);

  const onSubmitHandler = () => {
    setCOpen(true);
  };

  return (
    <>
      <Confirm
        isOpen={cOpen}
        title="Submit OTP 2FA to Confirm Action"
        content={
          <TextField
            type="number"
            fullWidth
            data-test-id="input-otp"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        }
        onConfirm={handleConfirm}
        onClose={() => setCOpen(false)}
      />
      <Edit id={downlineId}>
        <Box
          pt={5}
          width={{ xs: '100vW', sm: 600 }}
          mt={{ xs: 2, sm: 1 }}
          ml={{ xs: 2, sm: 1 }}
          mr={{ xs: 2, sm: 1 }}
          minHeight="100vh"
        >
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="h5">Downline Details</Typography>

            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <SimpleForm toolbar={<MyToolbar onSubmitHandler={onSubmitHandler} />}>
            <Box flex={2}>
              <TextInput
                data-test-id="input-label"
                source="label"
                isRequired
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />
              <TextInput
                data-test-id="input-contact"
                source="contact"
                isRequired
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />
              <SelectInput
                data-test-id="select-api-ver"
                source="api_version"
                label="API Version"
                choices={[
                  { id: 'v1', value: 'v1' },
                  { id: 'v2', value: 'v2' },
                ]}
                optionText={(record: RaRecord) => record.value}
                fullWidth
                disabled={permissions.includes('Staff') ? true : false}
              />

              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setFormData(formData);
                  return formData?.api_version === 'v2' ? (
                    <>
                      <TextInput
                        source="private_key_signature"
                        label="Private key Signature"
                        data-test-id="private-key-sign"
                        disabled
                        multiline
                        fullWidth
                      />
                      <TextInput
                        source="public_key"
                        data-test-id="public-key"
                        disabled
                        multiline
                        fullWidth
                      />

                      {isPartner && (
                        <GenerateKeys data-test-id="generate-api-pass" refresh={refresh} />
                      )}
                    </>
                  ) : (
                    <GenerateApiPassword
                      data-test-id="generate-api-pass"
                      refresh={refresh}
                      isPartner={isPartner}
                      source="api_password"
                    />
                  );
                }}
              </FormDataConsumer>

              <div style={{ marginTop: '17px', marginBottom: '17px' }}>
                <IpWhiteListField
                  source="whitelist_ips"
                  isPartner={isPartner}
                  isAdmin={isAdmin}
                  label="Ip Whitelist"
                />
              </div>

              <ArrayInput source="webhook_configuration" fullWidth>
                <SimpleFormIterator
                  addButton={<Button label="Add" startIcon={<AddCircleOutlineIcon />} />}
                  disableReordering
                  inline
                  disableAdd={!isPartner && true}
                  disableRemove={permissions.includes('Staff') ? true : false}
                >
                  <TextInput
                    source="url"
                    label="URL"
                    data-test-id="input-url"
                    style={{
                      width: '500px',
                    }}
                    disabled={permissions.includes('Staff') ? true : false}
                  />
                  <SelectArrayInput
                    source="status"
                    data-test-id="select-status"
                    choices={[
                      { id: 'QUOTE', name: 'QUOTE' },
                      { id: 'PENDING', name: 'PENDING' },
                      { id: 'SUCCESS', name: 'SUCCESS' },
                      { id: 'FAILED', name: 'FAILED' },
                    ]}
                    style={{
                      width: '200px',
                    }}
                    disabled={permissions.includes('Staff') ? true : false}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </SimpleForm>
        </Box>
      </Edit>
    </>
  );
};
