import { memo, useEffect, useState, Fragment } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AppBar,
  Layout,
  Logout,
  UserMenu,
  LayoutProps,
  useAuthProvider,
  useNotify,
  usePermissions,
} from 'react-admin';
import Menu from './Menu';
import { Divider, MenuItem, ListItemIcon, Typography } from '@mui/material';
import TwoFaModal from '../component/TwoFaModal';
import { manageProfile } from '../services/manage_profile';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const ManageProfile = () => {
  const authP = useAuthProvider();
  return (
    <MenuItem onClick={() => manageProfile(authP.data())}>
      <ListItemIcon style={{ minWidth: '40px' }}>
        <AccountBoxIcon />
      </ListItemIcon>
      Manage Profile
    </MenuItem>
  );
};

const MyUserMenu = () => (
  <UserMenu>
    <div style={{ margin: '10px 15px', fontSize: '12px' }}>{localStorage.getItem('username')}</div>
    <Divider style={{ margin: '0px 15px' }} />
    <Logout />
    <ManageProfile />
  </UserMenu>
);

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
}));

const MyAppBar = memo((props) => {
  const [open, setOpen] = useState(false);
  const authP = useAuthProvider();
  const [isHaveMfa, setIsHaveMfa] = useState(false);
  // const [qrCode, setQrCode] = useState("");
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const notify = useNotify();
  // const email = localStorage.getItem("username");
  const classes = useStyles();

  const location = useLocation();

  const { isLoading, permissions } = usePermissions();
  const executeFunction = !isLoading && permissions.find((el: string) => el === 'Administrator');

  useEffect(() => {
    if (executeFunction) {
      checkUserHaveMfa();
    }
  }, [executeFunction]);

  const checkUserHaveMfa = async () => {
    try {
      const userData = await authP.data();
      if (userData) {
        if (!userData.mfa) {
          setIsHaveMfa(false);
          handleOpen();
        } else {
          setIsHaveMfa(true);
        }
      }
    } catch (error) {
      notify(error as string);
    }
  };

  return location.pathname === '/' ? (
    <>
      <AppBar
        {...props}
        userMenu={<MyUserMenu />}
        elevation={1}
        container={Fragment}
        sx={{
          backgroundColor: '#626ed4',
        }}
      >
        <Typography variant="h6" color="inherit" className={classes.title}>
          Dashboard
        </Typography>
      </AppBar>
      {!isHaveMfa &&
      !isLoading &&
      (permissions.includes('Administrator') || permissions.includes('Partner')) ? (
        <TwoFaModal open={open} setOpen={setOpen} />
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <AppBar
        {...props}
        userMenu={<MyUserMenu />}
        elevation={1}
        container={Fragment}
        sx={{
          backgroundColor: '#626ed4',
        }}
      ></AppBar>
      {!isHaveMfa &&
      !isLoading &&
      (permissions.includes('Administrator') || permissions.includes('Partner')) ? (
        <TwoFaModal open={open} setOpen={setOpen} />
      ) : (
        <></>
      )}
    </>
  );
});

const CustomLayout = (props: LayoutProps) => (
  <>
    <Layout {...props} appBar={MyAppBar} menu={Menu} />
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ style: { width: 20, height: 30 } }}
    />
  </>
);

export default CustomLayout;
