import React, { Children, ReactNode, useState, useEffect } from 'react';
import { TableCell, TableRow } from '@mui/material';
import numeral from 'numeral';
import mbaas from '../../provider/Mbaas';

export default function RowPartnerTotalBalance({ children }: { children: any }) {
  const [totalBalanceDealer, setTotalBalanceDealer] = useState([{}]);

  const dealerTotalBalance = async () => {
    const { data: userData } = await mbaas.client.user.get();
    const balanceList = await mbaas.client.invokeApi('balance-positions', {
      account_id: userData?.account_id,
    });

    var totalBalanceDealer = balanceList.data.totalDealer.reduce(
      (obj: any, item: any) => ((obj[item.label] = item.value), obj),
      {}
    );

    setTotalBalanceDealer(totalBalanceDealer);
  };

  useEffect(() => {
    dealerTotalBalance();
  }, []);

  return (
    <TableRow>
      {Children.map(children, (child: any, index: any) => {
        return (
          <TableCell
            key={child.props.label}
            align={index === 0 ? 'left' : 'right'}
            data-test-id="row-total-balance"
            style={{
              fontWeight: 'bold',
            }}
          >
            {`${
              index === 0
                ? 'Total'
                : numeral(totalBalanceDealer[child.props.label]).format('0,0') || '0'
            }`}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
