import Box from '@mui/material/Box';
import {
  ManageAccounts as AccountIcon,
  MonetizationOn as TransactionsIcon,
  Check as StatementsIcon,
  ListAlt as ProductIcon,
  SyncAlt as StockManagementsIcon,
  Warehouse as SupplierCatalogIcon,
  AccountBalanceWallet as BalanceIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  usePermissions,
  Sidebar,
  useSidebarState,
} from 'react-admin';
import {
  DescriptionRounded,
  SupervisedUserCircle,
  Search,
  MultipleStop,
} from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { matchPath, useLocation } from 'react-router-dom';

const Menu = ({ dense = false }: MenuProps) => {
  const [open] = useSidebarState();
  const location = useLocation();

  const { isLoading, permissions } = usePermissions();
  if (!isLoading) {
    console.log(permissions);
  }

  function matchCurrentPath(path: string) {
    if (matchPath(path, location.pathname)) {
      return '#2B2D3B';
    }
    return '#333547';
  }

  const isAdmin = !isLoading && permissions.find((el: string) => el === 'Administrator');

  const isAdminStockOut = !isLoading && permissions.find((el: string) => el === 'Admin Stock-Out');

  const isAdminStockIn = !isLoading && permissions.find((el: string) => el === 'Admin Stock-In');

  const isAdminDispute = !isLoading && permissions.find((el: string) => el === 'Admin Dispute');

  const isStaff = !isLoading && permissions.find((el: string) => el === 'Staff');

  const isPartnerView = !isLoading && permissions.find((el: string) => el === 'Partner View');

  const isPartnerTopUp = !isLoading && permissions.find((el: string) => el === 'Partner Top-Up');

  return (
    <Sidebar
      sx={{
        '& .RaSidebar-fixed': {
          backgroundColor: '#333547',
          top: '48px',
          position: 'fixed',
        },
        '& .MuiDrawer-paper': {
          backgroundColor: '#333547',
        },
      }}
    >
      <Box
        sx={{
          width: open ? 260 : 50,
          marginTop: 3,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Stack spacing={2}>
          {!isPartnerView && !isPartnerTopUp ? (
            <DashboardMenuItem
              leftIcon={
                <DashboardIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}

          {isAdmin || isStaff ? (
            <MenuItemLink
              to="/users"
              state={{ _scrollToTop: true }}
              primaryText="Users"
              leftIcon={
                <AccountIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="users"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/users'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          {!isAdminStockIn && !isAdminStockOut && !isPartnerView && !isPartnerTopUp ? (
            <MenuItemLink
              to="/accounts"
              state={{ _scrollToTop: true }}
              primaryText="Accounts"
              leftIcon={
                <SupervisedUserCircle
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="accounts"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/accounts'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          <MenuItemLink
            to="/current-balance"
            state={{ _scrollToTop: true }}
            primaryText="Balance"
            leftIcon={
              <BalanceIcon
                style={{
                  color: '#e9ecef',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            resource="current-balance"
            dense={dense}
            style={{
              color: '#e9ecef',
              fontSize: '.929rem',
              fontWeight: '400',
              lineHeight: '1rem',
              backgroundColor: matchCurrentPath('/current-balance'),
              textDecoration: 'none',
            }}
          />
          {!isAdminStockIn && !isAdminStockOut && !isPartnerTopUp ? (
            <MenuItemLink
              to="/transactions"
              state={{ _scrollToTop: true }}
              primaryText="Transactions"
              leftIcon={
                <TransactionsIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="transactions"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/transactions'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          {!isPartnerTopUp ? (
            <MenuItemLink
              to="/check-transactions"
              state={{ _scrollToTop: true }}
              primaryText="Check Transactions"
              leftIcon={
                <Search
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="check-transactions"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/check-transactions'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}

          {isAdmin || isAdminDispute ? (
            <MenuItemLink
              to="/dispute"
              state={{ _scrollToTop: true }}
              primaryText="Dispute"
              leftIcon={
                <MultipleStop
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="dispute"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/dispute'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          {!isPartnerTopUp ? (
            <MenuItemLink
              to="/statements"
              state={{ _scrollToTop: true }}
              primaryText="Statements"
              leftIcon={
                <StatementsIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="statements"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/statements'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}

          <MenuItemLink
            to="/stock-managements"
            state={{ _scrollToTop: true }}
            primaryText={
              isAdmin ||
              isAdminDispute ||
              isAdminStockIn ||
              isAdminStockOut ||
              isStaff ||
              isPartnerTopUp
                ? 'Stock Managements'
                : 'Transfer Log'
            }
            resource="stock-managements"
            leftIcon={
              <StockManagementsIcon
                style={{
                  color: '#e9ecef',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            dense={dense}
            style={{
              color: '#e9ecef',
              fontSize: '.929rem',
              fontWeight: '400',
              lineHeight: '1rem',
              backgroundColor: matchCurrentPath('/stock-managements'),
              textDecoration: 'none',
            }}
          />
          {isAdmin || isAdminDispute || isStaff ? (
            <MenuItemLink
              to="/product-catalogs"
              state={{ _scrollToTop: true }}
              primaryText="Product Catalogs"
              leftIcon={
                <ProductIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="product-catalogs"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/product-catalogs'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          {isAdmin ? (
            <MenuItemLink
              to="/configuration-page"
              state={{ _scrollToTop: true }}
              primaryText="Configuration"
              leftIcon={
                <SettingsIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="configuration-page"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/configuration'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
          {isAdmin || isAdminDispute || isStaff ? (
            <MenuItemLink
              to="/supplier-catalog"
              state={{ _scrollToTop: true }}
              primaryText="Supplier Catalog"
              resource="supplier-catalog"
              leftIcon={
                <SupplierCatalogIcon
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/supplier-catalog'),
                textDecoration: 'none',
              }}
              dense={dense}
            />
          ) : (
            <></>
          )}
          {!isAdminStockIn && !isAdminStockOut && !isPartnerTopUp ? (
            <MenuItemLink
              to="/reports"
              state={{ _scrollToTop: true }}
              primaryText="Reports"
              leftIcon={
                <DescriptionRounded
                  style={{
                    color: '#e9ecef',
                    height: '20px',
                    width: '20px',
                  }}
                />
              }
              resource="reports"
              dense={dense}
              style={{
                color: '#e9ecef',
                fontSize: '.929rem',
                fontWeight: '400',
                lineHeight: '1rem',
                backgroundColor: matchCurrentPath('/reports'),
                textDecoration: 'none',
              }}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    </Sidebar>
  );
};

export default Menu;
