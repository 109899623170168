import MobileServiceClient from "@mocobaas/client-js/build/MobileServiceClient";
import { UpdateParams, UpdateResult } from "react-admin";
import { ProviderBase } from "./ProviderBase";
export class AccountsApiPasswordUpdateProviderClass extends ProviderBase<any> {
  client: MobileServiceClient;

  constructor(client: MobileServiceClient) {
    super();
    this.client = client;
  }

  async update(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<any>> {
    let updatedData = params.data;

    const { data } = await this.client.getTable("accounts").update(
      Object.assign({
        id: params.id,
        data: {
          api_password: updatedData.api_password,
        },
      })
    );
    return { data: data } as UpdateResult;
  }
}
