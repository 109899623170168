// @ts-nocheck
import { useState, useEffect } from 'react';
import { dataProvider } from '../../provider';
import MuiTextField from '@mui/material/TextField';
import MuiButton from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress } from '@mui/material';
import { Empty } from './EmptyPageBanner';
import Paper from '@mui/material/Paper';
import CustomPaginationActionsTable from './searchByTargetResult';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import { Title } from 'react-admin';
import { pageBadgeIcon } from 'src/styles/global-styles';

export const CheckTransactionListPage = () => {
  const [referenceId, setReferenceId] = useState('');
  const [target, setTarget] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultTotal, setSearchResultTotal] = useState(0);
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  async function handleSearch() {
    if (target.length > 0) {
      setLoading(true);
      setPage(0);
      setRowsPerPage(5);
      setSearchResult([]);

      const { data: listSearchByTarget, total: totalResult } = await dataProvider.getList(
        'check-transactions',
        {
          filter: {
            target: target,
          },
          pagination: { page: 0, perPage: 5 },
          sort: {
            field: 'created_at',
            order: 'DESC',
          },
        }
      );

      if (listSearchByTarget) {
        setSearchResult(listSearchByTarget);
        setSearchResultTotal(totalResult);
        setLoading(false);
        setFirstRender(false);
      }
    }

    if (referenceId.length > 0) {
      setLoading(true);
      setSearchResult([]);

      const { data } = await dataProvider.getOne('check-transactions', {
        id: referenceId,
      });
      if (data) {
        setSearchResult(data);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    async function fetchData(page, rowsPerPage, target, referenceId) {
      const { data: listSearchByTarget, total: totalResult } = await dataProvider.getList(
        'check-transactions',
        {
          filter: {
            target: target,
          },
          pagination: { page: page, perPage: rowsPerPage },
          sort: {
            field: 'created_at',
            order: 'DESC',
          },
        }
      );

      if (listSearchByTarget) {
        setSearchResult(listSearchByTarget);
        setSearchResultTotal(totalResult);
      }
    }

    if (!firstRender && target.length > 0) {
      fetchData(page, rowsPerPage, target);
    }
  }, [page, rowsPerPage, firstRender]);

  // reset searchResult when search field is empty to prevent re-fetching data
  useEffect(() => {
    setSearchResult([]);
    setSearchResultTotal(0);
    setFirstRender(true);
  }, [target.length === 0 && referenceId.length === 0]);

  return (
    <>
      <Title title="Check Transactions" />
      <Paper sx={{ marginTop: '64px' }}>
        <CustomTopToolbar>
          <Paper
            sx={{
              height: '75px',
              width: '75px',
              backgroundColor: '#02a499',
              position: 'absolute',
              top: '-31px',
              left: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style={{ zIndex: '2' }}
            elevation={5}
          >
            <SearchIcon style={pageBadgeIcon} />
          </Paper>
          <div>
            <MuiTextField
              style={{
                width: '375px',
              }}
              id="search-reference-id"
              label="Reference Id"
              value={referenceId}
              variant="standard"
              onChange={(e) => setReferenceId(e.target.value)}
            />
          </div>
          <div>
            <MuiTextField
              style={{
                width: '200px',
              }}
              id="search-target"
              label="Target"
              value={target}
              variant="standard"
              type="number"
              onChange={(e) => setTarget(e.target.value)}
            />
          </div>
          <div>
            <MuiButton
              disabled={referenceId.length === 0 && target.length === 0}
              variant="contained"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              style={{
                color: '#fff',
                backgroundColor:
                  referenceId.length === 0 && target.length === 0 ? '#e0e0e0' : '#02a499',
              }}
            >
              {loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Search'}
            </MuiButton>
          </div>
        </CustomTopToolbar>
      </Paper>

      {searchResult?.length > 1 && target.length > 0 ? (
        <CustomPaginationActionsTable
          searchResult={searchResult}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          searchResultTotal={searchResultTotal}
        />
      ) : searchResult?.length === 1 && referenceId.length > 0 ? (
        <div>
          <div style={{ marginTop: '27px' }}>
            <Card sx={{ maxWidth: 725 }}>
              <CardContent>
                <table
                  style={{
                    width: '150%',
                    lineHeight: '50px',
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <strong>Reference ID</strong>
                      </td>
                      <td>{searchResult[0]?.reference_id}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Account Name</strong>
                      </td>
                      <td>{searchResult[0]?.account_label}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Status</strong>
                      </td>
                      <td>{searchResult[0]?.status}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Product</strong>
                      </td>
                      <td>{searchResult[0]?.product_label}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Price</strong>
                      </td>
                      <td>{searchResult[0]?.price}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Target</strong>
                      </td>
                      <td>{searchResult[0]?.target}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Store ID</strong>
                      </td>
                      <td>{searchResult[0]?.metadata?.store_id ?? "-"}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Delivery Channel</strong>
                      </td>
                      <td>{searchResult[0]?.metadata?.delivery_channel ?? "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};
