// @ts-nocheck
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import mbaas from "../provider/Mbaas";
import QRCodeView from "qrcode.react";

export async function resetAuthenticator() {
	// const promptResult = await Swal.fire({
	//   title: "Please input your password",
	//   showCancelButton: true,
	//   html:
	//     '<h6 style="margin-bottom: -14px" >Password</h6>' +
	//     '<input  id="swal-input1" class="swal2-input" type="password">' +
	//     '<h6 style="margin-top: 5px; margin-bottom: -14px">Verify Password</h6>' +
	//     '<input id="swal-input2" class="swal2-input" type="password">',
	//   focusConfirm: false,
	//   preConfirm: () => {
	//     return [
	//       document.getElementById("swal-input1").value,
	//       document.getElementById("swal-input2").value,
	//     ];
	//   },
	// });
	// // return if canceled
	// if (!promptResult.value || promptResult.cancel) {
	//   return;
	// }
	// // warn if incorrect password
	// if (promptResult.value[0] !== promptResult.value[1]) {
	//   Swal.fire("Failed", "Password missmatch", "warning");
	//   return;
	// }
	// confirm button
	const isConfirmed = await Swal.fire({
		title:
			'<h4 style="margin-top: 5px; margin-bottom: 5px">Are you sure want to reset your otp?</h4>',
		text:
			"By clicking 'YES', your existing configuration in google authenticator will no longer valid!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085D6",
		cancelButtonColor: "#d33",
		confirmButtonText: "Yes",
	}).then((result) => {
		if (result.value) return true;
		return false;
	});
	//
	if (!isConfirmed) return;
	// const answer = promptResult.value[0];
	const authQr = await mbaas.client.invokeApi("totp", {
		action: "GENERATE",
	});
	const MySwal = withReactContent(Swal);
	console.log(44345, authQr);
	if (authQr === null) {
		console.log(44345, "halo");
		Swal.fire("Failed", "Invalid Credential. Please try again", "error");
	} else if (authQr) {
		console.log(44345, "hai");
		const result = await MySwal.fire({
			title:
				'<h5 style="margin-top: 5px; margin-bottom: 5px">Please scan with your Google Authenticator App!</h5>',
			// imageUrl: authQr.data,
			confirmButtonText: "Verify",
			html: (
				<>
					<Grid
						container
						direction="column"
						alignItems="center"
						alignContent="center"
						style={{ marginTop: "20px" }}
					>
						{<QRCodeView size={200} value={authQr?.data} />}
					</Grid>
					<h6 style={{ marginTop: "15px", marginBottom: "-14px" }}>
						Verify your otp
					</h6>
					<input id="swal-input1" class="swal2-input"></input>
				</>
			),
			preConfirm: () => {
				return [document.getElementById("swal-input1").value];
			},
		});
		const { data, error } = await mbaas.client.invokeApi("totp", {
			action: "VALIDATE",
			token: result.value[0],
		});
		console.log(44345, result.value[0]);
		console.log(44345, { data, error });
		if (data) {
			Swal.fire("Success", "Valid Totp", "success");
		}
		if (error) {
			Swal.fire("Failed", "Invalid Totp. Please try again", "error");
		}
	}
}
