// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  Toolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Button,
  required,
  Edit,
  BooleanInput,
  useRecordContext,
} from "react-admin";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import ProductPricing from "./ProductPricing";
import ProductRouting from "./ProductRouting";

const Title = () => {
  const record = useRecordContext();
  return (
    <span data-test-id="edit-title">
      &rsaquo; Detail {record ? record?.id : ""}
    </span>
  );
};

const useStylesX = makeStyles({
  input: {
    marginBottom: "0px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const DetailPage = ({ onCancel, match, ...props }) => {
  const classes = useStylesX();
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [onCancel]);

  const HiddenInput = () => {
    return <></>;
  };

  const [enableEdit, setEnableEdit] = useState(false);
  const CustomToolbar = ({ onCancel }) => {
    return (
      <Toolbar>
        <Grid item xs={12}>
          <SaveButton
            resource="product-catalogs"
            alwaysEnable
            style={{ color: "#fff" }}
            onClick={async (e) => {
              if (enableEdit) {
              } else {
                setEnableEdit(!enableEdit);
              }
            }}
            data-test-id="edit"
            label={!enableEdit ? "Enable Editing" : "Save"}
          />
          {enableEdit && (
            <Button
              variant="contained"
              color="error"
              style={{
                minWidth: "64px",
                padding: "6px 16px",
                borderRadius: "4px",
                lineHeight: "1.75",
                marginLeft: "14px",
              }}
              label="ra.action.cancel"
              onClick={() => {
                setEnableEdit(false);
              }}
            >
              <CloseIcon />
            </Button>
          )}
        </Grid>
      </Toolbar>
    );
  };

  return (
    <Show resource="product-catalogs" title={<Title />} {...props}>
      <Box
        pt={5}
        width={{ xs: "100vW", sm: 600 }}
        style={{ paddingBlockStart: 0 }}
      >
        <Stack
          direction="row"
          p={0}
          sx={{ position: "absolute", right: 0, zIndex: 100 }}
        >
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <TabbedShowLayout>
          <Tab label="detail">
            <Grid container spacing={0}>
              <Edit actions={false}>
                <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} />}>
                  <div className={classes.root}>
                    <Grid container>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <HiddenInput />
                          <TextInput
                            disabled={!enableEdit}
                            fullWidth
                            className={classes.input}
                            label="Item Key"
                            source="item_key"
                            data-test-id="input-item-key"
                            validate={[required()]}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextInput
                            disabled={!enableEdit}
                            fullWidth
                            className={classes.input}
                            label="Label"
                            source="label"
                            data-test-id="input-label"
                            validate={[required()]}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextInput
                            disabled={!enableEdit}
                            fullWidth
                            className={classes.input}
                            label="Description"
                            source="description"
                            data-test-id="input-description"
                            validate={[required()]}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <SelectInput
                            disabled={!enableEdit}
                            source="stock_type"
                            data-test-id="input-stock-type"
                            className={classes.input}
                            choices={[
                              { id: "BULK", name: "BULK" },
                              { id: "FIXED", name: "FIXED" },
                              { id: "BULK/FIX", name: "BULK/FIX" },
                            ]}
                            fullWidth
                            validate={required()}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <BooleanInput
                            disabled={!enableEdit}
                            source="is_available"
                            label="Available"
                            data-test-id="input-status"
                            className={classes.input}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <HiddenInput />
                          <TextInput
                            disabled={!enableEdit}
                            fullWidth
                            className={classes.input}
                            label="TOTP"
                            source="totp"
                            data-test-id="input-totp"
                            validate={[required()]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </SimpleForm>
              </Edit>
            </Grid>
          </Tab>
          <Tab label="Pricing" data-test-id="tab-pricing">
            <ProductPricing />
          </Tab>
          <Tab label="Routing" data-test-id="tab-routing">
            <ProductRouting />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
