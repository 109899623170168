import { Grid } from "@mui/material";
import { useState } from "react";
import {
  Button,
  Confirm,
  TextInput,
  useInput,
  useRecordContext,
  useUpdate,
} from "react-admin";
import KeyIcon from "@mui/icons-material/Key";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GenerateApiPassword = (props: any) => {
  const [open, setOpen] = useState(false);
  const [openApiPass, setOpenApiPass] = useState(false);
  const [apiPass, setApiPass] = useState("");
  const [copy, setCopy] = useState("Copy");
  const [update] = useUpdate();
  const record = useRecordContext();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  function createApiPass(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const apiPassword = useInput(props);

  const ApiPasswordGen = () => {
    const api_pass = createApiPass(16);
    setApiPass(api_pass);
    const base64Api_pass = window.btoa(api_pass);
    console.log(base64Api_pass);

    apiPassword.field.onChange(base64Api_pass);

    console.log("RAW--PASS", api_pass);
    console.log("B64--PASS", base64Api_pass);
    console.log("DEC--PASS", window.atob(base64Api_pass));
    saveKey(base64Api_pass);
    setOpen(false);
    setOpenApiPass(true);
  };

  const saveKey = (api_password: string) => {
    update(
      "accounts_api_password_update",
      {
        id: record?.id,
        data: {
          api_password: api_password,
        },
        previousData: record,
      },
      {
        onSettled() {
          props?.refresh();
        },
      }
    );
  };

  const handleConfirmApiPass = () => {
    setOpenApiPass(false);
  };

  const handleDialogCloseApiPass = () => {
    navigator.clipboard.writeText(apiPass);
    setCopy("Copied");
    setTimeout(() => setCopy("Copy"), 1000);
  };

  return (
    <>
      <Grid item xs={12}>
        <TextInput {...props} fullWidth disabled />
      </Grid>
      {props?.isPartner && (
        <Grid
          item
          style={{
            marginTop: -23,
          }}
        >
          <Button
            fullWidth
            startIcon={<KeyIcon />}
            size="medium"
            variant="contained"
            onClick={handleClick}
            label="Generate"
          />
        </Grid>
      )}
      <Confirm
        isOpen={open}
        title="Generate API Password"
        content="Are you sure you want to generate API Password?"
        onConfirm={ApiPasswordGen}
        onClose={handleDialogClose}
        CancelIcon={CancelOutlinedIcon}
      />
      <Confirm
        cancel={copy}
        confirm="ok"
        isOpen={openApiPass}
        title="Generate API Password"
        content={<label>{apiPass}</label>}
        onConfirm={handleConfirmApiPass}
        onClose={handleDialogCloseApiPass}
        CancelIcon={ContentCopyIcon}
      />
    </>
  );
};

export default GenerateApiPassword;
