// @ts-nocheck
import { Children, useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  FilterForm,
  ExportButton,
  FunctionField,
} from 'react-admin';
import mbaas from '../../provider/Mbaas';
import { TableCell, TableHead, TableRow } from '@mui/material';
import numeral from 'numeral';
import { AccountBalanceWallet as BalanceIcon } from '@mui/icons-material';
import CustomTopToolbar from 'src/component/CustomTopToolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  pageBadgeIcon,
  exportButtonDefaultStyle,
  exportButtonResponsivetStyle,
} from 'src/styles/global-styles';
import RowPartnerTotalBalance from './rowPartnerTotalBalance';

const listFilters = [
  <ReferenceInput label="User" source="account_id" reference="accounts" alwaysOn>
    <AutocompleteInput
      data-test-id="filter-account"
      source="label"
      optionValue="id"
      optionText={(choice) => choice.label}
      sx={{ width: '250px' }}
      variant="standard"
    />
  </ReferenceInput>,
];

const listFilterPartner = [];

export const BalanceListPage = () => {
  const theme = useTheme();
  const lessThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [ngrsData, setNgrsData] = useState([{}]);
  const [totalBalanceDealer, setTotalBalanceDealer] = useState([{}]);
  const [userData, setUserData] = useState({});
  const { isLoading, permissions } = usePermissions();
  if (!isLoading) {
  }

  const ngrsBalance = async () => {
    const balanceList = await mbaas.client.invokeApi('balance-positions', {
      account_id: '',
    });

    var balance = balanceList.data.ngrs.reduce(
      (obj: any, item: any) => ((obj[item.label] = item.value), obj),
      {}
    );

    var totalBalanceDealer = balanceList.data.totalDealer.reduce(
      (obj: any, item: any) => ((obj[item.label] = item.value), obj),
      {}
    );

    setNgrsData(balance);
    setTotalBalanceDealer(totalBalanceDealer);
  };

  const getUserData = async () => {
    const res = await mbaas.client.user.get();
    if (res.data) {
      setUserData(res.data);
      console.log(userData);
    }
    if (res.error) {
      // @ts-ignore
      notify(res.error);
    }
  };

  useEffect(() => {
    ngrsBalance();
    getUserData();
  }, []);

  const isPartner = !isLoading && permissions.find((el: string) => el === 'Partner');

  const isPartnerView = !isLoading && permissions.find((el: string) => el === 'Partner View');

  const isPartnerTopUp = !isLoading && permissions.find((el: string) => el === 'Partner Top-Up');

  const DatagridHeader = ({ children }: any) => (
    <TableHead>
      {Children.map(children, (child, index) => (
        <TableCell key={child.props.label} align={index === 0 ? 'left' : 'right'}>
          {child.props.label}
        </TableCell>
      ))}
      {!isPartner && !isPartnerView && !isPartnerTopUp ? (
        <>
          <TableRow>
            {Children.map(children, (child, index) => {
              return (
                <TableCell
                  key={child.props.label}
                  align={index === 0 ? 'left' : 'right'}
                  data-test-id="row-ngrs"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${
                    index === 0 ? 'NGRS' : numeral(ngrsData[child.props.label]).format('0,0') || '0'
                  }`}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            {Children.map(children, (child, index) => {
              return (
                <TableCell
                  key={child.props.label}
                  align={index === 0 ? 'left' : 'right'}
                  data-test-id="row-total-balance"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${
                    index === 0
                      ? 'Total'
                      : numeral(totalBalanceDealer[child.props.label]).format('0,0') || '0'
                  }`}
                </TableCell>
              );
            })}
          </TableRow>
        </>
      ) : (
        <RowPartnerTotalBalance children={children} totalBalanceDealer={totalBalanceDealer} />
      )}
    </TableHead>
  );

  return (
    <>
      <List
        title="Balance"
        resource="balance"
        perPage={25}
        debounce={1200}
        actions={<></>}
        sx={{
          width: '1000px',
        }}
      >
        <CustomTopToolbar pageBadge={<BalanceIcon style={pageBadgeIcon} />}>
          <FilterForm
            filters={isPartner || isPartnerView || isPartnerTopUp ? listFilterPartner : listFilters}
          />
          <ExportButton
            variant="contained"
            size="medium"
            style={lessThanSm ? exportButtonResponsivetStyle : exportButtonDefaultStyle}
          />
        </CustomTopToolbar>
        <Datagrid bulkActionButtons={false} header={<DatagridHeader />}>
          <FunctionField
            label="Start Time"
            render={(record: RaRecord) => {
              console.log(78423432, record);
              if (record.isParent === true) {
                return (
                  <span>
                    <b>{record.dealer} (Parent)</b>
                  </span>
                );
              }
              return <span>{record.dealer}</span>;
            }}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-15k"
            label="15K"
            source="type15K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-20k"
            label="20K"
            source="type20K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-25k"
            label="25K"
            source="type25K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-30k"
            label="30K"
            source="type30K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-40k"
            label="40K"
            source="type40K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-50k"
            label="50K"
            source="type50K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-75k"
            label="75K"
            source="type75K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-100k"
            label="100K"
            source="type100K"
            sortable={false}
          />
          <NumberField
            textAlign="right"
            data-test-id="field-bulk"
            label="BULK"
            source="BULK"
            sortable={false}
          />
        </Datagrid>
      </List>
    </>
  );
};
